import React from "react";
import { BottomNavigation, BottomNavigationAction, Box } from "@mui/material";
import { useTheme } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Home as HomeIcon, Person } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CarritoNavbar from "./carrito";
import Notifications from "../Notifications/Notifications";
import CategoriasResponsive from "./SubNavResponsive";

export default function BottomNavigationResponsive() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const token = localStorage.getItem("token");

  if (!isSmallScreen) return null;

  return (
    <BottomNavigation
      sx={{
        width: "100%",
        backgroundColor: theme.palette.navbar || "default",
        position: "fixed",
        zIndex: 9999,
        bottom: 0,
      }}
    >
      <BottomNavigationAction
        icon={
          <Box onClick={() => navigate("/")}>
            <HomeIcon htmlColor="#fff" sx={{ fontSize: 32 }} />
          </Box>
        }
      />
      <CategoriasResponsive />
      {token ? (
        <Notifications responsive={true} />
      ) : (
        <BottomNavigationAction
          icon={
            <Box onClick={() => navigate("/login")}>
              <Person htmlColor="#fff" sx={{ fontSize: 32 }} />
            </Box>
          }
        />
      )}
      {token && <CarritoNavbar responsive={true} />}
    </BottomNavigation>
  );
}
