import React from "react";
import { Box, Container, Typography } from "@mui/material";
import ImagenError404 from "../images/Error404.png";

export default function Error404() {
  return (
    <Container maxWidth="md">
      <Box height="80vh" mt={5}>
        <Box display="flex" justifyContent="center">
          <Box height="60vh">
            <img
              alt="error 404"
              src={ImagenError404}
              style={{
                maxHeight: "100%",
                maxWidth: "100%",
              }}
            />
          </Box>
        </Box>
        <Typography variant="h4" align="center">
          Lo sentimos, Esta pagina no existe
        </Typography>
      </Box>
    </Container>
  );
}
