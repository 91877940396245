import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
} from "@mui/material";
import { Link } from "react-router-dom";
import clienteAxios from "../../config/axios";

export default function CategoriasContainer() {
  const [loading, setLoading] = useState(false);
  const [categorias, setCategorias] = useState([]);

  const obtenerCategorias = async () => {
    setLoading(true);
    try {
      const { data } = await clienteAxios.get("/categories/navbar");
      setCategorias(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    obtenerCategorias();
  }, []);

  if (loading) {
    return <Box sx={{ width: "100%", height: 34 }} />;
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
      {categorias.map((category, index) => (
        <RenderCategorias key={index} category={category} />
      ))}
    </Box>
  );
}

const RenderCategorias = ({ category }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box>
      <Button
        sx={{
          textTransform: "none",
          padding: "4px 16px",
        }}
        ref={anchorRef}
        component={Link}
        to={`search?category=${category.category}`}
        aria-haspopup="true"
        onMouseOver={handleToggle}
        color="inherit"
        onMouseLeave={handleClose}
      >
        {category.category}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        onMouseOver={handleToggle}
        onMouseLeave={handleClose}
        sx={{
          transform: "translate3d(0px, 80px, 0px)!important",
          zIndex: 1300,
        }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper
              sx={{
                backgroundColor: "rgb(69, 57, 82)",
                width: "100%",
                borderRadius: 0,
                transition:
                  "opacity 201ms cubic-bezier(0.0, 0, 0.0, 1) 0ms, transform 134ms cubic-bezier(0.0, 0, 0.0, 1) 0ms",
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <Box
                  sx={{
                    width: "100vw",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {category.subcategories.map((subcat, index) => (
                    <Box key={index} sx={{ mx: 1 }}>
                      <Button
                        variant="text"
                        sx={{
                          color: "white",
                          textTransform: "none",
                          padding: "3px",
                        }}
                        component={Link}
                        to={`search?category=${category.category}&subcategory=${subcat.subCategory}`}
                      >
                        {subcat.subCategory}
                      </Button>
                    </Box>
                  ))}
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};
