import { deepOrange } from "@mui/material/colors";

const styles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.navbar,
  },
  drawer: {
    width: 400,
    flexShrink: 0,
    border: "10px solid black",
  },
  drawerPaper: {
    width: 0,
  },
  drawerPaperSecondary: {
    width: 300,
  },
  drawerPaperResponsive: {
    width: "100vw",
  },
  drawerContainer: {
    overflow: "auto",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    flexGrow: 1,
    marginRight: 400,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  divider: {
    backgroundColor: theme.palette.background.paper,
    height: 28,
    margin: 4,
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  marginButton: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  iconSave: {
    zIndex: 10,
    position: "fixed",
    bottom: theme.spacing(1),
    right: theme.spacing(2),
  },
  logo: {
    display: "none",
    height: 30,
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  logoResponsive: {
    height: 40,
  },
  imagen: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
});

export default styles;
