import React, { useState } from "react";
import { Button, Dialog, Box, IconButton, useMediaQuery } from "@mui/material";
import { Close, PlayCircleFilled } from "@mui/icons-material";
import Vimeo from "@u-wave/react-vimeo";
import { useTheme } from "@mui/material/styles";

export default function PreviewVideo({ topic }) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (!topic.preview || !topic.keyTopicVideo) return null;

  return (
    <>
      {!isSmallScreen ? (
        <Button
          color="primary"
          size="small"
          startIcon={<PlayCircleFilled />}
          onClick={handleClickOpen}
        >
          Vista previa
        </Button>
      ) : (
        <IconButton color="primary" onClick={handleClickOpen}>
          <PlayCircleFilled />
        </IconButton>
      )}

      <Dialog
        onClose={handleClose}
        open={open}
        fullWidth
        maxWidth="md"
        PaperProps={{
          sx: {
            position: "relative",
            backgroundColor: "#151515",
          },
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
            zIndex: 10,
          }}
          onClick={handleClose}
        >
          <Close />
        </IconButton>

        <Box
          sx={{
            height: { xs: "30vh", sm: "50vh", md: "70vh" },
            width: "100%",
            overflow: "hidden",
            display: "flex",
            alignItems: "flex-end",
            backgroundColor: "#151515",
          }}
        >
          <Vimeo
            video={topic.keyTopicVideo}
            autoplay={true}
            onEnd={handleClose}
            style={{ height: "95%", width: "100%" }}
          />
        </Box>
      </Dialog>
    </>
  );
}
