import { obtenerTokenFCM } from "../components/Firebase/firebaseInit";
import { getAuth, signOut } from "firebase/auth";
import clienteAxios from "../config/axios";

export const signOutUser = async () => {
  const user = JSON.parse(localStorage.getItem("student")) ?? { _id: "" };
  const auth = getAuth();
  const messagingToken = await obtenerTokenFCM();
  await clienteAxios
    .post("/user/signOut", { idUser: user._id, messagingToken })
    .then((res) => res)
    .catch((err) => {
      console.log(err);
    });
  await signOut(auth)
    .then(() => {
      // Sign-out successful.
      localStorage.removeItem("token");
      localStorage.removeItem("vistaPrevia");
      localStorage.removeItem("student");
      window.location.reload();
    })
    .catch((error) => {
      // An error happened.
      console.log(error);
    });
    return true
};
