import { deepOrange } from "@mui/material/colors";
import { alpha } from "@mui/material/styles";

export const sxStyles = (theme) => ({
  appbar: {
    height: "48px",
    backgroundColor: theme.palette.navbar,
  },
  backgroundCategories: {
    backgroundColor: theme.palette.navbarCategories,
    width: "100%",
  },
  grow: {
    flexGrow: 1,
  },
  marginButton: {
    margin: "0px 4px",
  },
  search: {
    position: "relative",
    borderRadius: "4px",
    backgroundColor: alpha("#ffffff", 0.15), // Sustituye theme.palette.common.white
    "&:hover": {
      backgroundColor: alpha("#ffffff", 0.25), // Sustituye theme.palette.common.white
    },
    marginLeft: 0,
    height: "35px",
    width: "100%",
  },
  search2: {
    position: "relative",
    borderRadius: "4px",
    backgroundColor: alpha("#ffffff", 0.15), // Sustituye theme.palette.common.white
    "&:hover": {
      backgroundColor: alpha("#ffffff", 0.25), // Sustituye theme.palette.common.white
    },
    marginLeft: "16px",
    height: "35px",
    width: "auto",
  },
  searchIcon: {
    padding: "0 16px", // Sustituye theme.spacing(0, 2)
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: "8px 8px 8px 16px",
    transition: "width 0.3s",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "30ch",
    },
  },
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 240,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  offset: {
    minHeight: theme.spacing(6),
    [theme.breakpoints.up("md")]: {
      minHeight: theme.spacing(10),
    },
  },
  orangeAvatar: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 120,
    height: 30,
  },
  ocultar: {
    "@media (max-width:1024px)": {
      display: "none",
    },
  },
  logoResponsive: {
    height: 40,
  },

  imagen: {
    width: 120,
  },

  session: {
    backgroundColor: "#3f51b5",
    color: "#fff",
  },
});
