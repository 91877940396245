import React, { useEffect } from "react";
import Grid from "@mui/material/Grid2";
import Imagen from "../../../images/register.jpg";
import FormLoginUsuario from "./form_login";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

export default function LoginUsuario(props) {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  if (token) {
    navigate("/");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid
      container
      direction="row"
      sx={{ backgroundColor: "background.paper" }}
    >
      <Grid
        size={{ sm: 6, md: 6, lg: 8 }}
        sx={{
          display: { xs: "none", sm: "block" },
        }}
      >
        <Box
          component="img"
          alt="registrate"
          src={Imagen}
          sx={{ maxHeight: "91vh" }}
        />
      </Grid>
      <Grid
        size={{ xs: 12, sm: 6, md: 6, lg: 4 }}
        sx={{ backgroundColor: "background.paper" }}
      >
        <FormLoginUsuario />
      </Grid>
    </Grid>
  );
}
