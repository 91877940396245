import React, { useContext, useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Aprendizajes from "./aprendizajes";
import Scroll from "../../../../../../components/ScrolltoTop/scroll";
import MessageSnackbar from "../../../../../../components/Snackbar/snackbar";
import Spin from "../../../../../../components/Spin/spin";
import { CursoContext } from "../../../../../../context/curso_context";
import clienteAxios from "../../../../../../config/axios";
import { Done } from "@mui/icons-material";

const styles = {
  BoxActions: {
    display: "flex",
    justifyContent: "flex-end",
    padding: 1.5,
    backgroundColor: (theme) => theme.palette.background.paper,
    zIndex: 10,
    position: "fixed",
    bottom: 0,
    right: 0,
    width: "100%",
  },
};

export default function VistaAprendizajes() {
  const { datos, update, setUpdate } = useContext(CursoContext);
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [respuestaAprendizaje, setRespuestaAprendizaje] = useState("");
  const [datosAprendizajes, setDatosAprendizajes] = useState([]);

  const [snackbar, setSnackbar] = useState({
    open: false,
    mensaje: "",
    status: "",
  });

  const obtenerRespuesta = (e) => {
    setRespuestaAprendizaje(e.target.value);
  };

  const agregarRespuesta = () => {
    if (!respuestaAprendizaje) {
      return;
    }
    setDatosAprendizajes([
      ...datosAprendizajes,
      { apredizaje: respuestaAprendizaje },
    ]);
    setRespuestaAprendizaje("");
  };

  useEffect(() => {
    if (datos.taller?.aprendizajesTaller) {
      setDatosAprendizajes(datos.taller.aprendizajesTaller);
    }
  }, [datos]);

  const eliminarRespuesta = (index) => {
    datosAprendizajes.splice(index, 1);
    setDatosAprendizajes([...datosAprendizajes]);
  };

  const guardarDatosBD = async () => {
    if (datosAprendizajes.length === 0) {
      setSnackbar({
        open: true,
        mensaje: "Por agregue minimo un aprendizaje",
        status: "error",
      });
      return;
    }
    setLoading(true);
    await clienteAxios
      .put(
        `/taller/agregateAprendizajes/${datos._id}`,
        {
          aprendizajesTaller: datosAprendizajes,
        },
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        setUpdate(!update);
        setSnackbar({
          open: true,
          mensaje: res.data.message,
          status: "success",
        });
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          setSnackbar({
            open: true,
            mensaje: err.response.data.message,
            status: "error",
          });
        } else {
          setSnackbar({
            open: true,
            mensaje: "Al parecer no se a podido conectar al servidor.",
            status: "error",
          });
        }
      });
  };

  return (
    <Box mt={3} mb={4}>
      <MessageSnackbar
        open={snackbar.open}
        mensaje={snackbar.mensaje}
        status={snackbar.status}
        setSnackbar={setSnackbar}
      />
      <Spin loading={loading} />
      <Scroll showBelow={250} bottomMargin={78} />
      <Box sx={styles.BoxActions} boxShadow={3}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          aria-label="Guardar"
          onClick={() => guardarDatosBD()}
          startIcon={<Done />}
        >
          Guardar
        </Button>
      </Box>
      <Typography variant="h6">
        ¿Qué aprenderan los estudiantes en tu taller?
      </Typography>
      <Box>
        <Box my={2}>
          <Aprendizajes
            datosAprendizajes={datosAprendizajes}
            eliminarRespuesta={eliminarRespuesta}
            setDatosAprendizajes={setDatosAprendizajes}
          />
          <TextField
            name="aprendizajesTaller"
            fullWidth
            placeholder="Ejemplo: HTML básico"
            variant="outlined"
            value={respuestaAprendizaje}
            onChange={obtenerRespuesta}
          />
        </Box>
        <Box mt={2}>
          <Button
            startIcon={<AddIcon />}
            color="primary"
            onClick={() => agregarRespuesta("aprendizajesTaller")}
          >
            Agregar respuesta
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
