import React, { Fragment } from "react";
import ExcelExportComponent from "../../../../../../components/ExcelExportComponent";

export default function ConsutaExcel({ usuarios }) {
  const datos = usuarios?.map((datos) => {
    const clientes = {};
    clientes.nameUser = datos.nameUser;
    clientes.emailUser = datos.emailUser;
    clientes.phoneUser = datos.phoneUser;
    return clientes;
  });

  return (
    <Fragment>
      <ExcelExportComponent
        data={datos}
        name="Lista de estudiantes Taller"
        button
      />
    </Fragment>
  );
}
