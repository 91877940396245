import React, { Fragment, useEffect, useState } from "react";
import {
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Collapse,
  Typography,
  BottomNavigationAction,
  Box,
  Alert,
  ListItemButton,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  Subscriptions as SubscriptionsIcon,
} from "@mui/icons-material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import clienteAxios from "../../config/axios";

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.action.disabled,
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

export default function CategoriasResponsive() {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categorias, setCategorias] = useState([]);
  const [error, setError] = useState(null); // Nuevo estado para manejar errores
  const navigate = useNavigate();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const obtenerCategorias = async () => {
    setLoading(true);
    try {
      const { data } = await clienteAxios.get("/categories/navbar");
      setCategorias(data);
    } catch (err) {
      setError(err.message)
      console.error(
        "Error al obtener categorías:",
        err.response || err.message
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    obtenerCategorias();
  }, []);

  return (
    <div>
      <BottomNavigationAction
        icon={
          <Box
            sx={{ height: "56px", display: "flex", alignItems: "center" }}
            onClick={toggleDrawer}
          >
            <SubscriptionsIcon htmlColor="#fff" sx={{ fontSize: 32 }} />
          </Box>
        }
      />
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
      >
        <div>
          <Puller />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              pt: 3,
            }}
          >
            <Typography variant="h6" align="center" color="textSecondary">
              Categorías
            </Typography>
          </Box>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                height: "50vh",
                p: 5,
              }}
            >
              <CircularProgress />
            </Box>
          ) : error ? ( // Muestra un mensaje de error si ocurre un problema
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                height: "50vh",
                p: 5,
              }}
            >
              <Alert severity="error">{error}</Alert>
            </Box>
          ) : (
            <List sx={{ height: "50vh", overflow: "auto" }}>
              {categorias.map((res, index) => (
                <ListCategories
                  key={index}
                  categorias={res}
                  toggleDrawer={toggleDrawer}
                  navigate={navigate}
                />
              ))}
            </List>
          )}
        </div>
      </SwipeableDrawer>
    </div>
  );
}

const ListCategories = ({ categorias, toggleDrawer, navigate }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Fragment>
      <ListItem disablePadding>
        <ListItemButton
          onClick={() => {
            toggleDrawer();
            navigate(`search?category=${categorias.category}`);
          }}
        >
          <ListItemText
            primary={
              <Typography variant="h6" noWrap>
                {categorias.category}
              </Typography>
            }
          />
          {open ? (
            <ExpandLess onClick={handleClick} />
          ) : (
            <ExpandMore onClick={handleClick} />
          )}
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {(categorias.subcategories || []).map((subs, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => {
                  toggleDrawer();
                  navigate(
                    `search?category=${categorias.category}&subcategory=${subs.subCategory}`
                  );
                }}
              >
                <ListItemText
                  primary={
                    <Typography variant="h6" noWrap>
                      {subs.subCategory}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </Fragment>
  );
};
