import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import { FacebookShareButton, WhatsappShareButton } from "react-share";
import ShareIcon from "@mui/icons-material/Share";
import QRCode from "react-qr-code";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import { CursoContext } from "../../../../../context/curso_context";
import MetaTags from "react-meta-tags";
import { FileCopy } from "@mui/icons-material";

export default function CompartirTaller({ datosTaller }) {
  const { datos } = useContext(CursoContext);
  const [open, setOpen] = useState(false);
  const [messageCopy, setMessageCopy] = useState(false);

  const handleClickOpen = () => {
    setOpen(!open);
  };

  const copyPortapapeles = (e) => {
    navigator.clipboard.writeText(
      `https://uniline.online/curso_taller/${datos.slug}`
    );
    setMessageCopy(true);
  };

  return (
    <div>
      <MetaTags>
        <title>UNILINE</title>
        <meta id="og-title" property="og:title" content={datos.slug} />
        <meta
          id="og-image"
          property="og:image"
          content={datos?.taller?.urlImageTaller}
        />
        <meta
          id="og-url"
          property="og:url"
          content={`https://uniline.online/curso_taller/${datos.slug}`}
        />
      </MetaTags>
      <Button
        fullWidth
        color="primary"
        style={{ fontSize: 16 }}
        variant="text"
        size="large"
        onClick={() => handleClickOpen()}
        startIcon={<ShareIcon />}
      >
        Compartir
      </Button>
      <Dialog
        open={open}
        onClose={handleClickOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          Comparte este taller por medio de WhatsAp o copiando este enlace, de
          igual manera escaneando el codigo QR
        </DialogTitle>
        <DialogContent>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={messageCopy}
            onClose={() => setMessageCopy(false)}
            message="Copiado a portapapeles"
            key="topCenter"
            autoHideDuration={3000}
          />
          <TextField
            fullWidth
            color="primary"
            value={`https://uniline.online/curso_taller/${datos.slug}`}
            name="Link"
            id="link"
            variant="outlined"
            slotProps={{
              input: {
                readOnly: true,
                endAdornment: (
                  <Stack direction="row" gap={1}>
                    <InputAdornment>
                      <IconButton onClick={(e) => copyPortapapeles(e)}>
                        <FileCopy />
                      </IconButton>
                    </InputAdornment>
                    <FacebookShareButton
                      url={`https://uniline.online/curso_taller/${datos.slug}`}
                      quote={datosTaller.nameTaller}
                      separator=": "
                    >
                      <FacebookIcon
                        style={{ fontSize: 30, color: "#3b5998" }}
                      />
                    </FacebookShareButton>
                    <WhatsappShareButton
                      url={`https://uniline.online/curso_taller/${datos.slug}`}
                      title={datosTaller.nameTaller}
                      separator=": "
                    >
                      <WhatsAppIcon
                        style={{ fontSize: 30, color: "#00bb2d" }}
                      />
                    </WhatsappShareButton>
                  </Stack>
                ),
              },
            }}
          />
          <Box my={2} mb={3} display="flex" justifyContent="center">
            <QRCode
              value={`https://uniline.online/curso_taller/${datos.slug}`}
              style={{
                height: "auto",
                width: 170,
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
