import React from "react";
import {
  Card,
  CardContent,
  Avatar,
  Box,
  Typography,
  CardActionArea,
  CardActions,
  CardMedia,
  AvatarGroup,
} from "@mui/material";
import { red } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import { formatoMexico } from "../../../config/reuserFunction";

export default function CardPaquete({ paquete }) {
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
        cursor: "pointer",
      }}
      onClick={() => navigate(`/paquete/${paquete.slug}`)}
    >
      <CardActionArea>
        <Box
          sx={{
            height: 170,
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              backgroundColor: red[200],
              color: "white",
              top: 5,
              left: 5,
              padding: "2px 8px",
              borderRadius: "5px",
              fontWeight: 600,
            }}
          >
            Pack de cursos
          </Box>
          {paquete.image ? (
            <CardMedia
              sx={{ height: "100%" }}
              component="img"
              image={paquete.image}
              alt={paquete.title}
            />
          ) : (
            <Box
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <AvatarGroup max={4}>
                {paquete.courses.map((res, index) => (
                  <Avatar
                    key={index}
                    sx={{ height: 60, width: 60 }}
                    alt={`curso ${index}`}
                    src={res.course?.urlPromotionalImage}
                  />
                ))}
              </AvatarGroup>
            </Box>
          )}
        </Box>
        <CardContent>
          <Typography
            gutterBottom
            component="h2"
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              fontSize: 18,
              fontWeight: 400,
            }}
          >
            {paquete.title}
          </Typography>
          <Typography color="textSecondary" variant="caption">
            Creado por {paquete.idProfessor.name}
          </Typography>
          <Typography
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              fontSize: 14,
            }}
            color="textSecondary"
          >
            {paquete.description}
          </Typography>
        </CardContent>
        <CardActions>
          <Typography sx={{ fontSize: 18 }}>
            ${formatoMexico(paquete.pricePack)} MXN
          </Typography>
        </CardActions>
      </CardActionArea>
    </Card>
  );
}
