import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "./styles.css";
import ConexionDetect from "./ConexionDetect";
import { obtenerTokenFCM } from "./components/Firebase/firebaseInit";
import { CtxPrincipalProvider } from "./context/ContextPrincipal";
import imagenInicio from "./images/inicio.jpg";
import { router } from "./config/routes";

function App() {
  useEffect(() => {
    obtenerTokenFCM();
  }, []);

  return (
    <HelmetProvider>
      <div className="App">
        <Helmet>
          <meta charSet="utf-8" />
          <title>UNILINE</title>
          <meta
            name="description"
            content="Aprende en nuestra escuela en linea."
          />
          <meta property="og:image" content={imagenInicio} />
          <meta property="og:url" content={`https://uniline.online/`} />
        </Helmet>
        <CtxPrincipalProvider>
          <ConexionDetect />
          <RouterProvider router={router} />
        </CtxPrincipalProvider>
      </div>
    </HelmetProvider>
  );
}

export default App;
