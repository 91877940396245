import React, { useCallback, useEffect, useState } from "react";
import { Box, Chip, Container, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Link, useLocation } from "react-router-dom";
import clienteAxios from "../../../config/axios";
import SpinNormal from "../../../components/Spin/spinNormal";
import CardsCursos from "../CardCurso/card_curso";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export default function ResultadoCategorias() {
  const location = useLocation();
  const busqueda = location.search;
  const decodedBusqueda = decodeURIComponent(busqueda);
  const [loading, setLoading] = useState(false);
  const [resultados, setResultados] = useState([]);
  const [subcat_list, setSubcatList] = useState([]);

  let categoria = "Todos los cursos";
  let subcategoria = "";

  if (decodedBusqueda) {
    const category_split = decodedBusqueda.split(/[=&]+/);
    console.log(decodedBusqueda);
    categoria = category_split[1]?.replace(/%20/g, " ") || "Todos los cursos";
    if (category_split.length > 2) {
      subcategoria = category_split[3]?.replace(/%20/g, " ") || "";
    }
  }

  const obtenerResultados = useCallback(async () => {
    setLoading(true);
    try {
      const res = await clienteAxios.get(
        `/categories/filter${decodedBusqueda}`
      );
      setResultados(res.data.cursos);
      setSubcatList(res.data.subcategorias);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [decodedBusqueda]);

  const render_cursos = resultados.map((curso, index) => (
    <Grid size={{ xs: 6, md: 4, lg: 3 }} key={index}>
      <CardsCursos curso={curso.course} cursoData={curso} />
    </Grid>
  ));

  const render_sub = subcat_list.map((sub, index) => (
    <Grid item key={index}>
      <Box m={1}>
        <Chip
          clickable
          component={Link}
          to={`?category=${categoria}&subcategory=${sub.subCategory}`}
          label={sub.subCategory}
          color="primary"
          variant="outlined"
        />
      </Box>
    </Grid>
  ));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    obtenerResultados();
  }, [obtenerResultados, busqueda]);

  if (loading) {
    return (
      <Box
        height="80vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <SpinNormal />
      </Box>
    );
  }

  return (
    <Container maxWidth="xl">
      <Box mt={2}>
        <Box mb={5}>
          <Box
            mb={1}
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="h6">{categoria}</Typography>
            {subcategoria && (
              <>
                <Box mx={2}>
                  <ArrowForwardIosIcon sx={{ fontSize: 16 }} />
                </Box>
                <Typography variant="h6">{subcategoria}</Typography>
              </>
            )}
          </Box>
          <Grid container justifyContent="center">
            {categoria && categoria !== "Todos los cursos" && (
              <Grid item>
                <Box m={1}>
                  <Chip
                    clickable
                    component={Link}
                    to={`?category=${categoria}`}
                    label={categoria}
                    color="primary"
                  />
                </Box>
              </Grid>
            )}
            {subcat_list.length > 0 && render_sub}
          </Grid>
        </Box>

        <Box mb={4}>
          <Grid container spacing={2}>
            {render_cursos}
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
