import React, { useEffect, useState } from "react";
import { Box, Container, Grid2 as Grid } from "@mui/material";
import clienteAxios from "../../../config/axios";
import Error500 from "../../error500";
import MessageSnackbar from "../../../components/Snackbar/snackbar";
//import MetaTags from "react-meta-tags";
import Scroll from "../../../components/ScrolltoTop/scroll";
import InfoPrincipal from "./InfoPrincipal";
import VideoPresentacion from "./VideoPresentacion";
import DetallesCurso from "./DetallesCurso";
import { Helmet } from "react-helmet-async";
import "./styles.css";
import { useTheme } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

export default function VistaCurso() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { url: idcurso } = useParams();
  const [cursos, setCursos] = useState([]);
  //const [loading, setLoading] = useState(true);
  const [error, setError] = useState({ error: false, message: "" });
  const [snackbar, setSnackbar] = useState({
    open: false,
    mensaje: "",
    status: "",
  });

  const obtenerCursosBD = React.useCallback(async () => {
    await clienteAxios
      .get(`/course/view-course/${idcurso}`)
      .then((res) => {
        //setLoading(false);
        setCursos(res.data);
      })
      .catch((err) => {
        //setLoading(false);
        if (err.response) {
          setError({ error: true, message: err.response.data.message });
        } else {
          setError({
            error: true,
            message: "Al parecer no se a podido conectar al servidor.",
          });
        }
      });
  }, [idcurso]);

  useEffect(() => {
    obtenerCursosBD();
    window.scrollTo(0, 0);
  }, [obtenerCursosBD]);

  if (error.error) {
    return <Error500 error={error.message} />;
  }

  if (cursos.length === 0) {
    return (
      <Box height="50vh" className="container-animation-loading">
        <div className="loading loading03">
          <span>U</span>
          <span>N</span>
          <span>I</span>
          <span>L</span>
          <span>I</span>
          <span>N</span>
          <span>E</span>
        </div>
      </Box>
    );
  }

  if (!cursos.course.publication) navigate("/");

  return (
    <Box>
      {/* <MetaTags>
        <title>UNILINE</title>
        <meta
          id="meta-description-curso"
          name="description"
          content={cursos.course.description}
        />
        <meta id="og-title-curso" property="og:title" content={cursos.course.title} />
        <meta
          id="og-image-curso"
          property="og:image"
          content={cursos.course.urlPromotionalImage}
        />
        <meta
          id="og-url-curso"
          property="og:url"
          content={"https://uniline.online/curso/" + cursos.course.slug}
        />
      </MetaTags> */}
      <Helmet>
        <title>UNILINE</title>
        <meta name="description" content={cursos.course.description} />
        <meta property="og:title" content={cursos.course.title} />
        <meta property="og:image" content={cursos.course.urlPromotionalImage} />
        <meta
          property="og:url"
          content={`https://uniline.online/curso/${cursos.course.slug}`}
        />
      </Helmet>
      <Scroll showBelow={250} bottomMargin={62} />

      <Box
        id="adquirir"
        style={{ scrollMarginTop: 70 }}
        sx={{
          "& .background": {
            [theme.breakpoints.down("xs")]: {
              position: "fixed",
              minHeight: "90vh",
              width: "100%",
            },
          },
        }}
      >
        <Box
          className="background"
          style={{
            backgroundImage: `url(${cursos.course.urlPromotionalImage})`,
          }}
        />
        <Box
          sx={{
            minHeight: "75vh",
            width: "100%",
            position: "relative",
            backgroundColor: "rgba(26, 0, 55, 0.55)!important",
            [theme.breakpoints.down("xs")]: {
              minHeight: "90vh",
              width: "100%",
            },
          }}
        >
          <Container>
            <Box py={5}>
              <Grid container spacing={2}>
                <Grid size={{ xs: 12, md: 7 }}>
                  <InfoPrincipal cursos={cursos} setSnackbar={setSnackbar} />
                </Grid>
                <Grid size={{ xs: 12, md: 5 }}>
                  <VideoPresentacion cursos={cursos} />
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
        <DetallesCurso curso={cursos} />
        <MessageSnackbar
          open={snackbar.open}
          mensaje={snackbar.mensaje}
          status={snackbar.status}
          setSnackbar={setSnackbar}
        />
      </Box>
    </Box>
  );
}
