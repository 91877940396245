import React, { useState } from "react";
import {
  Popover,
  List,
  IconButton,
  Badge,
  Divider,
  Box,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsPausedIcon from "@mui/icons-material/NotificationsPaused";
import ImageIcon from "@mui/icons-material/Image";

export default function NotificationComponent() {
  const [anchorEl, setAnchorEl] = useState(null);
  const notifications = JSON.parse(
    localStorage.getItem("notifications_uniline") || "[]"
  );

  const count = notifications.filter((res) => !res.read);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <IconButton aria-describedby={id} color="inherit" onClick={handleClick}>
        <Badge badgeContent={count.length} color="secondary">
          <NotificationsIcon fontSize="small" />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List sx={{ width: "100%", backgroundColor: "background.paper" }}>
          {!notifications.length ? (
            <ListItem>
              <NotificationsPausedIcon sx={{ mr: 2 }} />
              <ListItemText primary="No hay notificaciones" />
            </ListItem>
          ) : (
            notifications.map((notification, index) => (
              <Box key={index}>
                <ItemNotification
                  index={index}
                  notification={notification}
                  notificaciones={notifications}
                  handleClose={handleClose}
                />
                {index !== notifications.length - 1 && (
                  <Divider sx={{ mx: 2, height: 0.5 }} />
                )}
              </Box>
            ))
          )}
        </List>
      </Popover>
    </div>
  );
}

const ItemNotification = ({
  notification,
  notificaciones,
  index,
  handleClose,
}) => {
  const redirection = () => {
    const updatedNotification = { ...notification, read: true };
    const updatedNotifications = [...notificaciones];
    updatedNotifications.splice(index, 1, updatedNotification);
    localStorage.setItem(
      "notifications_uniline",
      JSON.stringify(updatedNotifications)
    );
    if (notification.url) window.open(notification.url, "_blank");
    handleClose();
  };

  return (
    <ListItem
      sx={{
        display: "flex",
        alignItems: "flex-start",
        cursor: "pointer",
        width: "420px",
      }}
      button
      onClick={redirection}
    >
      <Badge
        variant="dot"
        color="secondary"
        invisible={notification.read}
        sx={{ mt: 1.5, mr: 1 }}
      >
        <ListItemAvatar>
          <Avatar
            variant="rounded"
            src={notification.image || ""}
            sx={{ height: 52, width: 52, mr: 2 }}
          >
            <ImageIcon />
          </Avatar>
        </ListItemAvatar>
      </Badge>

      <ListItemText
        primary={`${
          notification.name_teacher ? `${notification.name_teacher}: ` : ""
        } ${notification.title || ""}`}
        secondary={notification.description || ""}
      />
    </ListItem>
  );
};
