import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import CardPaquete from "./CardPaquete";
import clienteAxios from "../../../config/axios";
import Error500 from "../../error500";

export default function PaquetesHome() {
  const [paquetes, setPaquetes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ error: false, message: "" });
  const [snackbar, setSnackbar] = useState({
    open: false,
    mensaje: "",
    status: "",
  });

  const obtenerPaquetesBD = useCallback(async () => {
    setLoading(true);
    try {
      const res = await clienteAxios.get(`/packages/`);
      setPaquetes(res.data);
    } catch (err) {
      setError({
        error: true,
        message:
          err.response?.data?.message || "Error al conectar con el servidor.",
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    obtenerPaquetesBD();
  }, [obtenerPaquetesBD]);

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="50vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error.error) {
    return <Error500 error={error.message} />;
  }

  if (!paquetes.length) return null;

  return (
    <Box sx={{ margin: { xs: "32px 0px", sm: 3 } }}>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.status}
          sx={{ width: "100%" }}
        >
          {snackbar.mensaje}
        </Alert>
      </Snackbar>

      <Box mt={3} mb={10}>
        <Box my={2}>
          <Typography variant="h4" gutterBottom>
            Llévalos juntos
          </Typography>
        </Box>
        <Box my={2}>
          <Grid container spacing={2}>
            {paquetes.map((paquete, index) => (
              <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                <CardPaquete paquete={paquete} setSnackbar={setSnackbar} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
