import React, { Fragment } from "react";
import QueueAnim from "rc-queue-anim";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

import Carousel from "react-material-ui-carousel";
import imagenBanner from "../../../../images/banner3.jpg";
import logoUniline from "../../../../images/uniline3.png";

export default function BannerDefault() {
  return (
    <Fragment>
      <Carousel interval={5000} indicators={false}>
        <Grid
          container
          sx={{
            display: "flex",
            minHeight: "65vh",
            backgroundImage: `url(${imagenBanner})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            "@media (max-width:600px)": {
              minHeight: "90vh",
            },
          }}
        >
          <Grid size={{ xs: 12, md: 6, lg: 7 }}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <Box p={5}>
                <QueueAnim delay={700} className="queue-simple">
                  <Box key="a">
                    <img
                      alt="logo uniline"
                      src={logoUniline}
                      style={{
                        height: 120,
                        "@media (maxWidth:600px)": {
                          height: 100,
                          width: "100%",
                        },
                      }}
                    />
                    <Box my={2} style={{ color: "#F9F9F9" }}>
                      <Typography variant="h4">
                        <b>APRENDE DESDE CASA EN EL MOMENTO QUE TÚ QUIERAS.</b>
                      </Typography>
                    </Box>
                  </Box>
                </QueueAnim>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Carousel>
    </Fragment>
  );
}
