import React from "react";
import { formatoMexico } from "../../../config/reuserFunction";
import { Fragment } from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid2 as Grid,
  Typography,
} from "@mui/material";
import { AccessTime, AssessmentOutlined } from "@mui/icons-material";

function CardCursoPack({ cursoObj }) {
  const { course } = cursoObj;

  return (
    <Fragment>
      <Card variant="outlined">
        <CardMedia
          sx={{ height: 130 }}
          alt={`imagen curso ${course._id}`}
          image={course.urlPromotionalImage}
        />
        <CardContent>
          <Typography gutterBottom variant="h6" noWrap>
            {course.title}
          </Typography>
          <Box>
            <Box display="flex" alignItems="center" style={{ marginBottom: 2 }}>
              <AccessTime style={{ marginRight: 5 }} />
              <Typography>{`${course.hours} horas de curso`}</Typography>
            </Box>
            <Box display="flex" alignItems="center" style={{ marginBottom: 2 }}>
              <AssessmentOutlined style={{ marginRight: 5 }} />
              <Typography>{`Nivel ${course.level}`}</Typography>
            </Box>
          </Box>
        </CardContent>
        <CardActions>
          {cursoObj.prices.persentagePromotion !== "0" ? (
            <Grid container>
              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant="h6" color="textPrimary" align="right">
                  ${formatoMexico(cursoObj.prices.promotionPrice)} MXN
                </Typography>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant="h6" color="textSecondary" align="right">
                  <s>${formatoMexico(course.priceCourse.price)} MXN</s>
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Typography variant="h6" color="textPrimary" align="right">
              ${formatoMexico(cursoObj.prices.price)} MXN
            </Typography>
          )}
        </CardActions>
      </Card>
    </Fragment>
  );
}

export default CardCursoPack;
