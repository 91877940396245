import React, { useCallback } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useDropzone } from "react-dropzone";
import { Alert, AlertTitle } from "@mui/material"

export default function InputImageDev({
  values,
  setValues,
  previewDevices,
  setPreviewDevices,
}) {

  const onDrop = useCallback(
    (files) => {
      setPreviewDevices(URL.createObjectURL(files[0]));
      setValues({
        ...values,
        image_devices: files,
      });
    },
    [values, setValues, setPreviewDevices]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const removeImage = () => {
    setValues({
      ...values,
      image_devices: "",
    });
    setPreviewDevices("");
  };

  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      <Box>
        <Alert severity="info" icon={false}>
          <AlertTitle>Para dispositivos moviles</AlertTitle>
          Este aparecera en dispositivos como tablets y smartphones
        </Alert>
      </Box>
      <Box
        sx={!previewDevices && { border: "dashed 2px", borderColor: "#aaaaaa" }}
        {...getRootProps()}
        height={120}
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <input disabled={values.isModal} {...getInputProps()} />
        {values.image_devices || previewDevices ? (
          <Box
            height={120}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <img
              alt="imagen del curso"
              src={previewDevices}
              style={{maxHeight: "100%",
                maxWidth: "100%",}}
            />
          </Box>
        ) : isDragActive ? (
          <Typography>Suelta tú imagen aquí...</Typography>
        ) : (
          <Typography>
            haz clic aquí o arrastra tu imagen y suelta aquí
          </Typography>
        )}
      </Box>
      <Button
        fullWidth
        variant="text"
        color="primary"
        size="small"
        onClick={() => removeImage()}
        disabled={values.isModal}
      >
        Eliminar imagen
      </Button>
    </Box>
  );
}
