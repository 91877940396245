import React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CrearSubCategoria from "./CrearSubcategorias";
import EditarCategoria from "./EditarCategoria";
import Editarsubcategoria from "./EditarSubcategoria";
import EliminarCategoria from "./EliminarCategoria";
import EliminarSubcategoria from "./EliminarSubcategoria";

export default function ListaCategorias({ categorias, update, setUpdate }) {
  return (
    <TableContainer>
      <Paper variant="outlined">
        <Typography variant="h6" component="div" sx={{ml: 2}}>
          Categorias
        </Typography>
        <Table size="medium" aria-label="collapsible table">
          <TableBody>
            {categorias.map((row, index) => (
              <RenderCategorias
                key={index}
                categoria={row}
                update={update}
                setUpdate={setUpdate}
              />
            ))}
          </TableBody>
        </Table>
      </Paper>
    </TableContainer>
  );
}

const RenderCategorias = ({ categoria, update, setUpdate }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow
        sx={{
          "& > *": {
            borderBottom: "unset",
          },
        }}
      >
        <TableCell padding="checkbox">
          <IconButton
            aria-label="expand row"
            size="medium"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography variant="button">{categoria.categorie}</Typography>
        </TableCell>
        <TableCell align="right" padding="checkbox">
          <EditarCategoria
            categoria={categoria}
            update={update}
            setUpdate={setUpdate}
          />
        </TableCell>
        <TableCell align="right" padding="checkbox">
          <EliminarCategoria
            categoria={categoria}
            update={update}
            setUpdate={setUpdate}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Typography
                variant="subtitle2"
                style={{ fontSize: 18 }}
                gutterBottom
                component="div"
              >
                Subcategorias
              </Typography>
              <Box my={1}>
                <CrearSubCategoria
                  categoria={categoria}
                  update={update}
                  setUpdate={setUpdate}
                />
              </Box>
              <Table size="medium" aria-label="purchases">
                <TableBody>
                  {categoria.subCategories.map((row) => (
                    <TableRow key={row._id}>
                      <TableCell component="th" scope="row">
                        <Typography variant="button">
                          {row.subCategorie}
                        </Typography>
                      </TableCell>
                      <TableCell align="right" padding="checkbox">
                        <Editarsubcategoria
                          categoria={categoria}
                          subcategoria={row}
                          update={update}
                          setUpdate={setUpdate}
                        />
                      </TableCell>
                      <TableCell align="right" padding="checkbox">
                        <EliminarSubcategoria
                          categoria={categoria}
                          subcategoria={row}
                          update={update}
                          setUpdate={setUpdate}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
