import {
  Account,
  AccountPopoverFooter,
  AccountPreview,
  SignOutButton,
  ThemeSwitcher,
} from "@toolpad/core";
import {
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Stack,
} from "@mui/material";
import { Settings } from "@mui/icons-material";
import { Link } from "react-router-dom";

function ToolbarActionsAccountPopover() {
  return (
    <Stack direction="column">
      <AccountPreview variant="expanded" />
      <MenuList>
        <MenuItem
          component={Link} to="/perfil"
          sx={{
            justifyContent: "flex-start",
            width: "100%",
            columnGap: 1,
          }}
        >
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
            }}
            primary="Editar mi información"
            primaryTypographyProps={{ variant: "body2" }}
            secondaryTypographyProps={{ variant: "caption" }}
          />
        </MenuItem>
      </MenuList>
      <Divider />
      <AccountPopoverFooter>
        <SignOutButton />
      </AccountPopoverFooter>
    </Stack>
  );
}

function ToolbarActionsAccount() {
  return (
    <Stack direction="row" alignItems="center">
      <ThemeSwitcher />
      <Account
        slots={{
          popoverContent: ToolbarActionsAccountPopover,
        }}
      />
    </Stack>
  );
}

export default ToolbarActionsAccount;
