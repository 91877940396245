import React, {
  Fragment,
  useCallback,
} from "react";
import {
  Avatar,
  Box,
  Collapse,
  IconButton,
  Link,
  Paper,
  Table,
  Typography,
} from "@mui/material";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { CursoContext } from "../../../../context/curso_context";
import clienteAxios from "../../../../config/axios";
import Spin from "../../../../components/Spin/spin";
import RevisarTarea from "./revisar_tarea";
import MessageSnackbar from "../../../../components/Snackbar/snackbar";
import CustomAvatar from "../../../../components/CustomAvatar";

const styles = {
  /* table: {
    [theme.breakpoints.only("xs")]: {
      display: "block",
      width: 370,
      overflowX: "auto",
    },
  }, */
  download: {
    display: "flex",
    alignItems: "center",
  },
};

export default function TareasEstudiantes() {
  const token = localStorage.getItem("token");
  const { datos } = React.useContext(CursoContext);
  const [tareas, setTareas] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [updateTareas, setUpdateTareas] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    mensaje: "",
    status: "",
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const obtenerTareas = useCallback(async () => {
    if (!datos._id) return;
    setLoading(true);
    await clienteAxios
      .get(`/homework/${datos._id}/`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        setLoading(false);
        setTareas(res.data);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          setSnackbar({
            open: true,
            mensaje: err.response.data.message,
            status: "error",
          });
        } else {
          setSnackbar({
            open: true,
            mensaje: "Al parecer no se a podido conectar al servidor.",
            status: "error",
          });
        }
      });
  }, [datos._id, token]);

  React.useEffect(() => {
    obtenerTareas();
  }, [obtenerTareas, updateTareas]);

  return (
    <Box>
      <Spin loading={loading} />
      <MessageSnackbar
        open={snackbar.open}
        mensaje={snackbar.mensaje}
        status={snackbar.status}
        setSnackbar={setSnackbar}
      />
      <TableContainer component={Paper} sx={styles.table}>
        <Table stickyHeader sx={styles.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Usuario</TableCell>
              <TableCell>Tarea</TableCell>
              <TableCell align="right">Calificación</TableCell>
              <TableCell align="right">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tareas
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((tarea, index) => (
                <TableInfoBody
                  key={index}
                  tarea={tarea}
                  updateTareas={updateTareas}
                  setUpdateTareas={setUpdateTareas}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={tareas.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelDisplayedRows={({ from, to, count }) =>
          `${from} de ${count !== -1 ? count : to}`
        }
      />
    </Box>
  );
}

const TableInfoBody = ({ tarea, updateTareas, setUpdateTareas }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Fragment>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Typography>{tarea.idUser.name}</Typography>
        </TableCell>
        <TableCell>
          <Link
            href={tarea.homeworkFileUrl}
            download={tarea.homeworkFileKey}
            sx={styles.download}
          >
            <GetAppIcon /> {tarea.homeworkFileKey}
          </Link>
        </TableCell>
        <TableCell align="right">
          {tarea.qualificationHomework ? tarea.qualificationHomework : "-"}
        </TableCell>
        <TableCell align="right">
          <RevisarTarea
            tarea={tarea}
            updateTareas={updateTareas}
            setUpdateTareas={setUpdateTareas}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Datos del estudiante
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Imagen</TableCell>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Telefono</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="left">
                      {tarea.idUser.urlImage ? (
                        <Avatar
                          alt="imagen usuario"
                          src={tarea.idUser.urlImage}
                        />
                      ) : (
                        <CustomAvatar name={tarea.idUser.name} />
                      )}
                    </TableCell>
                    <TableCell>{tarea.idUser.name}</TableCell>
                    <TableCell>{tarea.idUser.email}</TableCell>
                    <TableCell>
                      {tarea.idUser.phone ? tarea.idUser.phone : "-"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};
