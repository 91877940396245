import React, { useContext } from "react";
import { CursoContext } from "../../../../context/curso_context";
import ExcelExportComponent from "../../../../components/ExcelExportComponent";

function ExportarExcel({ estudiantes }) {
  const { datos } = useContext(CursoContext);

  const data = estudiantes.map((data) => {
    const estudiante = {
      name: "",
      email: "",
      phone: "",
      coupon_discount: "",
    };

    estudiante.name = data.idUser?.name;
    estudiante.email = data.idUser?.email;
    estudiante.phone = data.idUser?.phone;
    estudiante.type_access = data.code
      ? `Cupon - ${data.codeKey}`
      : data.freeCourse
      ? "Gratis"
      : "Comprado";
    estudiante.coupon_discount =
      data.coupon_discount && data.coupon_discount.percent_discount
        ? data.coupon_discount.coupon_code
        : "";

    return estudiante;
  });

  return (
    <ExcelExportComponent
      data={data}
      name={`estudiantes de ${datos.title}`}
      button
    />
  );
}

export default ExportarExcel;
