import React from "react";
import { Facebook, Instagram, WhatsApp, YouTube } from "@mui/icons-material";
import { Box, Link } from "@mui/material";

function FloatButton() {
  return (
    <Box
      sx={{
        position: "fixed",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 1,
        bottom: 16,
        right: 16,
        zIndex: 1000,
        "@media (max-width: 900px)": {
          marginBottom: "56px",
        },
      }}
    >
      <Link
        href="https://www.facebook.com/EscuelaUniline"
        target="_blank"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "60px",
          height: "60px",
          backgroundColor: "#1877f2",
          color: "#fff",
          borderRadius: "50%",
          fontSize: "32px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          transition: "transform 0.3s ease",
          "&:hover": {
            transform: "scale(1.1)",
          },
          "@media (max-width: 600px)": {
            width: "50px",
            height: "50px",
          },
        }}
      >
        <Facebook fontSize="inherit" />
      </Link>
      <Link
        href="https://www.instagram.com/uniline.oficial"
        target="_blank"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "60px",
          height: "60px",
          background:
            "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)",
          color: "#fff",
          borderRadius: "50%",
          fontSize: "32px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          transition: "transform 0.3s ease",
          "&:hover": {
            transform: "scale(1.1)",
          },
          "@media (max-width: 600px)": {
            width: "50px",
            height: "50px",
          },
        }}
      >
        <Instagram fontSize="inherit" />
      </Link>
      <Link
        href="https://www.youtube.com/@Unilineonline"
        target="_blank"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "60px",
          height: "60px",
          backgroundColor: "#FF0000",
          color: "#fff",
          borderRadius: "50%",
          fontSize: "32px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          transition: "transform 0.3s ease",
          "&:hover": {
            transform: "scale(1.1)",
          },
          "@media (max-width: 600px)": {
            width: "50px",
            height: "50px",
          },
        }}
      >
        <YouTube fontSize="inherit" />
      </Link>
      <Link
        href="https://wa.me/5213171297626"
        target="_blank"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "60px",
          height: "60px",
          backgroundColor: "#35c42c",
          color: "#fff",
          borderRadius: "50%",
          fontSize: "32px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          transition: "transform 0.3s ease",
          "&:hover": {
            transform: "scale(1.1)",
          },
          "@media (max-width: 600px)": {
            width: "50px",
            height: "50px",
          },
        }}
      >
        <WhatsApp fontSize="inherit" />
      </Link>
    </Box>
  );
}

export default FloatButton;
