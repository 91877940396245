import React from "react";
import { Box, Typography, Button, Container } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { WhatsApp } from "@mui/icons-material";
import { appsJson } from "./dataApps";

export default function CatalogoApps() {
  return (
    <Box my={3}>
      <Container maxWidth="md">
        <Grid container spacing={5}>
          {appsJson.map((res, index) => (
            <Grid size={{ xs: 12 }} key={index}>
              <Grid container spacing={2}>
                <Grid size={{ xs: 12, md: 4 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      component="img"
                      alt={res.title}
                      src={res.image}
                      sx={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        borderRadius: 2,
                      }}
                    />
                  </Box>
                </Grid>
                <Grid size={{ xs: 12, md: 8 }}>
                  <Box>
                    <Typography variant="h6">{res.title}</Typography>
                    <Typography align="justify">{res.description}</Typography>
                    <Box
                      component="ul"
                      sx={{
                        columns: { xs: 3 },
                      }}
                    >
                      {res.points?.map((punto, idx) => (
                        <Box component="li" key={idx}>
                          <Typography>{punto}</Typography>
                        </Box>
                      ))}
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        variant="contained"
                        color="secondary"
                        disableElevation
                        href={`https://wa.me/5213171297626?text=${encodeURIComponent(`Hola, quiero más información sobre esta app: ${res.title}`)}`}
                        target="_blank"
                        sx={{
                          backgroundColor: "#00a884",
                          "&:hover": { backgroundColor: "#007c64" }, // Color hover
                        }}
                        startIcon={<WhatsApp />}
                      >
                        Más información
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
