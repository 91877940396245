import React, { useEffect } from "react";
import { Box } from "@mui/material";
import Banner from "./Banner/banner";
import CursosComprados from "./Cursos/cursos_estudiante";
import BannerInformartivo from "./Banner_Informativo/banner_informativo";
import CursosDisponibles from "./Cursos/cursos";
import UltimosCursosSubidos from "./Cursos/LatestCourses";
import PaquetesHome from "../Paquetes/PaquetesHome";
import FloatButton from "./FloatButton";
//import { MetaTags } from "react-meta-tags";
//import imagenInicio from "../../../images/inicio.jpg";

export default function Home(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box>
      <Banner />
      <Box sx={{ maxWidth: "1250px", margin: "0 auto" }}>
        <CursosComprados />
        <UltimosCursosSubidos />
        <PaquetesHome />
        <CursosDisponibles />
        <FloatButton />
      </Box>
      <BannerInformartivo />
    </Box>
  );
}
