import { createBrowserRouter } from "react-router-dom";
// import LayoutUsers from "../components/Layout_User/layout_usuario";
// import Home from "../pages/users/Home/home";
import routesLuis from "./routesLuis";

//layout
import LayoutMaestro from "../components/Layout_Maestro/LayoutMaestro";
import LayoutContenidoCurso from "../components/Layout_Maestro/LayoutContenido";

//Admin pages
import RegistroUsuariosUniline from "../pages/maestro/RegistroUsuarios/TabPanelUsuarios";
import RegistroBannersCarousel from "../pages/maestro/Banner/Banner";
import NotificacionesAdmin from "../pages/maestro/Notificaciones/NotificacionesAdmin";
import VentasMaestro from "../pages/maestro/Ventas/Ventas";
//import HomeDesign from "../pages/maestro/HomeDesign/HomeDesign";
import LinksPago from "../pages/maestro/LinksPago";
import RespuestasEncuestaUniline from "../pages/maestro/RespuestasEncuentaUniline/RespuestasEncuestaUniline";
import PreguntasUniline from "../pages/maestro/PreguntasUniline/PreguntasUniline";

//dashboard registro curso pages
import SubirCursoMaestro from "../pages/maestro/Subir_curso/CrearCurso/crear_curso";
import RegistroInformacionCurso from "../pages/maestro/Subir_curso/Dasboard_curso/Info_general/informacion_curso";
import QueAprenderaEstudiante from "../pages/maestro/Subir_curso/Dasboard_curso/Info_general/Learnings/vista_learnings";
import RegistroContenido from "../pages/maestro/Subir_curso/Dasboard_curso/Contenido_curso/contenido";
import PrecioCurso from "../pages/maestro/Subir_curso/Dasboard_curso/Publicacion_curso/precio";
import TareasEstudiantes from "../pages/maestro/Subir_curso/Tareas/tareas";
import EstudiantesCurso from "../pages/maestro/Subir_curso/Estudiantes/estudiantes";
import RegistroTaller from "../pages/maestro/Subir_curso/Dasboard_curso/Taller_curso/registro_taller";
import NotificacionesCurso from "../pages/maestro/Subir_curso/Dasboard_curso/NotificacionesCurso/Notifications";

import Error404 from "../pages/error404";
import DashboardPrincipal from "../pages/maestro/Dashboard_maestro";
import PagarCurso from "../pages/users/Compra_curso/pagar_curso";
import LayoutDashboardUser from "../components/Layout_User/layout_dashboar";
import VimeoReactNative from "../pages/users/Dashboard_Usuario/vimeo_react_native";
import OnChangeVista from "../pages/users/Dashboard_Usuario/vista_change";
import Questions from "../pages/users/QuestionCourse/Questions";
import GenerarCertificado from "../pages/users/Dashboard_Usuario/certificado";
import TallerPage from "../pages/taller/taller_page";
import AgradecimientoTaller from "../pages/taller/agradecimiento_taller";
import RecuperarPassword from "../components/Layout_User/layout_recovery";

export const router = createBrowserRouter([
  //Revisar estos a detalle, desde aquí
  {
    path: "/certificado/:url",
    element: <GenerarCertificado />,
  },
  {
    path: "/curso_taller/:slug",
    element: <TallerPage />,
  },
  {
    path: "/register_user/inscrito",
    component: <AgradecimientoTaller />,
  },
  {
    path: "/reset_password/:url",
    element: <RecuperarPassword />,
  },
  {
    path: "/dashboard/:url",
    element: <LayoutDashboardUser />,
  },
  {
    path: "/vimeo_mobil/:url/:info",
    element: <VimeoReactNative />,
  },
  {
    path: "/vimeo_mobil_change/:action",
    element: <OnChangeVista />,
  },
  {
    path: "/question-curse-uniline/:idCurso/curso/:slug",
    element: <Questions />,
  },
  //Hasta aquí
  {
    path: "/instructor/nuevo_curso",
    element: <SubirCursoMaestro />,
  },
  {
    path: "/compra",
    element: <PagarCurso />,
  },
  routesLuis,
  {
    path: "/instructor",
    element: <LayoutMaestro />,
    errorElement: <Error404 />,
    children: [
      {
        path: "cursos",
        element: <DashboardPrincipal />,
      },
      {
        path: "/instructor/paymentlinks",
        element: <LinksPago />,
      },
      {
        path: "/instructor/registro_usuarios",
        element: <RegistroUsuariosUniline />,
      },
      {
        path: "/instructor/banner",
        element: <RegistroBannersCarousel />,
      },
      {
        path: "/instructor/notificaciones",
        element: <NotificacionesAdmin />,
      },
      {
        path: "/instructor/ventas",
        element: <VentasMaestro />,
      },
      /* {
        path: "/instructor/homedesign",
        element: HomeDesign,
      }, */
      {
        path: "/instructor/preguntas_uniline",
        element: <PreguntasUniline />,
      },
      {
        path: "/instructor/respuestas_encuenta",
        element: <RespuestasEncuestaUniline />,
      },
    ],
  },
  {
    path: "/instructor/contenido_curso/:curso",
    element: <LayoutContenidoCurso />,
    errorElement: <Error404 />,
    children: [
      {
        path: "/instructor/contenido_curso/:curso/general",
        element: <RegistroInformacionCurso />,
      },
      {
        path: "/instructor/contenido_curso/:curso/learn",
        element: <QueAprenderaEstudiante />,
      },
      {
        path: "/instructor/contenido_curso/:curso/contenido",
        element: <RegistroContenido />,
      },
      {
        path: "/instructor/contenido_curso/:curso/precio",
        element: <PrecioCurso />,
      },
      {
        path: "/instructor/contenido_curso/:curso/tareas",
        element: <TareasEstudiantes />,
      },
      {
        path: "/instructor/contenido_curso/:curso/estudiantes",
        element: <EstudiantesCurso />,
      },
      {
        path: "/instructor/contenido_curso/:curso/taller",
        element: <RegistroTaller />,
      },
      {
        path: "/instructor/contenido_curso/:curso/notificaciones",
        element: <NotificacionesCurso />,
      },
    ],
  },
]);
