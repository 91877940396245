import LayoutUsers from "../components/Layout_User/layout_usuario";

//Users pages
import Home from "../pages/users/Home/home";
import BuscarCertificado from "../pages/users/BuscarCertificado/CertificadoIndex";
import ResultadoBusqueda from "../pages/users/Busqueda/resultados_busqueda";
import ResultadoBusquedaCategorias from "../pages/users/BusquedaCategorias/ResultadosCategorias";
import Carrito from "../pages/users/Carrito/carrito";
import MisCursos from "../pages/users/Cursos_usuario/mis_cursos";
import PerfilUsuario from "../pages/users/Perfil_usuario/perfil";
import Politicas from "../pages/users/Politicas/politicas";
import ImagenCorporativa from "../pages/users/Imagen_corporativa/imagen_corporativa";
import RegistroUsuario from "../pages/users/Registro/registro";
import VistaCurso from "../pages/users/Vista_curso";
import PagoSuccess from "../pages/users/Compra_curso/success_page";
import PagoFailed from "../pages/users/Compra_curso/failed_page";
import VistaPaquete from "../pages/users/Paquetes/VistaPaquete/VistaPaquete";

//other
import Error404 from "../pages/error404";
import LoginUsuario from "../pages/users/Login/login";
import StripeConfirm from "../pages/users/Compra_curso/stripeConfirm";
import Consultoria from "../pages/users/Consultoria";
import AppsPage from "../pages/users/Apps";
import PaymentLinkFailed from "../pages/maestro/LinksPago/PaymentFailed";
import PaymentLinkSuccess from "../pages/maestro/LinksPago/PaymentSuccess";

const routesLuis = {
  path: "/",
  element: <LayoutUsers />,
  errorElement: <Error404 />,
  children: [
    {
      path: "",
      element: <Home />,
    },
    {
      path: "/buscar-certificado",
      element: <BuscarCertificado />,
    },
    {
      path: "/busqueda/:url",
      element: <ResultadoBusqueda />,
    },
    {
      path: "/search",
      element: <ResultadoBusquedaCategorias />,
    },
    { path: "/carrito", element: <Carrito /> },
    {
      path: "/mis_cursos",
      element: <MisCursos />,
    },
    {
      path: "/perfil",
      element: <PerfilUsuario />,
    },
    {
      path: "/politicas",
      element: <Politicas />,
    },
    {
      path: "/imagen_corporativa",
      element: <ImagenCorporativa />,
    },
    {
      path: "/login",
      element: <LoginUsuario />,
    },
    {
      path: "/registro",
      element: <RegistroUsuario />,
    },
    {
      path: "/curso/:url",
      element: <VistaCurso />,
    },
    {
      path: "/consultoria",
      element: <Consultoria />,
    },
    {
      path: "/apps",
      element: <AppsPage />,
    },
    {
      path: "/paquete/:url",
      element: <VistaPaquete />,
    },
    {
      path: "/payment_success/:idPago",
      element: <PagoSuccess />,
    },
    {
      path: "/payment_failed/:idPago/:message",
      element: <PagoFailed />,
    },
    {
      path: "/payment_confirm",
      element: <StripeConfirm />,
    },
    {
      path: "/paymentlink/success/:idPaymentLink",
      element: <PaymentLinkSuccess />,
    },
    {
      path: "/paymentlink/failed/:idPaymentLink",
      element: <PaymentLinkFailed />,
      exact: true,
    },
    {
      path: "*",
      element: <Error404 />,
    },
  ],
};
export default routesLuis;
