import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { sxStyles } from "./styles";
import ImagenLogo from "../../images/uniline2.png";
import ImagenLogoDark from "../../images/unilineDark.png";
import ImagenAzul from "../../images/unilineAzul.png";
import CarritoNavbar from "./carrito";
import CategoriesContainer from "./NavSubcategories";
import Notifications from "../Notifications/Notifications";
import BottomNavigationResponsive from "./NavegacionResponsive";
import { getAuth, signOut } from "firebase/auth";
import { obtenerTokenFCM } from "../Firebase/firebaseInit";
import clienteAxios from "../../config/axios";
import CustomAvatar from "../CustomAvatar";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Toolbar,
  useTheme,
} from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import Brightness5Icon from "@mui/icons-material/Brightness5";
import BrightnessMediumIcon from "@mui/icons-material/BrightnessMedium";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import HomeIcon from "@mui/icons-material/Home";
import SchoolIcon from "@mui/icons-material/School";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import Event from "@mui/icons-material/Event";
import Devices from "@mui/icons-material/Devices";

function NavegacionUsuario(props) {
  const theme = useTheme();
  const styles = sxStyles(theme);
  const [darkTheme, setDarkTheme] = props.tema;
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [busqueda, setBusqueda] = useState("");
  const isMenuOpen = Boolean(anchorEl);
  let user = { _id: "" };
  let token = localStorage.getItem("token");
  const auth = getAuth();

  if (token !== "null") user = JSON.parse(localStorage.getItem("student"));

  const darkModeAction = () => {
    setDarkTheme(!darkTheme);
    localStorage.setItem("tema", !darkTheme);
  };

  const handleProfileMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const handleDrawerAction = () => setOpen(!open);
  const navigate = useNavigate();

  const obtenerBusqueda = (e) => setBusqueda(e.target.value);
  const buscarBD = () => {
    if (!busqueda) {
      return;
    }
    /* setBusqueda(''); */
    navigate(`/busqueda/${busqueda}`);
  };

  const pressEnter = (e) => {
    if (!e.target.defaultValue) return;
    if (e.key === "Enter") navigate(`/busqueda/${e.target.defaultValue}`);
  };

  const signOutUser = async () => {
    setLoading(true);
    try {
      const messagingToken = await obtenerTokenFCM();
      await clienteAxios.post("/user/signOut", {
        idUser: user._id,
        messagingToken,
      });

      await signOut(auth);

      localStorage.removeItem("token");
      localStorage.removeItem("student");
      localStorage.removeItem("urlActual");

      handleMenuClose();
      navigate("/");

      setLoading(false);
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    localStorage.removeItem("urlActual");
  }, []);

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Popover
      id={menuId}
      open={isMenuOpen}
      anchorEl={anchorEl}
      onClose={handleMenuClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      {token && user.rol === "Maestro" ? (
        <MenuItem
          onClick={handleMenuClose}
          component={Link}
          to="/instructor/cursos"
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </MenuItem>
      ) : (
        <div />
      )}
      <Divider />
      <MenuItem onClick={handleMenuClose} component={Link} to="/perfil">
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Mi cuenta" />
      </MenuItem>
      <MenuItem disablePadding onClick={darkModeAction}>
        <ListItemIcon>
          {darkTheme ? <Brightness5Icon /> : <BrightnessMediumIcon />}
        </ListItemIcon>
        <ListItemText
          primary={`tema: ${darkTheme === true ? "Oscuro" : "Claro"}`}
        />
      </MenuItem>
      <MenuItem onClick={() => signOutUser()}>
        <ListItemIcon>
          {loading ? (
            <CircularProgress size={20} />
          ) : (
            <ExitToAppIcon color="error" />
          )}
        </ListItemIcon>
        <ListItemText primary="Cerrar sesión" />
      </MenuItem>
    </Popover>
  );

  return (
    <div>
      <AppBar position="fixed" sx={styles.appbar}>
        <Toolbar
          variant="dense"
          style={{ padding: "0px 8px" }}
          sx={{ display: "flex" }}
        >
          <Box
            sx={{ display: { xs: "flex", sm: "flex", md: "flex", lg: "none" } }}
          >
            <IconButton
              edge="start"
              aria-label="show more"
              aria-haspopup="true"
              onClick={handleDrawerAction}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          {/* <Hidden mdUp>
              <CategoriasResponsive />
            </Hidden> */}
          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "none", lg: "flex" },
            }}
          >
            <Box component={Link} to="/" sx={styles.logo}>
              <img
                alt="logo navbar"
                src={darkTheme ? ImagenLogoDark : ImagenLogo}
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: "flex", sm: "flex", md: "flex", lg: "none" },
              ...styles.search,
            }}
          >
            <InputBase
              placeholder="¿Qué quieres aprender hoy?"
              sx={{
                color: "inherit",
                padding: (theme) => theme.spacing(1, 1, 1, 2),
                transition: (theme) => theme.transitions.create("width"),
                width: "100%",
                "& .MuiInputBase-input": {
                  [theme.breakpoints.up("sm")]: {
                    width: "30ch",
                  },
                },
              }}
              inputProps={{ "aria-label": "search" }}
              value={busqueda}
              onChange={obtenerBusqueda}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  pressEnter(event);
                }
              }}
            />
            <IconButton size="small" color="inherit" onClick={() => buscarBD()}>
              <SearchIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "none", lg: "flex" },
              ...styles.search2,
            }}
          >
            <InputBase
              placeholder="¿Qué quieres aprender hoy?"
              sx={{
                color: "inherit",
                padding: (theme) => theme.spacing(1, 1, 1, 2),
                transition: (theme) => theme.transitions.create("width"),
                width: "100%",
                "& .MuiInputBase-input": {
                  [theme.breakpoints.up("sm")]: {
                    width: "30ch",
                  },
                },
              }}
              inputProps={{ "aria-label": "search" }}
              value={busqueda}
              onChange={obtenerBusqueda}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  pressEnter(event);
                }
              }}
            />
            <IconButton size="small" color="inherit" onClick={() => buscarBD()}>
              <SearchIcon />
            </IconButton>
          </Box>
          <Box sx={styles.grow} />
          <Box
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "none",
                lg: "flex",
                alignItems: "center",
              },
            }}
          >
            <Button
              style={{
                textTransform: "none",
                backgroundColor: "#ff1744",
              }}
              color="inherit"
              component={Link}
              to="/apps"
              variant="contained"
              size="small"
              sx={styles.marginButton}
              disableElevation
              startIcon={<Devices />}
            >
              Necesito una App
            </Button>
            <Button
              style={{ textTransform: "none" }}
              color="inherit"
              component={Link}
              to="/"
              sx={styles.marginButton}
              /* startIcon={<Home />} */
            >
              Inicio
            </Button>
            <Button
              style={{ textTransform: "none" }}
              color="inherit"
              component={Link}
              to="/consultoria"
              sx={styles.marginButton}
              /* startIcon={<Event />} */
            >
              Consultoria
            </Button>
            <Button
              style={{ textTransform: "none" }}
              color="inherit"
              component={Link}
              to="/buscar-certificado"
              sx={styles.marginButton}
              /* startIcon={<SchoolIcon />} */
            >
              Certificados
            </Button>
            {!token ? (
              <Fragment>
                <Button
                  style={{ textTransform: "none" }}
                  color="inherit"
                  component={Link}
                  to="/registro"
                  sx={styles.marginButton}
                  variant="outlined"
                  size="small"
                >
                  Regístrate
                </Button>
                <Button
                  style={{
                    textTransform: "none",
                    backgroundColor: "#ff1744",
                  }}
                  color="secondary"
                  disableElevation
                  component={Link}
                  to="/login"
                  sx={styles.marginButton}
                  variant="contained"
                  size="small"
                >
                  Inicia Sesión
                </Button>
              </Fragment>
            ) : null}

            {token ? (
              <Button
                style={{ textTransform: "none" }}
                color="inherit"
                component={Link}
                to="/mis_cursos"
                sx={styles.marginButton}
                /* startIcon={<VideoLibraryIcon />} */
              >
                Mis cursos
              </Button>
            ) : (
              <div />
            )}
            {/* {token && user.rol === "Maestro" ? (
                <Button
                  style={{ textTransform: "none" }}
                  color="inherit"
                  component={Link}
                  to="/instructor/cursos"
                  className={classes.marginButton}
                  startIcon={<DashboardIcon />}
                >
                  Dashboard
                </Button>
              ) : (
                <div />
              )} */}
          </Box>
          <Box
            sx={{
              display: {
                xs: "none",
                sm: "flex",
                md: "flex",
                lg: "flex",
                alignItems: "center",
              },
            }}
          >
            {token ? (
              <Fragment>
                <IconButton
                  size="small"
                  color="inherit"
                  component={Link}
                  to="/carrito"
                >
                  <CarritoNavbar />
                </IconButton>
                <Notifications />
              </Fragment>
            ) : (
              <div />
            )}
            {token ? (
              <IconButton
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                size="small"
              >
                {!user.imagen ? (
                  <CustomAvatar name={user.name} size={30} fontSize={17} />
                ) : (
                  <Avatar
                    alt="foto de perfil"
                    src={user.imagen}
                    style={{ height: 30, width: 30, fontSize: 17 }}
                  />
                )}
              </IconButton>
            ) : (
              <div />
            )}
            {!token ? (
              <IconButton
                aria-label="show 17 theme config"
                color="inherit"
                onClick={darkModeAction}
              >
                {darkTheme ? <Brightness5Icon /> : <BrightnessMediumIcon />}
              </IconButton>
            ) : (
              <div />
            )}
          </Box>
          {/* <Hidden mdUp>
              <IconButton
                aria-label="show 17 new notifications"
                color="inherit"
                component={Link}
                to="/carrito"
              >
                <CarritoNavbar />
              </IconButton>
            </Hidden> */}
        </Toolbar>
        <Box
          sx={{
            display: { xs: "none", sm: "block" },
          }}
        >
          <Box sx={styles.backgroundCategories}>
            <CategoriesContainer />
          </Box>
        </Box>
      </AppBar>
      {renderMenu}
      <Drawer
        sx={styles.drawer}
        anchor="left"
        open={open}
        onClose={handleDrawerAction}
        PaperProps={{
          sx: styles.drawerPaper,
        }}
      >
        <div style={styles.drawerHeader}>
          <Box sx={styles.logoResponsive}>
            <img
              alt="logo navbar"
              src={darkTheme ? ImagenLogoDark : ImagenAzul}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          </Box>
          <div style={styles.grow} />
          <IconButton onClick={handleDrawerAction}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to="/"
              onClick={handleDrawerAction}
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Inicio" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to="/apps"
              onClick={handleDrawerAction}
            >
              <ListItemIcon>
                <Devices />
              </ListItemIcon>
              <ListItemText primary="Necesito una App" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to="/consultoria"
              onClick={handleDrawerAction}
            >
              <ListItemIcon>
                <Event />
              </ListItemIcon>
              <ListItemText primary="Consultoria" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              component={Link}
              to="/buscar-certificado"
              onClick={handleDrawerAction}
            >
              <ListItemIcon>
                <SchoolIcon />
              </ListItemIcon>
              <ListItemText primary="Verificar certificados" />
            </ListItemButton>
          </ListItem>
          {token ? (
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to="/mis_cursos"
                onClick={handleDrawerAction}
              >
                <ListItemIcon>
                  <VideoLibraryIcon />
                </ListItemIcon>
                <ListItemText primary="Mis cursos" />
              </ListItemButton>
            </ListItem>
          ) : (
            <div />
          )}
          {token && user.rol === "Maestro" ? (
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to="/instructor/cursos"
                onClick={handleDrawerAction}
              >
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItemButton>
            </ListItem>
          ) : (
            <div />
          )}
          {token ? (
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to="/carrito"
                onClick={handleDrawerAction}
              >
                <ListItemIcon>
                  <CarritoNavbar />
                </ListItemIcon>
                <ListItemText primary="Carrito" />
              </ListItemButton>
            </ListItem>
          ) : (
            <div />
          )}
        </List>
        <Divider />
        <List>
          {!token ? (
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to="/login"
                onClick={handleDrawerAction}
              >
                <ListItemIcon>
                  <MeetingRoomIcon />
                </ListItemIcon>
                <ListItemText primary="Iniciar sesión" />
              </ListItemButton>
            </ListItem>
          ) : (
            <div />
          )}
          {!token ? (
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to="/registro"
                onClick={handleDrawerAction}
              >
                <ListItemIcon>
                  <HowToRegIcon />
                </ListItemIcon>
                <ListItemText primary="Regístrate" />
              </ListItemButton>
            </ListItem>
          ) : (
            <div />
          )}
          {token ? (
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                to="/perfil"
                onClick={handleDrawerAction}
              >
                <ListItemIcon>
                  {!user.imagen ? (
                    <CustomAvatar name={user.name} size={30} fontSize={17} />
                  ) : (
                    <Avatar
                      alt="foto de perfil"
                      src={user.imagen}
                      style={{ height: 30, width: 30, fontSize: 17 }}
                    />
                  )}
                </ListItemIcon>
                <ListItemText primary="Mi cuenta" />
              </ListItemButton>
            </ListItem>
          ) : (
            <div />
          )}
          <ListItem disablePadding>
            <ListItemButton onClick={darkModeAction}>
              <ListItemIcon>
                {darkTheme ? <Brightness5Icon /> : <BrightnessMediumIcon />}
              </ListItemIcon>
              <ListItemText
                primary={`tema: ${darkTheme === true ? "Oscuro" : "Claro"}`}
              />
            </ListItemButton>
          </ListItem>
          {token ? (
            <ListItem disablePadding>
              <ListItemButton onClick={() => signOutUser()}>
                <ListItemIcon>
                  {loading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <ExitToAppIcon color="error" />
                  )}
                </ListItemIcon>
                <ListItemText primary="Cerrar Sesión" />
              </ListItemButton>
            </ListItem>
          ) : (
            <div />
          )}
        </List>
      </Drawer>
      <BottomNavigationResponsive props={props} />
      <div style={styles.offset} />
    </div>
  );
}
export default NavegacionUsuario;
