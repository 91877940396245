import React, { useState, useContext, useEffect, useCallback } from "react";
import { Box, Button, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Scroll from "../../../../../components/ScrolltoTop/scroll";
import AddIcon from "@mui/icons-material/Add";
/* import SaveIcon from "@mui/icons-material/Save"; */
import Bloques from "./bloques";
import clienteAxios from "../../../../../config/axios";
import { CursoContext } from "../../../../../context/curso_context";
import Spin from "../../../../../components/Spin/spin";
import MessageSnackbar from "../../../../../components/Snackbar/snackbar";
import { Done } from "@mui/icons-material";

const styles = {
  color: {
    backgroundColor: theme => theme.palette.background.paper,
    padding: 3,
  },
  margin: {
    margin: 1,
  },
  BoxActions: {
    display: "flex",
    justifyContent: "flex-end",
    padding: 1.5,
    backgroundColor: theme => theme.palette.background.paper,
    zIndex: 10,
    position: "fixed",
    bottom: 0,
    right: 0,
    width: "100%",
  },
};

export default function RegistroContenido() {
  const { datos, update } = useContext(CursoContext);
  const token = localStorage.getItem("token");
  const [bloques, setBloques] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    mensaje: "",
    status: "",
  });

  const handleClickOpen = () => {
    setOpen(!open);
  };

  const obtenerBloquesBD = useCallback(async () => {
    setLoading(true);
    if (!datos._id) return;
    await clienteAxios
      .get(`/course/data/${datos._id}`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        setLoading(false);
        setBloques(res.data);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          setSnackbar({
            open: true,
            mensaje: err.response.data.message,
            status: "error",
          });
        } else {
          setSnackbar({
            open: true,
            mensaje: "Al parecer no se a podido conectar al servidor.",
            status: "error",
          });
        }
      });
  }, [datos._id, token]);

  const guardarOrdenBD = async () => {
    setLoading(true);
    await clienteAxios
      .put(`/course/content/order/`, bloques, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        setSnackbar({
          open: true,
          mensaje: res.data.message,
          status: "success",
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          setSnackbar({
            open: true,
            mensaje: err.response.data.message,
            status: "error",
          });
        } else {
          setSnackbar({
            open: true,
            mensaje: "Al parecer no se a podido conectar al servidor.",
            status: "error",
          });
        }
      });
  };

  useEffect(() => {
    obtenerBloquesBD();
  }, [update, obtenerBloquesBD]);

  return (
    <Box boxShadow={2} mb={6} sx={styles.color} minHeight="80vh">
      <Spin loading={loading} />
      <MessageSnackbar
        open={snackbar.open}
        mensaje={snackbar.mensaje}
        status={snackbar.status}
        setSnackbar={setSnackbar}
      />
      <Scroll showBelow={250} bottomMargin={78} />
      <Box sx={styles.BoxActions} boxShadow={3}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          aria-label="Guardar"
          onClick={() => guardarOrdenBD()}
          startIcon={<Done />}
        >
          Guardar orden
        </Button>
      </Box>
      <Box>
        <Alert severity="info">
          <Typography>
            En este apartado podrás subir el contenido de tu curso, como
            bloques, temas, tareas y más.
          </Typography>
          <br />
          <Typography variant="subtitle2">
            Recuerda que cada cambio que realizes debes guardarlo en ese momento
          </Typography>
        </Alert>
      </Box>
      <Box>
        <Box my={1}>
          <Button
            startIcon={<AddIcon />}
            size="large"
            variant="text"
            color="primary"
            className="addButton"
            onClick={handleClickOpen}
          >
            Nuevo bloque
          </Button>
        </Box>
        <Bloques
          bloques={bloques}
          setBloques={setBloques}
          setOpen={setOpen}
          open={open}
        />
      </Box>
    </Box>
  );
}
