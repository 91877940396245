import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  AppBar,
  Box,
  MenuItem,
  Popover,
  Avatar,
  Drawer,
  List,
  IconButton,
  Divider,
  Typography,
  Toolbar,
  Button,
  Link as LinkMaterial,
  ListItem,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  useTheme,
} from "@mui/material";
import { getAuth, signOut } from "firebase/auth";
/* import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore'; */
import ListaContenido from "./lista";
import ContenidoDashboard from "./contenido_dashboard";
import ImagenLogo from "../../../images/uniline2.png";
import ImagenLogoDark from "../../../images/unilineDark.png";
import styles from "./styles";
import Spin from "../../../components/Spin/spin";
import MessageSnackbar from "../../../components/Snackbar/snackbar";
import clienteAxios from "../../../config/axios";
import Error500 from "../../error500";
import SpinNormal from "../../../components/Spin/spinNormal";
import { DashboardContext } from "../../../context/dashboar_context";
import VideoCurso from "./video_curso";
import ResponsiveDashboard from "./responsive_dashboard";
import { obtenerTokenFCM } from "../../../components/Firebase/firebaseInit";
import CustomAvatar from "../../../components/CustomAvatar";
import {
  AccountCircle,
  ArrowBackIos,
  Brightness5,
  BrightnessMedium,
  ExitToApp,
  Home,
  // KeyboardArrowDown,
  Menu,
  School,
  VideoLibrary,
} from "@mui/icons-material";

function DashboarUsuario(props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const classes = styles(theme);
  let user = { _id: "" };
  const token = localStorage.getItem("token");
  const [darkTheme, setDarkTheme] = props.tema;
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [openSecondary, setOpenSecondary] = useState(false);
  const {
    curso,
    setCurso,
    setProgreso,
    setEndTopic,
    updateCurso,
    setCalificado,
  } = useContext(DashboardContext);
  const { url: slugCourse } = useParams();
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    mensaje: "",
    status: "",
  });
  const isMenuOpen = Boolean(anchorEl);
  const auth = getAuth();

  if (token !== null) user = JSON.parse(localStorage.getItem("student"));

  if (!token || !user) {
    navigate("/");
  }

  const darkModeAction = () => {
    setDarkTheme(!darkTheme);
    localStorage.setItem("tema", !darkTheme);
  };

  const handleProfileMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const handleDrawer = () => setOpen(!open);
  const handleDrawerSecondary = () => setOpenSecondary(!openSecondary);

  const obtenerCursoBD = useCallback(async () => {
    setLoading(true);
    await clienteAxios
      .get(`/course/view/${slugCourse}/user-progress/${user._id}`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        setLoading(false);
        setCurso(res.data);
        //console.log(res);
        //Condicionar si es ya lo
        if (
          user.rol === "Estudiante" &&
          res.data.inscriptionStudent &&
          res.data.inscriptionStudent.studentAdvance &&
          parseFloat(res.data.inscriptionStudent.studentAdvance) >= 50 &&
          (!res.data.inscriptionStudent.questionUniline ||
            res.data.inscriptionStudent.questionUniline === false)
        ) {
          navigate(
            `/question-curse-uniline/${res.data.course._id}/curso/${res.data.course.slug}`
          );
        }
        setProgreso(
          res.data.inscriptionStudent
            ? res.data.inscriptionStudent.studentAdvance
            : 0
        );
        setEndTopic(res.data.endTopicView);
        if (res.data.commentStudentQualification !== null) {
          setCalificado(true);
        } else {
          setCalificado(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          setSnackbar({
            open: true,
            mensaje: err.response.data.message,
            status: "error",
          });
        } else {
          setSnackbar({
            open: true,
            mensaje: "Al parecer no se a podido conectar al servidor.",
            status: "error",
          });
        }
      });
  }, [
    slugCourse,
    token,
    setCurso,
    user._id,
    setProgreso,
    setEndTopic,
    setCalificado,
    navigate,
    user.rol,
  ]);

  const signOutUser = async () => {
    const messagingToken = await obtenerTokenFCM();
    await clienteAxios
      .post("/user/signOut", { idUser: user._id, messagingToken })
      .then((res) => res)
      .catch((err) => {
        //console.log(err);
      });
    await signOut(auth)
      .then(() => {
        // Sign-out successful.
        localStorage.removeItem("token");
        localStorage.removeItem("student");
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch((error) => {
        // An error happened.
        //console.log(error);
      });
  };

  useEffect(() => {
    obtenerCursoBD();
  }, [obtenerCursoBD, updateCurso]);

  if (loading) {
    return <Spin loading={loading} />;
  }

  if (curso.length === 0) {
    if (loading) {
      return <SpinNormal />;
    } else {
      return <Error500 />;
    }
  }

  if (
    curso.inscriptionStudent === null ||
    user._id !== curso.inscriptionStudent.idUser ||
    !curso.inscriptionStudent
  ) {
    navigate("/");
  }

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Popover
      id={menuId}
      open={isMenuOpen}
      anchorEl={anchorEl}
      onClose={handleMenuClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <MenuItem onClick={handleMenuClose} component={LinkMaterial} to="/perfil">
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
        <ListItemText primary="Mi perfil" />
      </MenuItem>
      <MenuItem onClick={darkModeAction}>
        <ListItemIcon>
          {darkTheme ? <Brightness5 /> : <BrightnessMedium />}
        </ListItemIcon>
        <ListItemText
          primary={`tema: ${darkTheme === true ? "Oscuro" : "Claro"}`}
        />
      </MenuItem>

      <MenuItem onClick={() => signOutUser()}>
        <ListItemIcon>
          <ExitToApp />
        </ListItemIcon>
        <ListItemText primary="Cerrar sesión" />
      </MenuItem>
    </Popover>
  );

  return (
    <div style={classes.root}>
      {/* <Hidden mdUp>
				<Button
					size="large"
					variant="contained"
					color="primary"
					onClick={handleDrawer}
					className={classes.iconSave}
				>
					Contenido
				</Button>
			</Hidden> */}
      <CssBaseline />
      <AppBar position="fixed" sx={classes.appBar}>
        <Toolbar variant="dense" style={{ padding: "0px 8px" }}>
          <Box sx={{ display: { md: "none", xs: "block" } }}>
            <IconButton
              size="small"
              aria-haspopup="true"
              onClick={handleDrawerSecondary}
              color="inherit"
            >
              <Menu />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Box sx={classes.logo}>
              <img
                alt="logo navbar"
                src={darkTheme ? ImagenLogoDark : ImagenLogo}
                style={classes.imagen}
              />
            </Box>
          </Box>
          <Box ml={3}>
            <Typography sx={classes.title} variant="h6" noWrap>
              <LinkMaterial
                href={curso.course.slug ? `/curso/${curso.course.slug}` : null}
                target="_blank"
                rel="noopener"
                underline="none"
                color="inherit"
              >
                {curso.course.title ? curso.course.title : ""}
              </LinkMaterial>
            </Typography>
          </Box>
          <div style={classes.grow} />
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Button
              style={{ textTransform: "none" }}
              color="inherit"
              component={Link}
              to="/"
              sx={classes.marginButton}
              startIcon={<Home />}
            >
              Inicio
            </Button>
            <Button
              color="inherit"
              style={{ textTransform: "none" }}
              component={Link}
              to="/mis_cursos"
              sx={classes.marginButton}
              startIcon={<VideoLibrary />}
            >
              Mis cursos
            </Button>
          </Box>

          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <IconButton
              size="small"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              {!user ? (
                <CustomAvatar size={30} fontSize={17} />
              ) : !user.imagen ? (
                <CustomAvatar name={user.name} size={30} fontSize={17} />
              ) : (
                <Avatar
                  alt="foto de perfil"
                  src={user.imagen}
                  style={{ height: 30, width: 30, fontSize: 17 }}
                />
              )}
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMenu}
      <Box sx={{ flexGrow: 1 }}>
        <Toolbar variant="dense" />
        <VideoCurso props={props} user={user} />
        <Box
          sx={{
            display: { xs: "block", sm: "block", md: "none" },
          }}
        >
          <ResponsiveDashboard user={user} curso={curso} />
        </Box>
        <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
          <ContenidoDashboard user={user} />
        </Box>
      </Box>

      {/* DRAWER RIGHT */}
      <Drawer
        anchor="right"
        variant="permanent"
        sx={{
          width: 400,
          display: { xs: "none", sm: "none", md: "block" },
        }}
      >
        <Toolbar variant="dense" />
        {/* <div style={classes.drawerContainer}> */}
        <ListaContenido props={props} curso={curso} />
        {/* </div> */}
      </Drawer>

      {/* DRAWER BOTTOM */}
      {/* <Box
        sx={{
          border: "10px solid red",
          display: { xs: "block", sm: "block", md: "none" },
        }}
      >
        <Drawer
          anchor="bottom"
          variant="persistent"
          sx={classes.drawerPaperResponsive}
          open={open}
        >
          <div style={classes.drawerHeader}>
            <IconButton onClick={handleDrawer}>
              <KeyboardArrowDown />
            </IconButton>
          </div>
          <Divider />
          <div style={classes.drawerContainer}>
            <ListaContenido props={props} curso={curso} />
          </div>
        </Drawer>
      </Box> */}

      {/* MENÚ LATERAL */}
      <Box sx={{ display: { md: "none", xs: "block" } }}>
        <Drawer
          anchor="left"
          sx={classes.drawerPaperSecondary}
          open={openSecondary}
          onClose={handleDrawerSecondary}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button color="inherit" component={Link} to="/">
              <Box sx={classes.logoResponsive}>
                <img
                  alt="logo navbar"
                  src={darkTheme ? ImagenLogoDark : ImagenLogo}
                  style={classes.imagen}
                />
              </Box>
            </Button>
            <IconButton onClick={handleDrawerSecondary}>
              <ArrowBackIos />
            </IconButton>
          </Box>
          <Divider />
          <List>
            <ListItem
              button
              component={Link}
              to="/perfil"
              onClick={handleDrawer}
            >
              <ListItemIcon>
                {!user ? (
                  <CustomAvatar />
                ) : !user.imagen ? (
                  <CustomAvatar name={user.name} />
                ) : (
                  <Avatar alt="foto de perfil" src={user.imagen} />
                )}
              </ListItemIcon>
              <ListItemText primary="Mi perfil" />
            </ListItem>
            <ListItem button onClick={handleDrawer} component={Link} to="/">
              <ListItemIcon>
                <Home />
              </ListItemIcon>
              <ListItemText primary="Inicio" />
            </ListItem>
            <ListItem
              button
              onClick={handleDrawer}
              component={Link}
              to="/mis_cursos"
            >
              <ListItemIcon>
                <School />
              </ListItemIcon>
              <ListItemText primary="Mis cursos" />
            </ListItem>
            <ListItem button onClick={darkModeAction}>
              <ListItemIcon>
                {darkTheme ? <Brightness5 /> : <BrightnessMedium />}
              </ListItemIcon>
              <ListItemText
                primary={`tema: ${darkTheme === true ? "Oscuro" : "Claro"}`}
              />
            </ListItem>
            <ListItem button onClick={() => signOutUser()}>
              <ListItemIcon>
                <ExitToApp />
              </ListItemIcon>
              <ListItemText primary="Cerrar sesión" />
            </ListItem>
          </List>
        </Drawer>
      </Box>

      <Spin loading={loading} />
      <MessageSnackbar
        open={snackbar.open}
        mensaje={snackbar.mensaje}
        status={snackbar.status}
        setSnackbar={setSnackbar}
      />
    </div>
  );
}

export default DashboarUsuario;
