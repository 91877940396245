import React from "react";
import PropTypes from "prop-types";
import ListaContenido from "./lista";
import ContenidoDashboard from "./contenido_dashboard";
import ComentariosCurso from "./Comentarios/comentarios";
import { AppBar, Box, Tab, Tabs, useTheme } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ResponsiveDashboard({ user, curso }) {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{ flexGrow: 1, backgroundColor: theme.palette.background.paper }}
      style={{ padding: "0%" }}
    >
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          style={{ padding: "0%" }}
        >
          <Tab
            label="Contenido Curso"
            {...a11yProps(0)}
            sx={{
              color:
                value === 0
                  ? theme.palette.textWhite.primary
                  : theme.palette.textWhite.secondary,
              "&.Mui-selected": {
                color: theme.palette.textWhite.primary,
              },
            }}
          />
          <Tab
            label="Información Curso"
            {...a11yProps(1)}
            sx={{
              color:
                value === 1
                  ? theme.palette.textWhite.primary
                  : theme.palette.textWhite.secondary,
              "&.Mui-selected": {
                color: theme.palette.textWhite.primary,
              },
            }}
          />
          <Tab
            label="Comentarios"
            {...a11yProps(2)}
            sx={{
              color:
                value === 2
                  ? theme.palette.textWhite.primary
                  : theme.palette.textWhite.secondary,
              "&.Mui-selected": {
                color: theme.palette.textWhite.primary,
              },
            }}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <ListaContenido curso={curso} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ContenidoDashboard user={user} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        {/* <Box minHeight={200} style={{padding: '0%'}}> */}
        <ComentariosCurso curso={curso} />
        {/* </Box> */}
      </TabPanel>
    </Box>
  );
}
