import React, { useState, useContext, useCallback } from "react";
import clienteAxios from "../../config/axios";
import MessageSnackbar from "../Snackbar/snackbar";
import Spin from "../Spin/spin";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { NavContext } from "../../context/context_nav";
import {
  AdquirirCursoGratis,
  AgregarCarritoBD,
  CanjearCupon,
} from "../../pages/users/PeticionesCompras/peticiones_compras";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { app, obtenerTokenFCM } from "./firebaseInit";
import { Button } from "@mui/material";

function Firebase({ handleClose }) {
  const [loading, setLoading] = useState(false);
  const { setError, carrito, misCursos } = useContext(NavContext);
  const [snackbar, setSnackbar] = useState({
    open: false,
    mensaje: "",
    status: "",
  });
  const provider = new GoogleAuthProvider();
  const auth = getAuth(app);
  const navigate = useNavigate();

  const obtenerMisCursos = async (user, token) => {
    try {
      const res = await clienteAxios.get(`/course/user/${user._id}`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      });
      return res.data;
    } catch (err) {
      console.error("Error al obtener cursos:", err);
      return null; // O devuelve un valor que puedas manejar
    }
  };

  const canjearCupon = useCallback(
    async ({ curso, cupon, urlActual }) => {
      setLoading(true);
      try {
        let token = localStorage.getItem("token");
        let user = JSON.parse(localStorage.getItem("student"));

        const result = await CanjearCupon(token, user, curso, cupon);
        if (!result.status || result.status !== 200) {
          localStorage.removeItem("coupon");
          setError({ error: true, message: result });
          navigate(urlActual);
          return;
        }
        localStorage.removeItem("coupon");
        navigate("/mis_cursos");
      } catch (err) {
        console.error("Error en canjearCupon:", err);
        localStorage.removeItem("coupon");
        setError({ error: true, message: err.message || err });
        navigate(urlActual);
      } finally {
        setLoading(false);
      }
    },
    [navigate, setError]
  );

  const agregarCarrito = useCallback(
    async ({ curso, urlActual }) => {
      setLoading(true);
      try {
        let token = localStorage.getItem("token");
        let user = JSON.parse(localStorage.getItem("student"));
        let course = false;
        let cart = false;

        course = misCursos.some((res) => res.idCourse._id === curso);
        cart = carrito?.courses?.some((res) => res._id === curso);

        if (course) {
          localStorage.removeItem("cart");
          navigate(`/dashboard/${curso}`);
          return;
        }

        if (cart) {
          localStorage.removeItem("cart");
          navigate("/carrito");
          return;
        }

        const result = await AgregarCarritoBD(token, user, curso);
        if (!result.status || result.status !== 200) {
          localStorage.removeItem("cart");
          setError({ error: true, message: result });
          navigate(urlActual);
          return;
        }
        localStorage.removeItem("cart");
        navigate("/carrito");
      } catch (err) {
        console.error("Error en agregarCarrito:", err);
        localStorage.removeItem("cart");
        setError({ error: true, message: err.message || err });
        navigate(urlActual);
      } finally {
        setLoading(false);
      }
    },
    [navigate, setError, carrito, misCursos]
  );

  const comprarCurso = useCallback(
    async ({ curso }) => {
      setLoading(true);
      try {
        let token = localStorage.getItem("token");
        let user = JSON.parse(localStorage.getItem("student"));

        const misCursos = await obtenerMisCursos(user, token);
        if (!misCursos) throw new Error("Error al obtener mis cursos");

        let course = misCursos.some(
          (res) => res.idCourse._id === curso[0].idCourse
        );

        if (course) {
          localStorage.removeItem("buy");
          localStorage.setItem(
            "payment",
            JSON.stringify({ user, courses: curso })
          );
          setTimeout(() => {
            navigate(`/dashboard/${curso[0].course.slug}`);
          }, 500);
          return;
        }
        localStorage.removeItem("buy");
        localStorage.setItem(
          "payment",
          JSON.stringify({ user, courses: curso })
        );
        setTimeout(() => {
          navigate("/compra");
        }, 500);
      } catch (err) {
        console.error("Error en comprarCurso:", err);
      } finally {
        setLoading(false);
      }
    },
    [navigate]
  );

  const adquirirCursoGratis = useCallback(
    async ({ curso, urlActual }) => {
      setLoading(true);
      try {
        let token = localStorage.getItem("token");
        let user = JSON.parse(localStorage.getItem("student"));

        const misCursos = await obtenerMisCursos(user, token);
        if (!misCursos) throw new Error("Error al obtener mis cursos");

        let course = misCursos.some((res) => res.idCourse._id === curso._id);

        if (course) {
          localStorage.removeItem("free");
          setTimeout(() => {
            navigate(`/dashboard/${curso.slug}`);
          }, 500);
          return;
        }

        const result = await AdquirirCursoGratis(curso, user, token);
        if (!result.status || result.status !== 200) {
          localStorage.removeItem("free");
          navigate("/mis_cursos");
        } else {
          localStorage.removeItem("free");
          setError({ error: true, message: result });
          navigate(urlActual);
          return;
        }
      } catch (err) {
        console.error("Error en adquirirCursoGratis:", err);
        setError({ error: true, message: err.message || err });
        navigate(urlActual);
      } finally {
        setLoading(false);
      }
    },
    [navigate, setError]
  );

  const loginWithGoogle = async () => {
    setLoading(true);
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const messagingToken = await obtenerTokenFCM();

      const res = await clienteAxios.post("/user/firebase", {
        email: user.email,
        name: user.displayName,
        messagingToken,
      });

      const token = res.data.token;
      const decoded = jwtDecode(token);
      localStorage.setItem("token", token);
      localStorage.setItem("student", JSON.stringify(decoded));

      const cuponItem = JSON.parse(localStorage.getItem("coupon"));
      const cartItem = JSON.parse(localStorage.getItem("cart"));
      const buyItem = JSON.parse(localStorage.getItem("buy"));
      const freeItem = JSON.parse(localStorage.getItem("free"));
      const packItem = JSON.parse(localStorage.getItem("pack"));

      if (cuponItem) {
        canjearCupon(cuponItem);
      } else if (cartItem) {
        agregarCarrito(cartItem);
      } else if (buyItem) {
        comprarCurso(buyItem);
      } else if (freeItem) {
        adquirirCursoGratis(freeItem);
      } else if (packItem) {
        handleClose();
        navigate(packItem);
      } else {
        navigate("/");
      }
    } catch (err) {
      console.error("Error en loginWithGoogle:", err);
      setSnackbar({
        open: true,
        mensaje:
          err.response?.data?.message || err.message || "Error inesperado.",
        status: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Spin loading={loading} />
      <MessageSnackbar
        open={snackbar.open}
        mensaje={snackbar.mensaje}
        status={snackbar.status}
        setSnackbar={setSnackbar}
      />
      <Button
        disableElevation
        color="secondary"
        variant="contained"
        size="large"
        fullWidth
        onClick={() => loginWithGoogle()}
        startIcon={<FontAwesomeIcon icon={faGoogle} />}
        sx={{ textTransform: "none" }}
      >
        Inicia sesión con Google
      </Button>
    </div>
  );
}

export default Firebase;
