import React from "react";
import { Grid, Box, Container, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import imagen from "../../../../images/SchoolHome.gif";
import blog from "../../../../images/Blog.png";

export default function BannerInformativo() {
  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.background.default,
      }}
    >
      <Container maxWidth="lg">
        <Box mt={5}>
          <Typography align="center" variant="h3" sx={{ fontWeight: "bold" }}>
            UNILINE, una escuela que nunca para...
          </Typography>
        </Box>
        <Grid container>
          <Grid item md={6} xs={12} align="center">
            <img
              alt="Personal animado"
              src={imagen}
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                borderRadius: "10%",
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Box mt={10}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                <FiberManualRecordIcon sx={{ color: "#08f", fontSize: 30 }} />
                Clases desde casa
              </Typography>
              <Box ml={4} mt={2}>
                <Typography variant="h5">
                  Aprende de la mejor manera, con la mejor comodidad, desde tu
                  casa.
                </Typography>
              </Box>

              <Box mt={4}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  <FiberManualRecordIcon sx={{ color: "#08f", fontSize: 30 }} />
                  Conciso y claro
                </Typography>
              </Box>
              <Box ml={4} mt={2}>
                <Typography variant="h5">
                  Las mejores explicaciones, lo más claro posible y sin dudas.
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          direction={{
            xs: "column-reverse",
            md: "row",
          }}
        >
          <Grid item md={6} xs={12}>
            <Box mt={3}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                <FiberManualRecordIcon sx={{ color: "#08f", fontSize: 30 }} />
                Blogs
              </Typography>
              <Box ml={4} mt={2}>
                <Typography variant="h5">
                  Tener la facilidad de calificar tus cursos, dar comentarios y
                  resolver todas tus dudas.
                </Typography>
              </Box>

              <Box mt={4}>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  <FiberManualRecordIcon sx={{ color: "#08f", fontSize: 30 }} />
                  Audio y Video
                </Typography>
              </Box>
              <Box ml={4} mt={2}>
                <Typography variant="h5">
                  El mejor audio y video en nuestros cursos, la mayor calidad
                  para nuestros alumnos.
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={6} xs={12} align="center">
            <img
              alt="Blog animado"
              src={blog}
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                borderRadius: "10%",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
