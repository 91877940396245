import React, { useEffect } from "react";
import { Box, Container, Hidden, Typography } from "@mui/material";
import Imagen from "../../../images/imagenCorporativa.png";
import ExtensionIcon from "@mui/icons-material/Extension";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import UpdateIcon from "@mui/icons-material/Update";
import Scroll from "../../../components/ScrolltoTop/scroll";
import { Parallax } from "rc-scroll-anim";

export default function ImagenCorporativa() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Scroll showBelow={250} />
      <Hidden xlUp>
        <Parallax
          animation={{ x: 0, playScale: [1, 1.8] }}
          style={{ transform: "translateX(-1940px)" }}
        >
          <Box
            sx={{
              zIndex: 0,
              backgroundColor: "background.paper",
              height: "2000px",
              width: "100vw",
              position: "absolute",
            }}
          />
        </Parallax>
      </Hidden>

      <Hidden lgDown>
        <Parallax
          animation={{ x: 0, playScale: [1, 1.8] }}
          style={{ transform: "translateX(-2600px)" }}
        >
          <Box
            sx={{
              zIndex: 0,
              backgroundColor: "background.paper",
              height: "2000px",
              width: "100vw",
              position: "absolute",
            }}
          />
        </Parallax>
      </Hidden>

      <Box>
        {/* Imagen principal */}
        <Box height="90vh">
          <img
            alt="imagen corporativa"
            width="100%"
            height="100%"
            src={Imagen}
          />
        </Box>

        {/* Nuestro sueño */}
        <Box
          height="80vh"
          textAlign="center"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Container>
            <Parallax
              animation={{ x: 0, opacity: 1, playScale: [0, 0.6] }}
              style={{ transform: "translateX(-100px)", opacity: 0 }}
            >
              <Box my={5}>
                <Typography variant="h2">Nuestro Sueño</Typography>
              </Box>
            </Parallax>
            <Parallax
              animation={{ x: 0, opacity: 1, playScale: [0.0, 0.5] }}
              style={{ transform: "translateX(-100px)", opacity: 0 }}
            >
              <Box my={4}>
                <Typography variant="h5">
                  Nuestro Sueño es ayudar a trabajadores, empresarios,
                  emprendedores, profesores, alumnos y toda persona que quiera
                  desarrollarse y crecer profesionalmente a través de nuestros
                  cursos en línea.
                </Typography>
              </Box>
            </Parallax>
          </Container>
        </Box>

        {/* Valores */}
        <Box
          textAlign="center"
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ backgroundColor: "background.paper" }}
        >
          <div>
            <Parallax
              animation={{ x: 0, opacity: 1, playScale: [0.0, 0.5] }}
              style={{ transform: "translateX(100px)", opacity: 0 }}
            >
              <Box mb={10}>
                <Typography variant="h2">Valores</Typography>
              </Box>
            </Parallax>

            {/* Coherencia */}
            <Parallax
              animation={{ x: 0, opacity: 1, playScale: [0.0, 0.8] }}
              style={{ transform: "translateX(-300px)", opacity: 0 }}
            >
              <Box
                m={10}
                p={5}
                boxShadow={3}
                width={500}
                borderRadius={3}
                sx={{ backgroundColor: "#d1c4e9" }}
              >
                <Box my={2}>
                  <Typography variant="h4">Coherencia</Typography>
                </Box>
                <ExtensionIcon sx={{ fontSize: 100 }} />
                <Box textAlign="justify">
                  <Typography variant="h6">
                    Cada curso ofrecido dentro de nuestra plataforma será por
                    una persona experta en el área, con conocimientos y
                    experiencia.
                  </Typography>
                </Box>
              </Box>
            </Parallax>

            {/* Innovación */}
            <Parallax
              animation={{ x: 0, opacity: 1, playScale: [0.0, 0.8] }}
              style={{ transform: "translateX(300px)", opacity: 0 }}
            >
              <Box
                m={10}
                p={5}
                boxShadow={3}
                width={500}
                borderRadius={3}
                sx={{ backgroundColor: "#c5cae9" }}
              >
                <Box my={2}>
                  <Typography variant="h4">Innovación</Typography>
                </Box>
                <EmojiObjectsIcon sx={{ fontSize: 100 }} />
                <Box textAlign="justify">
                  <Typography variant="h6">
                    En UNILINE creamos metodologías para una experiencia
                    agradable de navegación y aprendizaje.
                  </Typography>
                </Box>
              </Box>
            </Parallax>

            {/* Actualización */}
            <Parallax
              animation={{ x: 0, opacity: 1, playScale: [0.0, 0.8] }}
              style={{ transform: "translateX(-300px)", opacity: 0 }}
            >
              <Box
                m={10}
                p={5}
                boxShadow={3}
                width={500}
                borderRadius={3}
                sx={{ backgroundColor: "#bbdefb" }}
              >
                <Box my={2}>
                  <Typography variant="h4">Actualización</Typography>
                </Box>
                <UpdateIcon sx={{ fontSize: 100 }} />
                <Box textAlign="justify">
                  <Typography variant="h6">
                    Actualizamos el contenido a diario, ofreciendo las mejores
                    herramientas para tu crecimiento profesional.
                  </Typography>
                </Box>
              </Box>
            </Parallax>
          </div>
        </Box>
      </Box>
    </div>
  );
}
