import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  InputBase,
  Grid2 as Grid,
  LinearProgress,
} from "@mui/material";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import ImagenVideo from "../../../../../images/Video-tutorial-bro.png";
import VimeoReproductor from "../../../../../components/Vimeo_Reproductor/Vimeo";
import clienteAxios from "../../../../../config/axios";
import { CursoContext } from "../../../../../context/curso_context";
import MessageSnackbar from "../../../../../components/Snackbar/snackbar";
import BackDropVideo from "../../../../../components/Spin/backdropVideo";
import { Upload } from "tus-js-client";

const styles = {
  input: {
    display: "none",
  },
  imagen: {
    height: 250,
    width: 250,
  },
};

export default function SubirVideoTema({ tema }) {
  const token = localStorage.getItem("token");
  const { update, setUpdate } = useContext(CursoContext);
  const [progress, setProgress] = useState(0);
  const [fileVideo, setFileVideo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [backdrop, setBackdrop] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    mensaje: "",
    status: "",
  });

  const getFileVideo = (e) => {
    try {
      setFileVideo(e.target.files[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const successAPI = (message) => {
    setSnackbar({
      open: true,
      mensaje: message,
      status: "success",
    });
    setUpdate(!update);
    setLoading(false);
    setBackdrop(false);
    setProgress(0);
    setFileVideo(null);
  };

  const errorAPI = (err) => {
    setLoading(false);
    setBackdrop(false);
    if (err.response) {
      setSnackbar({
        open: true,
        mensaje: err.response.data.message,
        status: "error",
      });
    } else {
      setSnackbar({
        open: true,
        mensaje: "Al parecer no se a podido conectar al servidor.",
        status: "error",
      });
    }
  };

  const handleSubmit = () => {
    if (!fileVideo) return;
    setLoading(true);
    setBackdrop(true);
    clienteAxios
      .post(
        `/course/uploadVideo`,
        {
          name: tema.topicTitle,
          size: fileVideo.size,
        },
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
        const upload = new Upload(fileVideo, {
          uploadUrl: res.data.uploadLink,
          endpoint: res.data.uploadLink,
          chunkSize: 5 * 1024 * 1024, // Tamaño de cada fragmento (5 MB)
          retryDelays: [0, 1000, 3000, 5000], // Retrasos en reintentos
          onProgress: (bytesUploaded, bytesTotal) => {
            const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
            setLoading(false);
            setProgress(parseInt(percentage));
          },
          onError: (error) => {
            console.log("Error al subir video:", error, error.message);
            setLoading(false);
            setBackdrop(false);
            setSnackbar({
              open: true,
              mensaje: "Hubo un error: " + error,
              status: "error",
            });
          },
          onSuccess: (res) => {
            clienteAxios
              .put(
                `/course/topic/video/${tema._id}`,
                {
                  title: tema.topicTitle,
                },
                {
                  headers: {
                    Authorization: `bearer ${token}`,
                  },
                }
              )
              .then((res) => {
                successAPI(res.data.message);
              })
              .catch((err) => {
                errorAPI(err);
              });
          },
        });

        upload.start();
      })
      .catch((err) => {
        errorAPI(err);
      });
  };

  const handleDelete = () => {
    clienteAxios
      .put(
        `/course/deleteVideoTopic/${tema._id}`,
        {
          video: tema.keyTopicVideo,
        },
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
        successAPI(res.data.message);
      })
      .catch((err) => {
        errorAPI(err);
      });
  };

  return (
    <Box>
      <MessageSnackbar
        open={snackbar.open}
        mensaje={snackbar.mensaje}
        status={snackbar.status}
        setSnackbar={setSnackbar}
      />
      <BackDropVideo
        backdrop={backdrop}
        loading={loading}
        progress={progress}
      />
      <Grid container spacing={1}>
        <Grid size={{ lg: 4 }}>
          <Box
            height={150}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {tema.keyTopicVideo ? (
              <VimeoReproductor
                idVideo={tema.keyTopicVideo ? tema.keyTopicVideo : ""}
                width="100%"
                height="100%"
              />
            ) : (
              <img
                alt="video del tema"
                src={ImagenVideo}
                style={styles.imagen}
              />
            )}
          </Box>
        </Grid>
        <Grid>
          <Box height="100%" display="flex" alignItems="center">
            <Box>
              <input
                name="keyTopicVideo"
                style={styles.input}
                id={`topic-video-${tema._id}`}
                type="file"
                onChange={getFileVideo}
              />

              <label htmlFor={`topic-video-${tema._id}`}>
                <Button
                  startIcon={<VideoCallIcon />}
                  color="primary"
                  variant="outlined"
                  component="span"
                  disabled={!tema.keyTopicVideo ? false : true}
                >
                  Cargar archivo
                </Button>
              </label>
              <InputBase
                value={fileVideo ? fileVideo.name : ""}
                placeholder=" Selecciona un video"
                inputProps={{ "aria-label": "naked", readOnly: true }}
              />
              {loading ? (
                <Box mt={1}>
                  <LinearProgress />
                </Box>
              ) : null}
              <Grid container spacing={1}>
                <Grid>
                  {!tema.keyTopicVideo ? (
                    <Box mt={1}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleSubmit()}
                      >
                        Subir
                      </Button>
                    </Box>
                  ) : null}
                </Grid>
                <Grid>
                  {tema.keyTopicVideo ? (
                    <Box mt={1}>
                      <Grid container>
                        <Grid>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => handleDelete()}
                          >
                            Eliminar video
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : null}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

/* function LinearProgressWithLabel(props) {
	return (
		<Box display="flex" alignItems="center">
			<Box width="100%" mr={2}>
				<LinearProgress variant="determinate" {...props} />
			</Box>
			<Box minWidth={35}>
				<Typography variant="body2" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
			</Box>
		</Box>
	);
} */
