import React, { Fragment } from "react";
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Rating,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import "moment/locale/es-mx";
import Reactions from "./Reactions";
import Respuestas from "./Respuestas";
import {
  ComentariosCursoCtx,
  ComentariosCursoProvider,
} from "../../../../context/comentariosCursoCtx";
import CustomAvatar from "../../../../components/CustomAvatar";
import { CheckCircle, Star } from "@mui/icons-material";

export default function ListaComentarios({ comentario, curso }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Fragment>
      <ComentariosCursoProvider>
        <Card
          sx={{ marginTop: theme.spacing(2), border: 0 }}
          variant="outlined"
        >
          <CardHeader
            sx={{ padding: "8px 8px 0 8px" }}
            avatar={
              comentario.idUser.urlImage ? (
                <Avatar
                  aria-label="recipe"
                  alt="imagen user"
                  src={comentario.idUser.urlImage}
                />
              ) : (
                <CustomAvatar name={comentario.idUser.name} />
              )
            }
            title={
              <Box display="flex">
                <Box mr={1}>
                  <Typography>{comentario.idUser.name}</Typography>
                </Box>
                {comentario.idUser._id === curso.course.idProfessor._id ? (
                  <CheckCircle color="primary" />
                ) : null}
              </Box>
            }
            subheader={moment(comentario.createdAt).format("LLL")}
            action={
              isSmallScreen ? (
                // Vista para pantallas pequeñas
                <Box display="flex">
                  <Star htmlColor="#FFE400" />
                  <Box ml={1}>
                    <Typography variant="subtitle1">
                      <b>{comentario.qualification}</b>
                    </Typography>
                  </Box>
                </Box>
              ) : (
                // Vista para pantallas grandes
                <Rating
                  name="read-only"
                  value={comentario.qualification}
                  readOnly
                  precision={0.5}
                  size="small"
                />
              )
            }
          />

          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              {comentario.comment}
            </Typography>
          </CardContent>
          <CardActions sx={{ justifyContent: "space-between", padding: 1 }}>
            <ItemsActions comentario={comentario} curso={curso} />
          </CardActions>
          <Respuestas comentario={comentario} curso={curso} />
        </Card>
      </ComentariosCursoProvider>
    </Fragment>
  );
}

const ItemsActions = ({ curso, comentario }) => {
  const { reply } = React.useContext(ComentariosCursoCtx);
  return (
    <>
      <Typography variant="button"> {reply.totalDocs} respuestas</Typography>
      <Reactions curso={curso} comentario={comentario} />
    </>
  );
};
