import React, { Fragment } from "react";
import moment from "moment";
import ExcelExportComponent from "../../../components/ExcelExportComponent";
import clienteAxios from "../../../config/axios";

const formatTable = (users = []) => {
  return users.map((user) => ({
    name: user.nameUser,
    email: user.emailUser,
    phone: user.phoneUser ? user.phoneUser : "-",
    curso: user.nameCurso,
    pregunta: user.textQuestion,
    respuesta: user.answer,
    fecha: moment(user.createdAt).format("DD/MM/YYYY"),
  }));
};

export default function ExportExcelAnswersUsers({ users }) {
  const excelData = formatTable(users);
  const [loading, setLoading] = React.useState(false);
  const token = localStorage.getItem("token");

  const getUsersAnswers = async () => {
    try {
      setLoading(true);
      const data = await clienteAxios.get(`/question/user/answers/all?search`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      });
      setLoading(false);
      return formatTable(data.data);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <ExcelExportComponent
        data={excelData}
        name="Respuestas Uniline"
        getQueryData={getUsersAnswers}
        loading={loading}
      />
    </Fragment>
  );
}
