import React from "react";
import CardCursoPack from "../CardCursoPack";
import { Box, Grid2 as Grid } from "@mui/material";

function ContainerCursos({ paquete }) {
  return (
    <Box>
      <Box my={2}>
        <Grid container spacing={2}>
          {paquete.courses.map((res, index) => (
            <Grid key={index} size={{ xs: 12, md: 4 }}>
              <CardCursoPack cursoObj={res} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default ContainerCursos;
