import {
  AssignmentInd,
  Home,
  Notifications,
  Panorama,
  QuestionAnswer,
  Receipt,
  RecordVoiceOver,
  Subscriptions,
  TrendingUp,
} from "@mui/icons-material";

export const NavItems = [
  {
    segment: "instructor/cursos",
    title: "Cursos",
    icon: <Subscriptions />,
  },
  {
    segment: "instructor/paymentlinks",
    title: "Enlaces de pago",
    icon: <Receipt />,
    adminRol: true
  },
  {
    segment: "instructor/ventas",
    title: "Ventas",
    icon: <TrendingUp />,
    adminRol: true
  },
  {
    segment: "instructor/registro_usuarios",
    title: "Usuarios",
    icon: <AssignmentInd />,
    adminRol: true
  },
  {
    segment: "instructor/banner",
    title: "Banners",
    icon: <Panorama />,
    adminRol: true
  },
  {
    segment: "instructor/notificaciones",
    title: "Notificaciones",
    icon: <Notifications />,
    adminRol: true
  },
  {
    segment: "instructor/preguntas_uniline",
    title: "Preguntas Encuesta",
    icon: <QuestionAnswer />,
    adminRol: true
  },
  {
    segment: "instructor/respuestas_encuenta",
    title: "Respuestas Encuesta",
    icon: <RecordVoiceOver />,
    adminRol: true
  },
  {
    segment: "",
    title: "Pagina principal",
    icon: <Home />,
  },
];

export const getNavItems = (auth) => {
    if(auth?.admin === true) return NavItems;
    return NavItems.filter(res => !res.adminRol);
}

export const HeaderTitle = {
  registro_usuarios: "Usuarios registrados",
  cursos: "Tus cursos",
  paymentlinks: "Enlaces de pago",
  estadisticas: "Estadisticas",
  categorias: "Categorias",
  productos: "Productos y servicios",
  banner: "Banners",
  notificaciones: "Notificaciones",
  ventas: "Ventas",
  paquetes: "Paquetes de promoción",
  homedesign: "Secciones Home",
  respuestas_encuenta: "Respuestas encuesta",
};
