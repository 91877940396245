import React, { useCallback, useEffect, useState } from "react";
import {
  Avatar,
  AvatarGroup,
  Box,
  CardMedia,
  Container,
  Grid2 as Grid,
  Typography,
  useTheme,
} from "@mui/material";
import clienteAxios from "../../../../config/axios";
import Spin from "../../../../components/Spin/spin";
import Error500 from "../../../error500";
import MetaTags from "react-meta-tags";
import MessageSnackbar from "../../../../components/Snackbar/snackbar";
import {
  formatoFechaCurso,
  formatoMexico,
} from "../../../../config/reuserFunction";
import ActionsPaquete from "./ActionsPaquete";
import ContainerCursos from "./ContainerCursos";
import { useNavigate, useParams } from "react-router-dom";

export default function VistaPaquete(props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [paquete, setPaquete] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorQuery, setErrorQuery] = useState({ error: false, message: "" });
  const { url: slug } = useParams();
  const [snackbar, setSnackbar] = useState({
    open: false,
    mensaje: "",
    status: "",
  });

  const obtenerPaqueteBD = useCallback(async () => {
    setLoading(true);
    await clienteAxios
      .get(`/packages/paquete/${slug}`)
      .then((res) => {
        setLoading(false);
        setPaquete(res.data);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          setErrorQuery({ error: true, message: err.response.data.message });
        } else {
          setErrorQuery({
            error: true,
            message: "Al parecer no se a podido conectar al servidor.",
          });
        }
      });
  }, [slug]);

  useEffect(() => {
    obtenerPaqueteBD();
    window.scrollTo(0, 0);
  }, [obtenerPaqueteBD]);

  if (errorQuery.error) {
    return <Error500 error={errorQuery.message} />;
  }

  if (paquete.length === 0) {
    return <Spin loading={loading} />;
  }

  if (!paquete.active) navigate("/");

  return (
    <Box>
      <MetaTags>
        <title>UNILINE</title>
        <meta
          id="meta-description-pack"
          name="description"
          content={paquete.description}
        />
        <meta id="og-title-pack" property="og:title" content={paquete.title} />
        <meta id="og-image-pack" property="og:image" content={paquete.image} />
        <meta
          id="og-url-pack"
          property="og:url"
          content={"https://uniline.online/paquete/" + paquete.slug}
        />
      </MetaTags>
      <Container
        sx={{
          position: "relative",
          padding: theme.spacing(2),
          [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(2),
          },
        }}
        maxWidth="md"
      >
        <Box p={1} borderRadius={5} bgcolor={theme.palette.background.paper}>
          <Grid container spacing={2} style={{ minHeight: 200 }}>
            <Grid size={{ xs: 12, sm: 4 }}>
              {paquete.image ? (
                <CardMedia style={{ height: 200 }} image={paquete.image} />
              ) : (
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <AvatarGroup
                    max={4}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    {paquete.courses.map((res, index) => (
                      <Avatar
                        key={index}
                        sx={{ height: 120, width: 120 }}
                        alt={` curso ${index}`}
                        src={res.course?.urlPromotionalImage}
                      />
                    ))}
                  </AvatarGroup>
                </Box>
              )}
            </Grid>
            <Grid size={{ xs: 12, sm: 8 }}>
              <Box height="100%">
                <Typography variant="h5" component="h1">
                  <b>{paquete.title}</b>
                </Typography>
                <Typography variant="body2">
                  {`Creado el ${formatoFechaCurso(paquete.createdAt)} por ${
                    paquete.idProfessor.name
                  }`}
                </Typography>
                <Box
                  sx={{
                    minHeight: "40%",
                    [theme.breakpoints.down("sm")]: {
                      minHeight: "25%",
                    },
                  }}
                >
                  <Typography variant="body1" component="h2">
                    {paquete.description}
                  </Typography>
                </Box>
                <Box my={2}>
                  <Grid container justify="space-between">
                    <Grid>
                      <Typography variant="h5">
                        Por solo ${formatoMexico(paquete.pricePack)} MXN
                      </Typography>
                    </Grid>
                    <Grid>
                      <ActionsPaquete
                        props={props}
                        paquete={paquete}
                        setSnackbar={setSnackbar}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box mt={2}>
          <ContainerCursos paquete={paquete} />
        </Box>
      </Container>
      <MessageSnackbar
        open={snackbar.open}
        mensaje={snackbar.mensaje}
        status={snackbar.status}
        setSnackbar={setSnackbar}
      />
    </Box>
  );
}
