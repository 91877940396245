import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button } from "@mui/material";
import Preguntas from "./Preguntas/Preguntas";

const styles = {
  contenedor: {
    padding: { xs: 1, md: 5}
  },
};

export default function PreguntasUniline() {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(!open);
  };

  return (
    <Box>
      <Box sx={styles.contenedor}>
        <Box>
          <Button
            startIcon={<AddIcon style={{ fontSize: 30 }} />}
            variant="text"
            color="primary"
            className="addButton"
            onClick={handleClickOpen}
          >
            Nueva pregunta
          </Button>
        </Box>
        <Preguntas open={open} setOpen={setOpen} />
      </Box>
    </Box>
  );
}
