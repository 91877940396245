import React from "react";
import { Box, Divider } from "@mui/material";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
import { StrictModeDroppable } from "../../../../../../components/CustomDroppable";

const styles = {
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginBottom: 16,
  },
  input: {
    marginLeft: 1,
    flex: 1,
  },
  divider: {
    height: 28,
    margin: 1,
  },
};

const reorder = (list, startIndex, endIndex) => {
  const newList = Array.from(list);
  const [removed] = newList.splice(startIndex, 1);
  newList.splice(endIndex, 0, removed);
  return newList;
};

export default function Aprendizajes({
  datosAprendizajes,
  setDatosAprendizajes,
  eliminarRespuesta,
}) {

  const onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    const new_elements = reorder(
      datosAprendizajes,
      source.index,
      destination.index
    );
    setDatosAprendizajes(new_elements);
  };

  const respuestas_aprendizajes = datosAprendizajes.map((res, index) => {
    return (
      <Draggable draggableId={`aprendizaje-${index}`} index={index} key={index}>
        {(provided) => (
          <Box ref={provided.innerRef} {...provided.draggableProps}>
            <Paper sx={styles.root}>
              <InputBase
                sx={styles.input}
                value={res.apredizaje ? res.apredizaje : ""}
                inputProps={{ readOnly: true }}
              />
              <Divider sx={styles.divider} orientation="vertical" />
              <IconButton
                aria-label="delete"
                onClick={() => eliminarRespuesta(index)}
              >
                <DeleteOutlinedIcon />
              </IconButton>
              <IconButton
                {...provided.dragHandleProps}
              >
                <DragIndicatorOutlinedIcon />
              </IconButton>
            </Paper>
          </Box>
        )}
      </Draggable>
    );
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <StrictModeDroppable droppableId="droppable-learnings">
        {(provided) => (
          <Box my={2} ref={provided.innerRef}>
            {respuestas_aprendizajes}
            {provided.placeholder}
          </Box>
        )}
      </StrictModeDroppable>
    </DragDropContext>
  );
}
