import React, { useCallback, useContext, useEffect, useState } from "react";
import { Badge, BottomNavigationAction, Box } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { NavContext } from "../../context/context_nav";
import clienteAxios from "../../config/axios";
import { useNavigate } from "react-router-dom";

export default function CarritoNavbar({ responsive }) {
  const { setCarrito, update, setMisCursos } = useContext(NavContext);
  const navigate = useNavigate();

  const [count, setCount] = useState(0);
  const token = localStorage.getItem("token");
  const user = token
    ? JSON.parse(localStorage.getItem("student"))
    : { _id: "" };

  const obtenerCarritoBD = useCallback(async () => {
    if (!user._id) return;

    try {
      const { data } = await clienteAxios.get(`/cart/${user._id}`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      });
      setCarrito(data);

      const cant = data.courses.length + (data.packsCourses?.length || 0);
      setCount(cant);
    } catch (err) {
      console.error(err);
    }
  }, [token, user._id, setCarrito]);

  const obtenerMisCursos = useCallback(async () => {
    if (!user._id) return;

    try {
      const { data } = await clienteAxios.get(`/course/user/${user._id}`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      });
      setMisCursos(data);
    } catch (err) {
      console.error(err);
    }
  }, [token, user._id, setMisCursos]);

  useEffect(() => {
    obtenerCarritoBD();
    obtenerMisCursos();
  }, [obtenerCarritoBD, obtenerMisCursos, update]);

  return responsive ? (
    <BottomNavigationAction
      onClick={() => navigate("/carrito")}
      icon={
        <Badge badgeContent={count} color="secondary">
          <ShoppingCartIcon sx={{ color: "#fff", fontSize: 30 }} />
        </Badge>
      }
    />
  ) : (
    <Box onClick={() => navigate("/carrito")} sx={{ cursor: "pointer" }}>
      <Badge badgeContent={count} color="secondary">
        <ShoppingCartIcon fontSize="small" />
      </Badge>
    </Box>
  );
}
