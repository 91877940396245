import React, { useEffect, useState } from "react";
import { Fab } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

export default function Scroll({ showBelow, bottomMargin }) {
  const [show, setShow] = useState(showBelow ? false : true);

  const handleScrollToClick = () => {
    window["scrollTo"]({ top: 0, behavior: "smooth" });
  };
  /* const handleScrollToListener = () => {
    if (window.pageYOffset > showBelow) {
      if (!show) setShow(true);
    } else {
      if (show) setShow(false);
    }
  }; */

  const handleScrollToListener = () => {
    if (window.scrollY > showBelow) {
      // Replace pageYOffset with scrollY
      if (!show) setShow(true);
    } else {
      if (show) setShow(false);
    }
  };

  useEffect(() => {
    if (showBelow) {
      window.addEventListener("scroll", handleScrollToListener);
      return () => window.removeEventListener("scroll", handleScrollToListener);
    }
  });

  return (
    <div>
      {show && (
        <Fab
          color="primary"
          aria-label="up"
          sx={{
            zIndex: 10,
            position: "fixed",
            right: 20,
            bottom: { xs: 70, md: 20 },
            // bottom: bottomMargin ?? 2,
          }}
          onClick={handleScrollToClick}
        >
          <ArrowUpwardIcon />
        </Fab>
      )}
    </div>
  );
}
