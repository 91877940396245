import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import { School } from "@mui/icons-material";
import { formatoFechaCurso } from "../../../config/reuserFunction";

export default function TablaCertificados({ certificado, loaded }) {
  if (!loaded && !certificado) return null;

  if (loaded && !certificado) {
    return (
      <Box my={10} display="flex" justifyContent="center">
        <Box textAlign="center">
          <School color="disabled" sx={{ fontSize: 120 }} />
          <Box my={1} />
          <Typography color="textSecondary" align="center" variant="h5">
            No se encontró ningún certificado
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Paper variant="outlined" sx={{ overflow: "hidden" }}>
      <TableContainer>
        <Table aria-label="tabla-certificados">
          <TableHead>
            <TableRow>
              <TableCell>No. Certificado</TableCell>
              <TableCell>Estudiante</TableCell>
              <TableCell>Curso</TableCell>
              <TableCell>Fecha de emisión</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{certificado.certificate_number}</TableCell>
              <TableCell>{certificado.user}</TableCell>
              <TableCell>{certificado.course}</TableCell>
              <TableCell>
                {certificado.finish_date
                  ? formatoFechaCurso(certificado.finish_date)
                  : ""}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
