import React from "react";
import clienteAxios from "../../../../config/axios";
import {
  Box,
  LinearProgress,
  Rating,
  Typography,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

function Calificaciones({ curso }) {
  const theme = useTheme();
  const [calificacion, setCalificacion] = React.useState(null);

  React.useLayoutEffect(() => {
    const getQualify = async () => {
      await clienteAxios
        .get(`/comment/qualification/get/${curso._id}`)
        .then((res) => {
          setCalificacion(res.data.commentCourse[0]);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getQualify();
  }, [curso._id]);

  if (!calificacion) {
    return null;
  }

  const { total, five, four, three, two, one } = calificacion;

  return (
    <Box
      mt={3}
      sx={{
        position: "sticky",
        top: theme.spacing(22),
        [theme.breakpoints.down("xs")]: {
          position: "inherit",
        },
      }}
    >
      <Box display="flex" alignItems="center">
        <Typography variant="h3">
          {parseFloat(curso.qualification).toFixed(1)}
        </Typography>
        <Box ml={1}>
          <Rating
            name="read-only"
            value={curso.qualification}
            precision={0.5}
            readOnly
          />
          <Typography variant="body1">{`${total} Reseñas`}</Typography>
        </Box>
      </Box>

      <Grid container spacing={1} alignItems="center">
        <Grid size={{ xs: 1 }}>
          <Typography variant="body2" color="textSecondary">
            5
          </Typography>
        </Grid>
        <Grid size={{ xs: 9 }}>
          <LinearProgress
            variant="determinate"
            color="secondary"
            value={Math.round((five / total) * 100)}
          />
        </Grid>
        <Grid size={{ xs: 2 }}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            (five / total) * 100
          )}%`}</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1} alignItems="center">
        <Grid size={{ xs: 1 }}>
          <Typography variant="body2" color="textSecondary">
            4
          </Typography>
        </Grid>
        <Grid size={{ xs: 9 }}>
          <LinearProgress
            variant="determinate"
            color="secondary"
            value={Math.round((four / total) * 100)}
          />
        </Grid>
        <Grid size={{ xs: 2 }}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            (four / total) * 100
          )}%`}</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1} alignItems="center">
        <Grid size={{ xs: 1 }}>
          <Typography variant="body2" color="textSecondary">
            3
          </Typography>
        </Grid>
        <Grid size={{ xs: 9 }}>
          <LinearProgress
            variant="determinate"
            color="secondary"
            value={Math.round((three / total) * 100)}
          />
        </Grid>
        <Grid size={{ xs: 2 }}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            (three / total) * 100
          )}%`}</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1} alignItems="center">
        <Grid size={{ xs: 1 }}>
          <Typography variant="body2" color="textSecondary">
            2
          </Typography>
        </Grid>
        <Grid size={{ xs: 9 }}>
          <LinearProgress
            variant="determinate"
            color="secondary"
            value={Math.round((two / total) * 100)}
          />
        </Grid>
        <Grid size={{ xs: 2 }}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            (two / total) * 100
          )}%`}</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1} alignItems="center">
        <Grid size={{ xs: 1 }}>
          <Typography variant="body2" color="textSecondary">
            1
          </Typography>
        </Grid>
        <Grid size={{ xs: 9 }}>
          <LinearProgress
            variant="determinate"
            color="secondary"
            value={Math.round((one / total) * 100)}
          />
        </Grid>
        <Grid size={{ xs: 2 }}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            (one / total) * 100
          )}%`}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Calificaciones;
