import React from "react";
import { Box, Divider } from "@mui/material";
import UnilineDark from "../../../images/unilineDark.png";

export default function NavQuestion({ actiallyQuestion, dataQuestions }) {
  const classes = {
    contentNav: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      // padding: "5px 5px",
      backgroundColor: "rgb(60,0,143)",
    },
    imgLogo: {
      width: "150px",
      margin: "5px",
    },
    contentCount: {
      marginLeft: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
  return (
    <Box sx={classes.contentNav}>
      <img style={classes.imgLogo} alt={"logo uniline"} src={UnilineDark} />
      <Divider
        style={{ backgroundColor: "white", margin: "5px 0px" }}
        orientation="vertical"
        flexItem
      />
      <div style={classes.contentCount}>
        <p
          style={{
            margin: "0px",
            fontSize: "17px",
            color: "white",
            fontWeight: "bold",
          }}
        >
          Paso {actiallyQuestion.index + 1} de {dataQuestions.length + 1}
        </p>
      </div>
    </Box>
  );
}
