import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Grid2 as Grid,
  Box,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CursosProfesor from "./cursos";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Scroll from "../../../components/ScrolltoTop/scroll";
import clienteAxios from "../../../config/axios";
import MessageSnackbar from "../../../components/Snackbar/snackbar";
import Spin from "../../../components/Spin/spin";
import useSWR, { mutate } from "swr";

const styles = {
  flex: {
    width: "100%",
    height: "50vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconflex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    fontSize: 60,
    marginRight: 10,
    color: "#6666",
  },
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    marginBottom: { xs: 10, md: 0 },
  },
  input: {
    marginLeft: 1,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
};

const fetcher = (url, token) =>
  clienteAxios
    .get(url, {
      headers: { Authorization: `bearer ${token}` },
    })
    .then((res) => res.data);

export default function DashboardMaestro(props) {
  const token = localStorage.getItem("token");
  const [datos, setDatos] = useState([]);
  const [loading, setLoading] = useState(false);
  //const [update, setUpdate] = useState(false);
  const [value, setValue] = useState("");
  const [snackbar, setSnackbar] = useState({
    open: false,
    mensaje: "",
    status: "",
  });
  let user = { _id: "" };

  if (token !== null) user = JSON.parse(localStorage.getItem("student"));

  const { data, error, isLoading } = useSWR(
    [`/course/teacher/${user._id}`, token],
    ([url, token]) => fetcher(url, token),
    { revalidateOnFocus: false, revalidateIfStale: false }
  );

  const update = () => {
    mutate([`/course/teacher/${user._id}`, token]);
  };

  /* useEffect(() => {
    mutate([`/course/teacher/${user._id}`, token]);
  }, [update, token, user._id]); */

  useEffect(() => {
    if (error) {
      if (error.response) {
        setSnackbar({
          open: true,
          mensaje: error.response.data.message,
          status: "error",
        });
      } else {
        setSnackbar({
          open: true,
          mensaje: "Al parecer no se a podido conectar al servidor.",
          status: "error",
        });
      }
    }
  }, [error]);

  useEffect(() => {
    if (data) setDatos(data);
  }, [data]);

  /* const obtenerCursosBD = useCallback(async () => {
    if (!user._id) return;
    setLoading(true);
    await clienteAxios
      .get(`/course/teacher/${user._id}`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        setLoading(false);
        setDatos(res.data);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          setSnackbar({
            open: true,
            mensaje: err.response.data.message,
            status: "error",
          });
        } else {
          setSnackbar({
            open: true,
            mensaje: "Al parecer no se a podido conectar al servidor.",
            status: "error",
          });
        }
      });
  }, [token, user._id]); */

  const capturarBusqueda = (valor) => setValue(valor);

  const obtenerBusquedas = async (e) => {
    e.preventDefault();
    if (value === "") {
      mutate([`/course/teacher/${user._id}`, token]);
      return;
    }
    setLoading(true);
    await clienteAxios
      .get(`/course/teacher/${user._id}/filter/${value}`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        setLoading(false);
        setDatos(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        if (err.response) {
          setSnackbar({
            open: true,
            mensaje: err.response.data.message,
            status: "error",
          });
        } else {
          setSnackbar({
            open: true,
            mensaje: "Al parecer no se a podido conectar al servidor.",
            status: "error",
          });
        }
      });
  };

  /* useEffect(() => {
    obtenerCursosBD();
  }, [obtenerCursosBD, update]); */

  const render_cursos = datos.map((curso, index) => (
    <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
      <CursosProfesor
        key={index}
        datos={curso}
        update={update}
        setLoading={setLoading}
        setSnackbar={setSnackbar}
      />
    </Grid>
  ));

  if (!datos) {
    return (
      <Box sx={styles.flex}>
        <Box>
          <Box sx={styles.iconflex}>
            <CloudUploadIcon sx={styles.icon} />
          </Box>
          <Typography style={{ color: "#666666" }} variant="h5">
            Aun no tienes cursos registrados, ¡Comienza ahora!
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Spin loading={isLoading || loading} />
      <MessageSnackbar
        open={snackbar.open}
        mensaje={snackbar.mensaje}
        status={snackbar.status}
        setSnackbar={setSnackbar}
      />
      <Scroll showBelow={250} />
      <Box
        sx={{
          display: "flex",
          position: { sm: "inherit", md: "absolute" },
          top: 80,
          right: 20,
          gap: 1,
        }}
      >
        <form onSubmit={obtenerBusquedas}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            placeholder="Buscar tus cursos"
            onChange={(e) => capturarBusqueda(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton type="submit">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </form>
        <Button
          size="medium"
          variant="contained"
          color="primary"
          disableElevation
          style={{ marginLeft: 8 }}
          startIcon={<AddIcon />}
          component={Link}
          to="/instructor/nuevo_curso"
        >
          Nuevo
        </Button>
      </Box>
      <Grid container spacing={3}>
        {render_cursos}
      </Grid>
    </Box>
  );
}
