import { jwtDecode } from "jwt-decode";

function Jwt(token) {
  try {
    return jwtDecode(token);
  } catch (e) {
    return null;
  }
}

function Sesion(navigate) {
  const token = localStorage.getItem("token");
  var decoded = Jwt(token);

  if (navigate) {
    if (!decoded || decoded.rol !== "Maestro") {
      navigate("/")
    } else {
      return decoded;
    }
  }
  return decoded;
}

export default Sesion;
