import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Typography from "@mui/material/Typography";
import CloudDone from "@mui/icons-material/CloudDone";
import CloudOff from "@mui/icons-material/CloudOff";
import ToggleActive from "./ToggleActive";
import CrearPackage from "../../CrearPaquetes/CrearPackage";
import ArchivePackage from "./ArchivePackage";
import { CardMedia } from "@mui/material";

export default function ItemPackage({ data, archived }) {
  return (
    <Card variant="outlined">
      {data.archived ? null : (
        <CardHeader
          style={{
            padding: 0,
            textAlign: "center",
            backgroundColor: data.active ? "#dcedc8" : "#eeeeee",
          }}
          subheader={
            data.active ? (
              <Box
                justifyContent="center"
                display="flex"
                alignItems="center"
                style={{ color: "#0000008A" }}
              >
                <CloudDone />
                <Box mx={1} />
                {` Publicado`}
              </Box>
            ) : (
              <Box
                justifyContent="center"
                display="flex"
                alignItems="center"
                style={{ color: "#0000008A" }}
              >
                <CloudOff />
                <Box mx={1} />
                {` No publicado`}
              </Box>
            )
          }
        />
      )}
      <Box sx={{ height: 170 }}>
        {data.image ? (
          <CardMedia style={{ height: "100%" }} image={data.image} />
        ) : (
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AvatarGroup
              max={4}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {data.courses.map((res, index) => (
                <Avatar
                  key={index}
                  sx={{ height: 130, width: 130 }}
                  alt={`curso ${index}`}
                  src={res.course?.urlPromotionalImage}
                />
              ))}
            </AvatarGroup>
          </Box>
        )}
      </Box>
      <CardContent>
        <Typography gutterBottom variant="h6" component="h2" noWrap>
          {data.title}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p" noWrap>
          {data.description}
        </Typography>
      </CardContent>
      <CardActions style={{ justifyContent: "space-around" }}>
        {archived ? null : (
          <React.Fragment>
            <ToggleActive data={data} />
            <CrearPackage updating={true} data={data} />
          </React.Fragment>
        )}
        <ArchivePackage data={data} />
      </CardActions>
    </Card>
  );
}
