import React from "react";
import { Editor } from "@tinymce/tinymce-react";

export default function TinyEditorComponent({
  value = null,
  name = "",
  height = 300,
  menubar = true,
  onEditorChange = null,
}) {
    
  return (
    <Editor
      name={name}
      init={{
        height,
        menubar,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
        ],
        toolbar:
          "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
      }}
      apiKey={process.env.REACT_APP_TINY_KEY}
      onEditorChange={onEditorChange}
      value={value ?? ""}
    />
  );
}
