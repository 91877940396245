import React, { Fragment } from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import "./banner.css";

export default function BannerApps() {
  const theme = useTheme();
  const url =
    "https://cursos-uniline.s3.us-west-1.amazonaws.com/apps/AppsBanner.png";

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          maxHeight: "50vh",
          width: "100%",
          [theme.breakpoints.down("xs")]: {
            maxHeight: "40vh",
          },
        }}
      >
        <Box
          component="img"
          alt={`imagen-${url}`}
          src={url}
          sx={{
            maxHeight: "100%",
            maxWidth: "100%",
          }}
        />
      </Box>
    </Fragment>
  );
}
