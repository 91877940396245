import React, { useCallback } from "react";
import Spin from "../../../../../components/Spin/spin";
import { CursoContext } from "../../../../../context/curso_context";
import clienteAxios from "../../../../../config/axios";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import MessageSnackbar from "../../../../../components/Snackbar/snackbar";
import { Link, useParams } from "react-router-dom";
import CompartirTaller from "./compartirTaller";
import { ViewArray } from "@mui/icons-material";
import InformaciónTaller from "./infoTaller";
import VistaAprendizajes from "./Aprendizajes/vista_aprendizajes";
import AlumnosRegistrados from "./Usuarios/alumnos_registrados";

const styles = {
  color: {
    backgroundColor: theme => theme.palette.background.paper,
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme => theme.palette.background.paper,
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function RegistroTaller() {
  const params = useParams();
  const idcurso = params?.curso;
  const { datos, update, setUpdate } = React.useContext(CursoContext);
  const token = localStorage.getItem("token");
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [datosTaller, setDatosTaller] = React.useState({});

  const [snackbar, setSnackbar] = React.useState({
    open: false,
    mensaje: "",
    status: "",
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const obtenerCursoBD = useCallback(async () => {
    setLoading(true);
    await clienteAxios
      .get(`/course/${idcurso}`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        setLoading(false);
        if (!res.data.taller) {
          return;
        } else {
          setDatosTaller(res.data.taller);
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          setSnackbar({
            open: true,
            mensaje: err.response.data.message,
            status: "error",
          });
        } else {
          setSnackbar({
            open: true,
            mensaje: "Al parecer no se a podido conectar al servidor.",
            status: "error",
          });
        }
      });
  }, [idcurso, token]);

  const publicarCurso = async () => {
    await clienteAxios
      .put(
        `/taller/publicTaller/${idcurso}`,
        {
          publicTaller: !datos?.taller.publicTaller,
        },
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setSnackbar({
          open: true,
          mensaje: res.data.message,
          status: "success",
        });
        setLoading(false);
        setUpdate(!update);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          setSnackbar({
            open: true,
            mensaje: err.response.data.message,
            status: "error",
          });
        } else {
          setSnackbar({
            open: true,
            mensaje: "Al parecer no se a podido conectar al servidor.",
            status: "error",
          });
        }
      });
  };

  React.useEffect(() => {
    obtenerCursoBD();
  }, [obtenerCursoBD]);

  return (
    <Box p={3} mb={6} boxShadow={3} sx={styles.color}>
      <MessageSnackbar
        open={snackbar.open}
        mensaje={snackbar.mensaje}
        status={snackbar.status}
        setSnackbar={setSnackbar}
      />
      <Spin loading={loading} />
      <Box mb={2}>
        <Grid container justify="flex-end" spacing={2}>
          <Grid>
            <Button
              target="_blank"
              fullWidth
              color="primary"
              variant="text"
              size="large"
              style={{ fontSize: 16 }}
              component={Link}
              to={`/curso_taller/${datos?.slug}`}
              onClick={() => {
                localStorage.setItem("vistaPrevia", true);
              }}
              startIcon={<ViewArray />}
            >
              Vista previa
            </Button>
          </Grid>
          <Grid>
            <CompartirTaller datosTaller={datosTaller} />
          </Grid>
          <Grid>
            <Button
              fullWidth
              color={
                datos?.taller?.publicTaller === true ? "primary" : "default"
              }
              variant="text"
              size="small"
              style={{ fontSize: 16 }}
              onClick={() => publicarCurso()}
              startIcon={
                <FormControlLabel
                  style={{ width: 40 }}
                  control={
                    <Switch
                      color="primary"
                      checked={datos?.taller?.publicTaller}
                      name="checkedA"
                    />
                  }
                />
              }
            >
              {datos?.taller?.publicTaller === true ? "Publicado" : "Publicar"}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box sx={styles.root}>
        <AppBar position="static" color="inherit" elevation={0}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="Información del taller" {...a11yProps(0)} />
            <Tab label="Aprendizajes" {...a11yProps(1)} />
            <Tab label="Usuarios registrados" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <InformaciónTaller idcurso={idcurso} datosTaller={datosTaller} setDatosTaller={setDatosTaller} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <VistaAprendizajes />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <AlumnosRegistrados idcurso={idcurso} />
        </TabPanel>
      </Box>
    </Box>
  );
}
