import React, { useContext } from "react";
import Box from "@mui/material/Box";
import CrearPackage from "./CrearPaquetes/CrearPackage";
import PackagesList from "./ListPackages/PackagesList";
import MessageSnackbar from "../../../components/Snackbar/snackbar";
import { PackageCtx, PackagesProvider } from "./PackagesCtx";
import PackagesArchivedIndex from "./PackagesArchived/PackagesArchivedIndex";

export default function PackagesIndex() {
  return (
    <PackagesProvider>
      <ComponentToRender />
    </PackagesProvider>
  );
}

const ComponentToRender = () => {
  const { snackbar, setSnackbar } = useContext(PackageCtx);
  return (
    <div>
      <MessageSnackbar
        open={snackbar.open}
        mensaje={snackbar.mensaje}
        status={snackbar.status}
        setSnackbar={setSnackbar}
      />
      <Box
        sx={{
          display: "flex",
          position: { sm: "inherit", md: "absolute" },
          top: 80,
          right: 20,
          gap: 1,
        }}
      >
        <PackagesArchivedIndex />
        <CrearPackage />
      </Box>
      <Box>
        <PackagesList />
      </Box>
    </div>
  );
};
