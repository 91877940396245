import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import ExportarPagos from "./ExportarExcel";

export default function FiltrosVentasMaestro({ filtro, setFiltro }) {
  const [busqueda, setBusqueda] = React.useState("");

  const handleChangeFiltro = (e) => {
    const { value, name } = e.target;
    switch (name) {
      case "all":
        setFiltro({ field: "", search: "" });
        break;
      case "paypal":
        setFiltro({ field: "typePay", search: value });
        break;
      case "stripe":
        setFiltro({ field: "typePay", search: value });
        break;
      case "failed":
        setFiltro({ field: "statusPay", search: value === "true" });
        break;

      default:
        setFiltro({ field: "", search: "" });
        break;
    }
  };

  const hanldeChangeInput = (value) => {
    setBusqueda(value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (!busqueda) {
      setFiltro({ field: "", search: "" });
    }
    setFiltro({ field: "nameUser", search: busqueda });
  };

  return (
    <Grid container spacing={2} alignItems="center" style={{ width: "100%" }}>
      <Grid size={{ xs: 12, sm: 12, md: 4, lg: 5 }}>
        <form onSubmit={handleSearch}>
          <TextField
            fullWidth
            size="small"
            placeholder="Buscar por estudiante"
            value={busqueda}
            onChange={(e) => hanldeChangeInput(e.target.value)}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="center">
                  <IconButton type="submit">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </form>
      </Grid>
      <Grid size={{ xs: 12, sm: 9, md: 6, lg: 5 }}>
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="position"
            name="position"
            value={filtro.search}
            onChange={handleChangeFiltro}
          >
            <FormControlLabel
              value=""
              name="all"
              control={<Radio color="primary" />}
              label="Todas"
            />
            <FormControlLabel
              value="paypal"
              name="paypal"
              control={<Radio color="primary" />}
              label="Paypal"
            />
            <FormControlLabel
              value="stripe"
              name="stripe"
              control={<Radio color="primary" />}
              label="Stripe"
            />
            <FormControlLabel
              value={false}
              name="failed"
              control={<Radio color="primary" />}
              label="Fallidos"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid
        size={{ xs: 12, sm: 3, md: 2, lg: 2 }}
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <ExportarPagos />
      </Grid>
    </Grid>
  );
}
