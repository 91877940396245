import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  CardActionArea,
  LinearProgress,
  Typography,
  Box,
  Button,
} from "@mui/material";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import { Link, useNavigate } from "react-router-dom";

export default function CardsCursosEstudiantes({ curso }) {
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <CardActionArea
        sx={{
          "&:hover .content-play": {
            display: "flex",
            opacity: 0.6,
            "& .button-play": {
              display: "block",
            },
          },
        }}
        onClick={() => navigate(`/dashboard/${curso.idCourse.slug}`)}
      >
        <Box
          className="content-play"
          sx={{
            position: "absolute",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            backgroundColor: "#000",
            opacity: 0,
            transition: "opacity 0.15s linear",
            display: "none",
            "& .button-play": {
              display: "none",
            },
          }}
        >
          <PlayCircleFilledIcon
            className="button-play"
            sx={{ fontSize: 72, color: "white" }}
          />
        </Box>
        <CardMedia
          className="content-media"
          sx={{
            height: 170,
          }}
          image={curso.idCourse.urlPromotionalImage}
        />
      </CardActionArea>

      <CardContent
        sx={{
          padding: 1,
        }}
      >
        <Typography
          color="textPrimary"
          sx={{
            display: "-webkit-box",
            overflow: "hidden",
            textOverflow: "ellipsis",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
            fontSize: 18,
            fontWeight: 400,
            "@media (maxWidth:600px)": {
              fontSize: 16,
            },
          }}
        >
          {curso.idCourse.title}
        </Typography>
        <Typography
          variant="subtitle2"
          color="textSecondary"
          noWrap
          sx={{ width: "100%" }}
        >
          {curso.idCourse.category}
        </Typography>
      </CardContent>

      <CardActions>
        <Box width="100%">
          <LinearProgress
            variant="determinate"
            value={parseInt(curso.studentAdvance)}
            sx={{
              height: 10,
              borderRadius: 5,
              backgroundColor: (theme) =>
                theme.palette.grey[theme.palette.mode === "light" ? 200 : 700],
              "& .MuiLinearProgress-bar": {
                borderRadius: 2,
                backgroundColor: (theme) => theme.palette.primary.main,
              },
            }}
          />
          <Typography>Avance del curso: {curso.studentAdvance}%</Typography>
        </Box>
      </CardActions>

      <CardActions
        sx={{
          padding: 1,
        }}
      >
        <Button
          variant="text"
          color="primary"
          fullWidth
          component={Link}
          to={`/dashboard/${curso.idCourse.slug}`}
        >
          ¡Continuar con tus clases!
        </Button>
      </CardActions>
    </Card>
  );
}
