import {
  ArrowBack,
  AssignmentInd,
  AssignmentOutlined,
  CheckCircle,
  Error,
  Notifications,
  QueuePlayNext,
} from "@mui/icons-material";

import {
  verificarInformacionCurso,
  verificarLearningsCurso,
  verificarBloquesCurso,
  verificarPrecioCurso,
} from "../../utils/verificar_contenido";

export const NavItems = ({ idcurso, curso, block }) => [
  {
    segment: "instructor/cursos",
    title: "Dashboard",
    icon: (
      <ArrowBack
        sx={{ color: (theme) => `${theme.palette.primary.main}!important` }}
      />
    ),
  },
  {
    kind: "header",
    title: "Información general",
  },
  {
    segment: `instructor/contenido_curso/${idcurso}/general`,
    title: "Información del curso",
    icon: verificarInformacionCurso(curso) ? (
      <CheckCircle
        sx={{ color: (theme) => `${theme.palette.primary.main}!important` }}
      />
    ) : (
      <Error sx={{color: theme => `${theme.palette.error.main}!important`}}/>
    ),
  },
  {
    segment: `instructor/contenido_curso/${idcurso}/learn`,
    title: "Que enseñarás",
    icon: verificarLearningsCurso(curso) ? (
      <CheckCircle
        sx={{ color: (theme) => `${theme.palette.primary.main}!important` }}
      />
    ) : (
      <Error sx={{color: theme => `${theme.palette.error.main}!important`}}/>
    ),
  },
  {
    kind: "header",
    title: "Contenido del curso",
  },
  {
    segment: `instructor/contenido_curso/${idcurso}/contenido`,
    title: "Bloques y temas",
    icon: verificarBloquesCurso(block) ? (
      <CheckCircle
        sx={{ color: (theme) => `${theme.palette.primary.main}!important` }}
      />
    ) : (
      <Error sx={{color: theme => `${theme.palette.error.main}!important`}}/>
    ),
  },
  {
    kind: "header",
    title: "Publicación del curso",
  },
  {
    segment: `instructor/contenido_curso/${idcurso}/precio`,
    title: "Precio y promociones",
    icon: verificarPrecioCurso(curso) ? (
      <CheckCircle
        sx={{ color: (theme) => `${theme.palette.primary.main}!important` }}
      />
    ) : (
      <Error sx={{color: theme => `${theme.palette.error.main}!important`}}/>
    ),
  },
  {
    kind: "header",
    title: "Otros",
  },
  {
    segment: `instructor/contenido_curso/${idcurso}/taller`,
    title: "Información taller",
    icon: <QueuePlayNext />,
  },
  {
    segment: `instructor/contenido_curso/${idcurso}/tareas`,
    title: "Tareas",
    icon: <AssignmentOutlined />,
  },
  {
    segment: `instructor/contenido_curso/${idcurso}/estudiantes`,
    title: "Alumnos inscritos",
    icon: <AssignmentInd />,
  },
  {
    segment: `instructor/contenido_curso/${idcurso}/notificaciones`,
    title: "Notificaciones",
    icon: <Notifications />,
  },
];

export const HeaderTitle = {
  general: "Información del curso",
  learn: "Que enseñarás",
  contenido: "Bloques y temas del curso",
  precio: "Precio del curso",
  tareas: "Tareas de tus estuidantes",
  estudiantes: "Tus alumnos",
};
