import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  AccessTime,
  AllInclusive,
  AssessmentOutlined,
  FolderOpenOutlined,
  Language,
  MobileFriendly,
  School,
  Subscriptions,
} from "@mui/icons-material";

export default function Beneficios({ curso }) {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          [theme.breakpoints.down("xs")]: {
            position: "inherit",
          },
          position: "inherit", // Estilo predeterminado
          [theme.breakpoints.up("sm")]: {
            position: "sticky",
            top: theme.spacing(20), // Espaciado para sticky
          },
        }}
      >
        <Grid container>
          <Grid item>
            <List>
              <ListItem>
                <ListItemIcon>{<AccessTime />}</ListItemIcon>
                <ListItemText
                  primary={`${curso.course.hours} horas de cursos`}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>{<Subscriptions />}</ListItemIcon>
                <ListItemText
                  primary={`${curso.totalTopics} temas disponibles`}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>{<AllInclusive />}</ListItemIcon>
                <ListItemText primary="Acceso de por vida" />
              </ListItem>
              <ListItem>
                <ListItemIcon>{<MobileFriendly />}</ListItemIcon>
                <ListItemText primary="Acceso desde dispositivos móviles" />
              </ListItem>
            </List>
          </Grid>
          <Grid item>
            <List>
              <ListItem>
                <ListItemIcon>{<School />}</ListItemIcon>
                <ListItemText primary="Certificado al finalizar" />
              </ListItem>
              <ListItem>
                <ListItemIcon>{<Language />}</ListItemIcon>
                <ListItemText
                  primary={`Lenguaje en ${curso.course.language}`}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>{<AssessmentOutlined />}</ListItemIcon>
                <ListItemText primary={`Nivel ${curso.course.level}`} />
              </ListItem>
              <ListItem>
                <ListItemIcon>{<FolderOpenOutlined />}</ListItemIcon>
                <ListItemText primary="Trabajo final" />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
