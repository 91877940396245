import React from "react";
import { Avatar, useTheme } from "@mui/material";

function stringToColor(string) {
  let hash = 0;

  /* eslint-disable no-bitwise */
  for (let i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (let i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 14)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */
  return color;
}

export default function CustomAvatar({ size = 40, name = "", fontSize = 20 }) {
  const theme = useTheme();

  if (!name) {
    return <Avatar alt="sin foto de perfil" />;
  }

  const initials = name
    .split(" ")
    .map((word) => word[0])
    .slice(0, 2)
    .join("");

  return (
    <Avatar
      style={{
        height: size,
        width: size,
        fontSize,
        backgroundColor: stringToColor(name),
        color: theme.palette.getContrastText(stringToColor(name)),
      }}
    >
      {initials}
    </Avatar>
  );
}
