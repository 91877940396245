import { Adjust, CheckCircleOutline } from "@mui/icons-material";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  useTheme,
} from "@mui/material";
import React from "react";

export default function Answers({
  answers,
  answersSelected,
  setAnswersSelected,
  textQuestion,
  idCurso,
}) {
  const theme = useTheme();

  return (
    <div>
      <FormControl component="fieldset" sx={{ margin: theme.spacing(3) }}>
        <FormGroup>
          {answers?.map((answer, i) => {
            const check = answersSelected.filter((a) => a._id === answer._id);
            return (
              <CheckAnswer
                key={i}
                check={check}
                answersSelected={answersSelected}
                setAnswersSelected={setAnswersSelected}
                answer={answer}
                textQuestion={textQuestion}
                idCurso={idCurso}
              />
            );
          })}
        </FormGroup>
      </FormControl>
    </div>
  );
}

function CheckAnswer({
  answer,
  answersSelected,
  setAnswersSelected,
  check,
  textQuestion,
  idCurso,
  classes,
}) {
  const theme = useTheme();
  const handleChangeCheck = (ans) => {
    if (check.length > 0) {
      const checkedSelected = answersSelected.filter(
        (asnwerFilter) => asnwerFilter._id !== ans._id
      );
      setAnswersSelected(checkedSelected);
    } else {
      setAnswersSelected([
        ...answersSelected,
        { ...ans, textQuestion, idCurso },
      ]);
    }
  };

  return (
    <FormControlLabel
      style={{
        border: `1px solid rgb(${check.length > 0 ? "60,0,142" : "217,217,217"})`,
      }}
      sx={{
        width: "500px",
        [theme.breakpoints.down("md")]: {
          width: "100%",
        },
        margin: "5px 0px",
        borderRadius: "5px",
        padding: "7px",
      }}
      control={
        <Checkbox
          checked={check.length > 0 ? true : false}
          onChange={() => handleChangeCheck(answer)}
          name="sss"
          icon={
            <Adjust style={{ color: "rgb(167,166,170)", fontSize: "25px" }} />
          }
          checkedIcon={
            <CheckCircleOutline
              style={{ color: "rgb(60,0,142)", fontSize: "25px" }}
            />
          }
        />
      }
      label={answer?.answer}
    />
  );
}
