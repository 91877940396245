import React, { useEffect } from "react";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router-dom";
import Imagen from "../../../images/register.jpg";
import FormRegistroUsuario from "./form_registro";

export default function RegistroUsuario() {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token, navigate]);

  return (
    <Grid
      container
      sx={{
        backgroundColor: "background.paper",
        minHeight: "100vh", // Asegura altura completa de la pantalla
      }}
    >
      <Grid
        size={{ sm: 6, md: 6, lg: 8 }}
        sx={{
          display: { xs: "none", sm: "block" }, // Oculta la imagen en xs
        }}
      >
        <img
          alt="registrate"
          src={Imagen}
          style={{
            height: "91vh",
            width: "100%",
            objectFit: "cover",
          }}
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
        <FormRegistroUsuario />
      </Grid>
    </Grid>
  );
}
