import React, { useCallback, useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";
import clienteAxios from "../../../../config/axios";
import BannerDefault from "./BannerDefault";
import "./banner.css";
import { Box, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Banner() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [carouselHeight, setCarouselHeight] = useState("auto");
  const navigate = useNavigate();

  const isXs = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const obtenerBanners = useCallback(async () => {
    setLoading(true);
    try {
      const res = await clienteAxios.get("/banner/");
      const { banners } = res.data;
      setBanners(banners);
    } catch (err) {
      console.error(err.response || err);
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  }, []);

  useEffect(() => {
    obtenerBanners();
  }, [obtenerBanners]);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (banners.length > 0) {
      if (isXs) {
        setCarouselHeight("50vh");
      } else {
        const aspectRatio = 16 / 9;
        const newHeight = windowSize.width / aspectRatio;
        setCarouselHeight(`${newHeight}px`);
      }
    }
  }, [windowSize, banners, isXs]);

  if (loading) {
    return (
      <Box height="65vh" className="container-animation-loading">
        <div className="loading loading03">
          <span>U</span>
          <span>N</span>
          <span>I</span>
          <span>L</span>
          <span>I</span>
          <span>N</span>
          <span>E</span>
        </div>
      </Box>
    );
  } else if (loaded && banners.length === 0) {
    return <BannerDefault />;
  }

  const redireccion = (banner) => {
    if (!banner.course_ref) return;
    navigate(`/curso/${banner.course_ref}`);
  };

  const bannerContainerStyles = {
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: isXs ? "85.5vh" : "auto",
  };

  return (
    <Carousel
      key={windowSize.width}
      interval={5000}
      indicators={false}
      style={{
        width: "100%",
        height: carouselHeight,
      }}
    >
      {banners.map((banner) => {
        if (!isXs) {
          return banner.image_desktop ? (
            <Box
              key={`${banner.id}-${windowSize.width}`}
              sx={{
                ...bannerContainerStyles,
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                width: "100%",
                overflow: "hidden",
                position: "relative",
                margin: 0,
                padding: 0,
              }}
              onClick={() => redireccion(banner)}
            >
              <img
                alt={`imagen-${banner.order_number}`}
                src={banner.image_desktop}
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "contain",
                  display: "block",
                }}
              />
            </Box>
          ) : (
            <BannerDefault key={`default-${banner.id}`} />
          );
        }

        return banner.key_devices ? (
          <Box
            key={`${banner.id}-${windowSize.width}`}
            sx={{
              ...bannerContainerStyles,
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              width: "100%",
              overflow: "hidden",
              position: "relative",
              margin: 0,
              padding: 0,
            }}
            onClick={() => redireccion(banner)}
          >
            <img
              alt={`imagen-${banner.order_number}`}
              src={banner.image_devices}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                display: "block",
              }}
            />
          </Box>
        ) : (
          <BannerDefault key={`default-${banner.id}`} />
        );
      })}
    </Carousel>
  );
}
