import React, { useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { Box, CssBaseline, Typography } from "@mui/material";
import NavegacionUsuario from "../Navegacion_User/navegacion";
import Footer from "../Footer/footer";
import { NavProvider } from "../../context/context_nav";
import MantenimientoImg from "./Mantenimiento_img.png";
import mantenimiento from "../../context/context_mantenimiento";
import theme from "../../config/themeConfig";
import darkMode from "../../config/darkMode";

export default function LayoutUsers() {
  const storedTheme = JSON.parse(localStorage.getItem("tema"));
  const [darkTheme, setDarkTheme] = useState(storedTheme ?? false);

  const appliedTheme = darkTheme ? darkMode : theme;
  const navRef = useRef(null); // Ref para la barra de navegación

  useEffect(() => {
    localStorage.setItem("tema", darkTheme);
  }, [darkTheme]);

  if (mantenimiento) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          textAlign: "center",
        }}
      >
        <Box>
          <img
            alt="Imagen no disponible"
            src={MantenimientoImg}
            style={{ width: "500px", margin: "0 auto" }}
          />
          <Typography variant="h4" sx={{ mt: 2, fontWeight: "bold" }}>
            MANTENIMIENTO!!
          </Typography>
          <Typography variant="h5" sx={{ mt: 1 }}>
            Estamos haciendo cambios para brindarte una mejor experiencia.
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <ThemeProvider theme={appliedTheme}>
      <CssBaseline />
      <NavProvider>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            backgroundColor: appliedTheme.palette.background.default,
          }}
        >
          <Box ref={navRef}>
            <NavegacionUsuario tema={[darkTheme, setDarkTheme]} />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              marginTop: {
                xs: "0px",
                sm: "32.5px",
              },
            }}
          >
            <Outlet />
          </Box>
          <Footer />
        </Box>
      </NavProvider>
    </ThemeProvider>
  );
}
