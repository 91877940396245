import React from "react";
import Appoinments from "./Appointments";
import Payment from "./Payment";
import { ConsultaContext, ConsultaProvider } from "./Context";
import PaymentSuccess from "./Payment/PaymentSuccess";
import PaymentError from "./Payment/PaymentError";
import {
  Box,
  Button,
  Container,
  Paper,
  Step,
  StepLabel,
  Stepper,
  useTheme,
} from "@mui/material";

function getSteps() {
  return ["Qué necesitas", "Confirmar y pagar"];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return <Appoinments />;
    case 1:
      return <Payment />;
    default:
      return "Unknown step";
  }
}

function RenderConsultoria() {
  const theme = useTheme();
  const { activeStep, handleBack } = React.useContext(ConsultaContext);
  const steps = getSteps();

  return (
    <Container
      sx={{
        backgroundColor: theme.palette.background.paper,
        minHeight: "90vh",
        paddingTop: 3,
      }}
    >
      <Container maxWidth="md">
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Container>
      {activeStep === steps.length ? (
        <Paper
          square
          elevation={0}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <PaymentSuccess />
          <PaymentError />
        </Paper>
      ) : (
        <Box sx={{ paddingBottom: 4 }}>
          <Box minHeight="65vh">{getStepContent(activeStep)}</Box>
          <Box
            component="div"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              size="small"
            >
              Regresar
            </Button>
            {activeStep === steps.length - 1 ? null : (
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                size="small"
                type="submit"
                form="appointment-form"
                sx={{
                  marginLeft: 2,
                }}
              >
                Confirmar
              </Button>
            )}
          </Box>
        </Box>
      )}
    </Container>
  );
}

export default function Consultoria() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ConsultaProvider>
      <RenderConsultoria />
    </ConsultaProvider>
  );
}
