import React, { useCallback, useEffect, useState } from "react";
import { Box, Container, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ImagenEmpty from "../../../images/Empty.png";
import clienteAxios from "../../../config/axios";
import SpinNormal from "../../../components/Spin/spinNormal";
import CardsCursos from "../CardCurso/card_curso";
import { useParams } from "react-router-dom";

export default function ResultadoBusqueda() {
  const { url: busqueda } = useParams();
  const [loading, setLoading] = useState(false);
  const [resultados, setResultados] = useState([]);

  const obtenerResultados = useCallback(async () => {
    setLoading(true);
    try {
      const res = await clienteAxios.get(`/course/search/${busqueda}`);
      setResultados(res.data.posts);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [busqueda]);

  const render_cursos = resultados.map((curso, index) => (
    <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3 }} key={index}>
      <CardsCursos curso={curso.course} cursoData={curso} />
    </Grid>
  ));

  useEffect(() => {
    obtenerResultados();
  }, [obtenerResultados]);

  // Spinner de carga
  if (loading) {
    return (
      <Box
        height="80vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <SpinNormal />
      </Box>
    );
  }

  // Sin resultados
  if (resultados.length === 0) {
    return (
      <Container maxWidth="xl">
        <Box height="80vh" mt={5} textAlign="center">
          <Typography variant="h4">{`No hubo resultados de "${busqueda}"`}</Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <img
              alt="resultados busqueda"
              src={ImagenEmpty}
              style={{ maxHeight: "100%", maxWidth: "100%" }}
            />
          </Box>
        </Box>
      </Container>
    );
  }

  // Resultados
  return (
    <Container maxWidth="xl">
      <Box my={5}>
        <Typography variant="h4">{`Hay ${resultados.length} ${
          resultados.length > 1 ? "resultados" : "resultado"
        } de "${busqueda}"`}</Typography>
        <Grid container spacing={2}>
          {render_cursos}
        </Grid>
      </Box>
    </Container>
  );
}
