import React from "react";
import { Box, Container, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTheme } from "@mui/material/styles";
import MenuAnclas from "./MenuAnclas";
import Descripcion from "./Descripcion";
import Aprendizajes from "./Aprendizajes";
import Contenido from "./Contenido";
import Comentarios from "../Comentarios";
import Beneficios from "./Beneficios";

export default function DetallesCurso({ curso }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: "background.paper",
      }}
    >
      <Container maxWidth="lg">
        <MenuAnclas />
        <Box my={2}>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, md: 8 }}>
              <Descripcion curso={curso} />
              <br />
              {isSmallScreen && <Beneficios curso={curso} />}
              <Aprendizajes curso={curso} />
              <br />
              <Contenido curso={curso} />
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
              {!isSmallScreen && <Beneficios curso={curso} />}
            </Grid>
          </Grid>
        </Box>
        <Comentarios curso={curso} />
      </Container>
    </Box>
  );
}
