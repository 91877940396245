import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { formatoMexico } from "../../../config/reuserFunction";
import EliminarProducto from "./EliminarProducto";
import NewProduct from "./NuevoProducto";

export default function TableProducts({ productos, setUpdate, setSnackbar }) {

  return (
    <Paper variant="outlined" style={{ marginTop: "12px" }}>
      <TableContainer sx={{height: "70vh"}}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>descripción</TableCell>
              <TableCell>tipo</TableCell>
              <TableCell>precio</TableCell>
              <TableCell padding="checkbox" />
              <TableCell padding="checkbox" />
            </TableRow>
          </TableHead>
          <TableBody>
            {productos.map((row) => (
              <TableRow key={row._id}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell>${formatoMexico(row.price)}</TableCell>
                <TableCell padding="checkbox">
                  <NewProduct
                    data={row}
                    setUpdate={setUpdate}
                    setSnackbar={setSnackbar}
                  />
                </TableCell>
                <TableCell padding="checkbox">
                  <EliminarProducto
                    data={row}
                    setUpdate={setUpdate}
                    setSnackbar={setSnackbar}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
