import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { createTheme } from "@mui/material/styles";
import { AppProvider } from "@toolpad/core/react-router-dom";
import { DashboardLayout, ThemeSwitcher } from "@toolpad/core/DashboardLayout";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { darkTheme, lightTheme } from "../../config/Theming";
import { NavItems, HeaderTitle } from "./NavigationContenido";
import { CursoContext, CursoProvider } from "../../context/curso_context";
import clienteAxios from "../../config/axios";
import { Button, Stack } from "@mui/material";
import { Done } from "@mui/icons-material";
import {
  verificarBloquesCurso,
  verificarInformacionCurso,
  verificarLearningsCurso,
  verificarPrecioCurso,
} from "../../utils/verificar_contenido";
import MessageSnackbar from "../Snackbar/snackbar";

const demoTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: "data-toolpad-color-scheme",
  },
  colorSchemes: { light: lightTheme, dark: darkTheme },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default function LayoutContenido2() {
  return (
    <CursoProvider>
      <ContentLayout />
    </CursoProvider>
  );
}

const ContentLayout = () => {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = React.useState(false);
  let tema = JSON.parse(localStorage.getItem("tema"));
  const location = useLocation();
  const params = useParams();
  const idcurso = params?.curso;
  const title = HeaderTitle[location?.pathname?.split("/")[4]] ?? "";

  const [snackbar, setSnackbar] = React.useState({
    open: false,
    mensaje: "",
    status: "",
  });
  const [blocks, setBlocks] = React.useState([]);
  const { datos, setDatos, update, setUpdate, setPreview } = React.useContext(
    CursoContext
  );

  const obtenerCursoBD = React.useCallback(async () => {
    setLoading(true);
    await clienteAxios
      .get(`/course/${idcurso}`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        setLoading(false);
        setDatos(res.data);
        if (res.data.urlPromotionalImage) {
          setPreview(res.data.urlPromotionalImage);
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          setSnackbar({
            open: true,
            mensaje: err.response.data.message,
            status: "error",
          });
        } else {
          setSnackbar({
            open: true,
            mensaje: "Al parecer no se a podido conectar al servidor.",
            status: "error",
          });
        }
      });
  }, [idcurso, token, setDatos, setPreview]);

  const obtenerBloquesBD = React.useCallback(async () => {
    if (datos._id && token) {
      await clienteAxios
        .get(`/course/data/${datos._id}`, {
          headers: {
            Authorization: `bearer ${token}`,
          },
        })
        .then((res) => {
          setBlocks(res.data);
        })
        .catch((err) => {
          return;
        });
    }
  }, [datos._id, token]);

  const publicarCurso = async () => {
    if (
      verificarInformacionCurso(datos) &&
      verificarLearningsCurso(datos) &&
      verificarBloquesCurso(blocks) &&
      verificarPrecioCurso(datos)
    ) {
      await clienteAxios
        .put(
          `/course/public/${datos._id}`,
          {
            publication: !datos.publication,
          },
          {
            headers: {
              Authorization: `bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setSnackbar({
            open: true,
            mensaje: res.data.message,
            status: "success",
          });
          setLoading(false);
          setUpdate(!update);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response) {
            setSnackbar({
              open: true,
              mensaje: err.response.data.message,
              status: "error",
            });
          } else {
            setSnackbar({
              open: true,
              mensaje: "Al parecer no se a podido conectar al servidor.",
              status: "error",
            });
          }
        });
    } else {
      setSnackbar({
        open: true,
        mensaje: "Tu curso aun esta incompleto.",
        status: "error",
      });
    }
  };

  React.useEffect(() => {
    obtenerCursoBD();
    obtenerBloquesBD();
  }, [obtenerCursoBD, obtenerBloquesBD, update]);

  React.useEffect(() => {
    if (tema === null) {
      localStorage.setItem("tema", false);
      return;
    }
  }, [tema]);

  return (
    <AppProvider
      branding={{
        logo: "",
        title: (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={2}
          >
            <Typography noWrap>{datos.title ? datos.title : ""}</Typography>
            <Typography noWrap>{" • "}</Typography>
            <Typography noWrap>
              <b>{title}</b>
            </Typography>
          </Box>
        ),
      }}
      navigation={NavItems({ idcurso, curso: datos, block: blocks })}
      theme={demoTheme}
    >
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 10 })}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MessageSnackbar
        open={snackbar.open}
        mensaje={snackbar.mensaje}
        status={snackbar.status}
        setSnackbar={setSnackbar}
      />
      <DashboardLayout
        slots={{
          toolbarAccount: () => null,
          toolbarActions: () =>
            ToolbarActions({ datos, onClick: publicarCurso }),
        }}
      >
        <Box m={2}>
          <Outlet />
        </Box>
      </DashboardLayout>
    </AppProvider>
  );
};

const ToolbarActions = ({ datos, onClick }) => {
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <Button
        size="small"
        color="primary"
        variant="contained"
        disableElevation
        onClick={onClick}
        startIcon={datos.publication && <Done />}
      >
        {datos.publication ? "Publicado" : "Publicar"}
      </Button>
      <ThemeSwitcher />
    </Stack>
  );
};
