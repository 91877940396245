import React, { Fragment } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import PreviewVideo from "./PreviewVideo";
import { ExpandMore } from "@mui/icons-material";

export default function Contenido({ curso }) {
  return (
    <>
      <Box id="programa" sx={{ scrollMarginTop: "12em" }}>
        <Box mb={2}>
          <Typography variant="h6">Programa o contenido</Typography>
          <Divider />
        </Box>
        <Box>
          {curso.contentCourse.map((res, index) => (
            <Fragment key={index}>
              <Accordion variant="outlined" sx={{ border: 0 }}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography variant="h6">
                    {res.block?.blockTitle || "Título no disponible"}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <List sx={{ width: "100%", padding: 0 }}>
                    {res.topics.map((topic, index) => (
                      <ListItem
                        key={topic._id}
                        sx={{
                          padding: 0,
                          marginLeft: { xs: 2, sm: 3 },
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <ListItemText>
                          {`${index + 1} ${topic.topicTitle}`}
                        </ListItemText>

                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <PreviewVideo topic={topic} />
                        </Box>
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
              <Divider />
            </Fragment>
          ))}
        </Box>
      </Box>
    </>
  );
}
