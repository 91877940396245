export const darkTheme = {
    palette: {
		type: 'dark',
		navbar: '#424242!important',
		navbarCategories: '#424242!important',
		session: {
			main: '#E72F2F'
		},
		primary: {
			main: '#2196f3'
		},
		secondary: {
			main: '#0093ed'
		},
		/* error: {
			main: '#f44336'
		},
		warning: {
			main: '#ff9800'
		},
		info: {
			main: 'rgba(255, 255, 255, 0.3)'
		},
		success: {
			main: '#4caf50'
		}, */
		/* text: {
			primary: '#fff',
			secondary: 'rgba(255, 255, 255, 0.7)',
			disabled: 'rgba(255, 255, 255, 0.5)',
			hint: 'rgba(255, 255, 255, 0.5)',
			icon: 'rgba(255, 255, 255, 0.5)'
		},

		divider: 'rgba(255, 255, 255, 0.12)', */
		background: {
			paper: '#424242',
			default: '#303030',
			selected: '#303030'
		},

		/* action: {
			active: '#fff',
			hover: 'rgba(255, 255, 255, 0.08)',
			hoverOpacity: 0.08,
			selected: 'rgba(255, 255, 255, 0.16)',
			selectedOpacity: 0.16,
			disabled: 'rgba(255, 255, 255, 0.3)',
			disabledBackground: 'rgba(255, 255, 255, 0.12)',
			disabledOpacity: 0.38,
			focus: 'rgba(255, 255, 255, 0.12)',
			focusOpacity: 0.12,
			activatedOpacity: 0.24
		} */
	}
}

export const lightTheme = {
    palette: {
		type: 'light',
		navbar: '#4e00a0!important',
		navbarCategories: '#5900B6!important',
		session: {
			main: '#E72F2F'
		},
		primary: {
			/* main: '#2196f3', */
			main: '#4e00a0'
		},
		secondary: {
			main: '#0093ed'
		},
		error: {
			main: '#f44336'
		},
		warning: {
			main: '#ff9800'
		},
		info: {
			main: '#2196f3'
		},
		success: {
			main: '#5cb85c',
			secondary: '#c0ffb5'
		},
		text: {
			primary: 'rgba(0, 0, 0, 0.87)',
			secondary: 'rgba(0, 0, 0, 0.54)',
			disabled: 'rgba(0, 0, 0, 0.38)',
			hint: 'rgba(0, 0, 0, 0.38)'
		},
        divider: 'rgba(0, 0, 0, 0.12)',
		background: {
			paper: '#fff',
			default: '#fafafa',
			selected: '#f5f5f5',
		},
		action: {
			active: 'rgba(0, 0, 0, 0.54)',
			hover: 'rgba(0, 0, 0, 0.04)',
			hoverOpacity: 0.04,
			selected: 'rgba(0, 0, 0, 0.08)',
			selectedOpacity: 0.08,
			disabled: 'rgba(0, 0, 0, 0.26)',
			disabledBackground: 'rgba(0, 0, 0, 0.12)',
			disabledOpacity: 0.38,
			focus: 'rgba(0, 0, 0, 0.12)',
			focusOpacity: 0.12,
			activatedOpacity: 0.12
		}
	}
}