import React, { Fragment, useContext, useState } from "react";
import { IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import InfoIcon from "@mui/icons-material/Info";
import { UsuariosDashCtx } from "../../../../context/usuariosAdminCtx";
import moment from "moment";
import InformacionModalEstudiante from "./InfoModal";
import CustomAvatar from "../../../../components/CustomAvatar";

export default function TablaUsuariosEstudiantes() {
  const { usuarios } = useContext(UsuariosDashCtx);

  return (
    <Box>
      <TableContainer component={Paper} sx={{height: "63vh"}}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left" />
              <TableCell>Nombre</TableCell>
              <TableCell>Correo electrónico</TableCell>
              <TableCell>Telefono</TableCell>
              <TableCell>Se registro el</TableCell>
              <TableCell>Información</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usuarios.map((user, index) => (
              <TableInfoBody key={index} user={user} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

const TableInfoBody = ({ user }) => {
  const [open, setOpen] = useState(false);

  const handleClickModal = (e) => setOpen(!open);

  return (
    <Fragment>
      <TableRow>
        <TableCell align="left">
          {user.urlImage ? (
            <Avatar alt="imagen usuario" src={user.urlImage} />
          ) : (
            <CustomAvatar name={user.name} />
          )}
        </TableCell>
        <TableCell>{user.name}</TableCell>
        <TableCell>{user.email}</TableCell>
        <TableCell>{user.phone ? user.phone : "-"}</TableCell>
        <TableCell>{moment(user.createdAt).format("DD/MM/YYYY")}</TableCell>
        <TableCell align="center">
          <IconButton size="small" color="default" onClick={handleClickModal}>
            <InfoIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <Dialog
        open={open}
        onClose={handleClickModal}
        aria-labelledby="detalles-student-modal"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="detalles-student-modal">{`Detalles de este estudiante`}</DialogTitle>
        <DialogContent>
          <InformacionModalEstudiante user={user} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClickModal()} color="primary" autoFocus>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
