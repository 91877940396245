import React from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { ConsultaContext } from "../Context";

export default function StaticCalendar() {
  const { appointment, setAppointment } = React.useContext(ConsultaContext);

  const handleChange = (value) => {
    setAppointment((st) => ({ ...st, fecha: moment(value).format() }));
  };
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateCalendar
        value={appointment.fecha ? moment(appointment.fecha) : moment()}
        onChange={handleChange}
        disablePast
      />
    </LocalizationProvider>
  );
}
