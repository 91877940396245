import React, { useEffect, useState } from "react";
import { ThemeProvider, Box, Typography, CssBaseline } from "@mui/material";
import theme from "../../config/themeConfig";
import darkMode from "../../config/darkMode";
import { NavProvider } from "../../context/context_nav";
import DashboarUsuario from "../../pages/users/Dashboard_Usuario/dashboard";
import { DashProvider } from "../../context/dashboar_context";
import MantenimientoImg from "./Mantenimiento_img.png";
import mantenimiento from "../../context/context_mantenimiento";
import { useNavigate } from "react-router-dom";

export default function LayoutDashboardUser(props) {
  const navigate = useNavigate();
  const thema = localStorage.getItem("tema");
  const token = localStorage.getItem("token");
  const tema = JSON.parse(thema);
  const [darkTheme, setDarkTheme] = useState(tema);

  if (!token) {
    navigate("/");
  }

  useEffect(() => {
    if (tema === null) {
      localStorage.setItem("tema", false);
    }
  }, [tema]);

  if (mantenimiento) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          textAlign: "center",
        }}
      >
        <Box>
          <img
            alt="Imagen no disponible"
            style={{ width: "700px" }}
            src={MantenimientoImg}
          />
          <Typography variant="h4" fontWeight="bold" sx={{ mt: 2 }}>
            Estamos haciendo cambios
          </Typography>
          <Typography variant="h5" sx={{ mt: 1 }}>
            para brindarte una mejor experiencia.
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <ThemeProvider theme={tema === true ? darkMode : theme}>
      <CssBaseline />
      <NavProvider>
        <DashProvider>
          <DashboarUsuario tema={[darkTheme, setDarkTheme]} />
        </DashProvider>
      </NavProvider>
    </ThemeProvider>
  );
}
