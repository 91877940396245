import React from "react";
import { Box, Divider, Tab, Tabs, useTheme } from "@mui/material";
import { HashLink } from "react-router-hash-link";
import { useLocation } from "react-router-dom";

function MenuAnclas() {
  const theme = useTheme();
  const location = useLocation();
  const [value, setValue] = React.useState(location.hash || "#adquirir");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        position: "sticky",
        top: {
          xs: theme.spacing(6),
          sm: theme.spacing(10),
        },
        backgroundColor: "background.paper",
        zIndex: 99,
      }}
    >
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="menu anclas"
      >
        <Tab
          label="Adquirir"
          value="#adquirir"
          component={HashLink}
          to="#adquirir"
          smooth
        />
        <Tab
          label="Descripción"
          value="#descripcion"
          component={HashLink}
          to="#descripcion"
          smooth
        />
        <Tab
          label="Aprendizaje"
          value="#aprendizaje"
          component={HashLink}
          to="#aprendizaje"
          smooth
        />
        <Tab
          label="Programa"
          value="#programa"
          component={HashLink}
          to="#programa"
          smooth
        />
        <Tab
          label="Reseñas y comentarios"
          value="#comentarios"
          component={HashLink}
          to="#comentarios"
          smooth
        />
      </Tabs>
      <Divider />
    </Box>
  );
}

export default MenuAnclas;
