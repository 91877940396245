import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

// Configuración de Firebase
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROYECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Inicializa Firebase
export const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

// Función para obtener el token FCM
export const obtenerTokenFCM = async () => {
  try {
    // Solicitar permiso para notificaciones
    const permission = await Notification.requestPermission();
    if (permission !== "granted") {
      console.warn("Permiso para notificaciones no concedido.");
      return null;
    }

    // Obtener el token FCM
    const currentToken = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    });

    if (currentToken) {
      console.log("Token FCM obtenido:", currentToken);
      return currentToken;
    } else {
      console.warn("No se pudo obtener el token FCM.");
      return null;
    }
  } catch (err) {
    console.error("Error al obtener el token FCM:", err);
    return null;
  }
};
