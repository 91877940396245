import React, { Fragment, useState, useCallback, useEffect } from "react";
import {
  Box,
  InputBase,
  Typography,
  Button,
  Grid2 as Grid,
} from "@mui/material";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import Alert from "@mui/material/Alert";
import LinearProgress from "@mui/material/LinearProgress";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import { Upload } from "tus-js-client";
/* import { CursoContext } from '../../../../../context/curso_context'; */

import VimeoReproductor from "../../../../../components/Vimeo_Reproductor/Vimeo";
import MessageSnackbar from "../../../../../components/Snackbar/snackbar";
import clienteAxios from "../../../../../config/axios";
import BackDropVideo from "../../../../../components/Spin/backdropVideo";
import { Delete } from "@mui/icons-material";
import { useParams } from "react-router-dom";

export default function UploadVideo(props) {
  const token = localStorage.getItem("token");
  const [datos, setDatos] = useState([]);
  const [update, setUpdate] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    mensaje: "",
    status: "",
  });
  const [fileVideo, setFileVideo] = useState(null);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [backdrop, setBackdrop] = useState(false);
  const params = useParams();
  const idcurso = params?.curso;

  const getFile = (e) => {
    try {
      setFileVideo(e.target.files[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const successAPI = (mensaje) => {
    setSnackbar({
      open: true,
      mensaje: mensaje,
      status: "success",
    });
    setUpdate(!update);
    setLoading(false);
    setBackdrop(false);
    setProgress(0);
    setFileVideo(null);
  };

  const errorAPI = (err) => {
    setLoading(false);
    setBackdrop(false);
    if (err.response) {
      setSnackbar({
        open: true,
        mensaje: err.response.data.message,
        status: "error",
      });
    } else {
      setSnackbar({
        open: true,
        mensaje: "Al parecer no se a podido conectar al servidor.",
        status: "error",
      });
    }
  };

  const obtenerCursoBD = useCallback(async () => {
    setLoading(true);
    await clienteAxios
      .get(`/course/${idcurso}`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        setLoading(false);
        setDatos(res.data);
      })
      .catch((err) => {
        errorAPI(err);
      });
  }, [idcurso, token, setDatos]);

  const handleSubmit = () => {
    setLoading(true);
    setBackdrop(true);
    clienteAxios
      .post(
        `/course/uploadVideo`,
        {
          name: datos.title,
          size: fileVideo.size,
        },
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
        const upload = new Upload(fileVideo, {
          uploadUrl: res.data.uploadLink,
          endpoint: res.data.uploadLink,
          chunkSize: 5 * 1024 * 1024, // Tamaño de cada fragmento (5 MB)
          retryDelays: [0, 1000, 3000, 5000], // Retrasos en reintentos
          onProgress: (bytesUploaded, bytesTotal) => {
            const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
            setLoading(false);
            setProgress(parseInt(percentage));
          },
          onError: (error) => {
            console.log("Error al subir video:", error, error.message);
            setLoading(false);
            setBackdrop(false);
            setSnackbar({
              open: true,
              mensaje: "Hubo un error: " + error,
              status: "error",
            });
          },
          onSuccess: (res) => {
            clienteAxios
              .put(
                `/course/video/${datos._id}`,
                {
                  title: datos.title,
                },
                {
                  headers: {
                    Authorization: `bearer ${token}`,
                  },
                }
              )
              .then((res) => {
                successAPI(res.data.message);
              })
              .catch((err) => {
                errorAPI(err);
              });
          },
        });

        upload.start();
      })
      .catch((err) => {
        errorAPI(err);
      });
  };

  const handleDelete = () => {
    clienteAxios
      .put(
        `/course/deleteVideo/${datos._id}`,
        {
          video: datos.urlCourseVideo,
        },
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
        successAPI(res.data.message);
      })
      .catch((err) => {
        errorAPI(err);
      });
  };

  useEffect(() => {
    obtenerCursoBD();
  }, [obtenerCursoBD, update]);

  return (
    <Fragment>
      <MessageSnackbar
        open={snackbar.open}
        mensaje={snackbar.mensaje}
        status={snackbar.status}
        setSnackbar={setSnackbar}
      />
      <BackDropVideo
        backdrop={backdrop}
        loading={loading}
        progress={progress}
      />
      <Grid container direction="row" spacing={1}>
        <Grid size={{ sx: 12, md: 6 }}>
          {datos.urlCourseVideo ? (
            <Box
              height={250}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <VimeoReproductor
                idVideo={datos.urlCourseVideo ? datos.urlCourseVideo : ""}
                width="100%"
                height="100%"
              />
            </Box>
          ) : (
            <Box
              height={300}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box textAlign="center">
                <VideocamOutlinedIcon style={{ fontSize: 130 }} />
                <Typography>No hay video de presentacion</Typography>
              </Box>
            </Box>
          )}
        </Grid>
        <Grid size={{ sx: 12, md: 6 }}>
          <Box>
            <Alert severity="info">
              <Typography variant="body1">
                Este video aparecera en la descripción cuando los estudiantes
                den click en tu curso.
              </Typography>
              <br />
              <Typography variant="body2">
                Al subir un video, la plataforma tardara unos minutos en
                renderizar el contenido, despues de eso recarga la pagina para
                ver el video.
              </Typography>
            </Alert>
          </Box>
          <Box mt={1}>
            <input
              name="promotionalVideo"
              style={{ display: "none" }}
              id="icon-button-video"
              type="file"
              onChange={getFile}
              disabled={!datos.urlCourseVideo ? false : true}
            />

            <label htmlFor="icon-button-video">
              <Button
                startIcon={<VideoCallIcon style={{ fontSize: 30 }} />}
                color="primary"
                variant="outlined"
                component="span"
                disabled={!datos.urlCourseVideo ? false : true}
              >
                Cargar archivo
              </Button>
            </label>
            <InputBase
              value={fileVideo ? fileVideo.name : ""}
              placeholder=" Selecciona un video"
              inputProps={{ "aria-label": "naked", readOnly: true }}
            />
            {loading ? (
              <Box mt={1}>
                <LinearProgress />
              </Box>
            ) : null}
            <Grid container spacing={1}>
              <Grid>
                {!fileVideo ? null : (
                  <Box mt={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => handleSubmit()}
                    >
                      Subir
                    </Button>
                  </Box>
                )}
              </Grid>
              <Grid>
                {!datos.urlCourseVideo ? null : (
                  <Box mt={1}>
                    <Grid container>
                      <Grid>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="large"
                          onClick={() => handleDelete()}
                          startIcon={<Delete />}
                        >
                          Eliminar video
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
}
