import React from "react";
import {
  Box,
  Breadcrumbs,
  Grid2 as Grid,
  Link,
  Typography,
  Rating,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { formatoFechaCurso } from "../../../config/reuserFunction";
import MetodosPago from "./MetodosPago";

export default function InfoPrincipal({ cursos, setSnackbar }) {
  return (
    <Box>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ color: "white", fontSize: 18 }}
      >
        <Link
          color="inherit"
          href={`/busqueda/${cursos.course.category}`}
          target="_blank"
          rel="noopener"
          underline="hover"
        >
          {cursos.course.category}
        </Link>
        <Link
          color="inherit"
          href={`/busqueda/${cursos.course.subCategory}`}
          target="_blank"
          rel="noopener"
          underline="hover"
        >
          {cursos.course.subCategory}
        </Link>
      </Breadcrumbs>

      <Box my={1} />

      <Typography variant="h3" component="h1" sx={{ color: "white" }}>
        <b>{cursos.course.title}</b>
      </Typography>

      <Box my={1} />

      <Typography variant="h5" component="h2" sx={{ color: "white" }}>
        {cursos.course.subtitle}
      </Typography>

      <Box my={2}>
        <Grid container spacing={2}>
          <Grid item>
            <Rating
              name="read-only"
              value={cursos.course.qualification}
              precision={0.5}
              readOnly
            />
          </Grid>
          <Grid item>
            <Typography sx={{ color: "white" }}>
              {`${cursos.totalInscription} estudiantes`}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Typography sx={{ color: "white" }}>
        <b>
          {`Creado el ${formatoFechaCurso(cursos.course.createdAt)} por ${
            cursos.course.idProfessor.name
          }`}
        </b>
      </Typography>

      <MetodosPago curso={cursos} setSnackbar={setSnackbar} />
    </Box>
  );
}
