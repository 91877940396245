import React, { useCallback, useContext, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid2 as Grid,
  IconButton,
  InputBase,
  Radio,
  RadioGroup,
  TablePagination,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Spin from "../../../../../components/Spin/spin";
import MessageSnackbar from "../../../../../components/Snackbar/snackbar";
import clienteAxios from "../../../../../config/axios";
import { CursoContext } from "../../../../../context/curso_context";
import { formatoFecha } from "../../../../../config/reuserFunction";

const useStyles = {
  table: {
    width: "100%",
  },
  colorCanjeados: {
    color: (theme) => theme.palette.success.main,
  },
  root: {
    padding: "0px 4px",
    display: "flex",
    alignItems: "center",
  },
  iconButton: {
    padding: 1,
  },
  divider: {
    height: 28,
    margin: 1,
  },
};

export default function GenerarCupones() {
  const [cantidadCupones, setCantidadCupones] = useState(0);
  const [busqueda, setBusqueda] = useState("");
  const [cuponesBD, setCuponesBD] = useState([]);
  const [update, setUpdate] = useState(false);
  const { datos } = useContext(CursoContext);
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    mensaje: "",
    status: "",
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const obtenerCantidadCupones = (e) => setCantidadCupones(e.target.value);

  const obtenerBusqueda = (e) => setBusqueda(e.target.value);

  const obtenerCuponesBD = useCallback(
    async (filtro, busqueda) => {
      setLoading(true);
      await clienteAxios
        .get(
          filtro === "todos"
            ? `/course/coupon/${datos._id}`
            : filtro === "search"
            ? `/course/coupon/${datos._id}?code=${busqueda}`
            : `/course/coupon/${datos._id}?exchange=${filtro}`,
          {
            headers: {
              Authorization: `bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setLoading(false);
          setCuponesBD(res.data);
          setPage(0);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response) {
            setSnackbar({
              open: true,
              mensaje: err.response.data.message,
              status: "error",
            });
          } else {
            setSnackbar({
              open: true,
              mensaje: "Al parecer no se a podido conectar al servidor.",
              status: "error",
            });
          }
        });
    },
    [datos._id, token]
  );

  const generarCupones = async () => {
    if (!cantidadCupones) {
      return;
    }
    setLoading(true);
    await clienteAxios
      .post(
        `/course/coupon/${datos._id}`,
        {
          coupon: cantidadCupones,
        },
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        setSnackbar({
          open: true,
          mensaje: res.data.message,
          status: "success",
        });
        setCantidadCupones(0);
        setUpdate(!update);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          setSnackbar({
            open: true,
            mensaje: err.response.data.message,
            status: "error",
          });
        } else {
          setSnackbar({
            open: true,
            mensaje: "Al parecer no se a podido conectar al servidor.",
            status: "error",
          });
        }
      });
  };

  useEffect(() => {
    obtenerCuponesBD("todos");
  }, [obtenerCuponesBD, update]);

  return (
    <Box>
      <Spin loading={loading} />
      <MessageSnackbar
        open={snackbar.open}
        mensaje={snackbar.mensaje}
        status={snackbar.status}
        setSnackbar={setSnackbar}
      />
      <Box my={2}>
        <form noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid>
              <TextField
                name="cupones"
                type="number"
                label="Cantidad de cupones"
                variant="outlined"
                size="small"
                onChange={obtenerCantidadCupones}
              />
            </Grid>
            <Grid>
              <Button
                variant="contained"
                color="primary"
                onClick={generarCupones}
              >
                Generar cupones
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Box>
        <Box my={1}>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, lg: 6 }}>
              <Paper variant="outlined" component="form" sx={useStyles.root}>
                <InputBase
                  fullWidth
                  placeholder="Busca tu cupon"
                  inputProps={{ "aria-label": "busqueda por estudiante" }}
                  onChange={obtenerBusqueda}
                />
                <Divider sx={useStyles.divider} orientation="vertical" />
                <IconButton
                  sx={useStyles.iconButton}
                  aria-label="search"
                  onClick={() => obtenerCuponesBD("search", busqueda)}
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Grid>
            <Grid>
              <RadioGroup
                row
                aria-label="filtros"
                name="filtros"
                defaultValue="todos"
              >
                <FormControlLabel
                  value="todos"
                  control={<Radio color="primary" />}
                  label="Todos"
                  labelPlacement="start"
                  onChange={(e) => obtenerCuponesBD(e.target.value)}
                />
                <FormControlLabel
                  value="true"
                  control={<Radio color="primary" />}
                  label="Canjeados"
                  labelPlacement="start"
                  onChange={(e) => obtenerCuponesBD(e.target.value)}
                />
                <FormControlLabel
                  value="false"
                  control={<Radio color="primary" />}
                  label="No canjeados"
                  labelPlacement="start"
                  onChange={(e) => obtenerCuponesBD(e.target.value)}
                />
              </RadioGroup>
            </Grid>
          </Grid>
        </Box>
        <TableContainer component={Paper}>
          <Table stickyHeader sx={useStyles.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Cupones</TableCell>
                <TableCell>Canjeado</TableCell>
                <TableCell>Usuario</TableCell>
                <TableCell>Fecha</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cuponesBD
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={row.exchange ? useStyles.colorCanjeados : null}
                    >
                      {row.code}
                    </TableCell>
                    <TableCell
                      sx={row.exchange ? useStyles.colorCanjeados : null}
                    >
                      {row.exchange ? "SI" : "NO"}
                    </TableCell>
                    <TableCell
                      sx={row.exchange ? useStyles.colorCanjeados : null}
                    >
                      {row.idUser ? row.idUser.name : "-"}
                    </TableCell>
                    <TableCell
                      sx={row.exchange ? useStyles.colorCanjeados : null}
                    >
                      {row.exchange ? formatoFecha(row.updatedAt) : "-"}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={cuponesBD.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelDisplayedRows={({ from, to, count }) =>
            `${to} de ${count !== -1 ? count : to}`
          }
        />
      </Box>
    </Box>
  );
}
