import React from "react";
/* import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined'; */
import clienteAxios from "../../../../config/axios";
import { blue, red } from "@mui/material/colors";
import { Button } from "@mui/material";
import { ThumbUpAltOutlined } from "@mui/icons-material";

export default function LikesAndDislikes({
  update,
  setUpdate,
  comentario,
  respuesta,
}) {
  const classes = {
    like: {
      color: blue[700],
    },
    dislike: {
      color: red[400],
    },
  };
  const token = localStorage.getItem("token");

  const like = async () => {
    await clienteAxios
      .put(
        !respuesta
          ? `/comment/${comentario._id}/like`
          : `/comment/${comentario._id}/answer/${respuesta._id}/like`,
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setUpdate(!update);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  /* const dislike = async () => {
		await clienteAxios
			.put(
				!respuesta
					? `/comment/${comentario._id}/dislike`
					: `/comment/${comentario._id}/answer/${respuesta._id}/dislike`,
				{
					headers: {
						Authorization: `bearer ${token}`
					}
				}
			)
			.then((res) => {
				setUpdate(!update);
			})
			.catch((err) => {
				console.log(err);
			});
	}; */
  return (
    <>
      <Button
        aria-label="like"
        startIcon={<ThumbUpAltOutlined />}
        size="large"
        onClick={() => like()}
        sx={
          !respuesta
            ? comentario.likes > 0
              ? classes.like
              : null
            : respuesta.likes > 0
              ? classes.like
              : null
        }
      >
        {!respuesta ? comentario.likes : respuesta.likes}
      </Button>
      {/* <Button
				aria-label="dislike"
				startIcon={<ThumbDownAltOutlinedIcon />}
				size="large"
				onClick={() => dislike()}
				className={
					!respuesta ? comentario.dislikes > 0 ? (
						classes.dislike
					) : null : respuesta.dislikes > 0 ? (
						classes.dislike
					) : null
				}
			>
				{!respuesta ? comentario.dislikes : respuesta.dislikes}
			</Button> */}
    </>
  );
}
