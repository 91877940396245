import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import DashboardMaestro from "./Dashboard";
import PackagesCourses from "../PackagesCourses/PackagesIndex";
import Productos from "../Productos";
import Categorias from "../Categorias/Categorias";
import { Category, ShopTwo, Subscriptions, Widgets } from "@mui/icons-material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && <Box py={2}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

export default function DashboardPrincipal(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Tabs value={value} onChange={handleChange}>
        <Tab icon={<Subscriptions />} label="Cursos" {...a11yProps(0)} />
        <Tab icon={<ShopTwo />} label="Paquetes" {...a11yProps(1)} />
        <Tab icon={<Widgets />} label="Apps" {...a11yProps(2)} />
        <Tab icon={<Category />} label="Categorias" {...a11yProps(3)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <DashboardMaestro />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PackagesCourses />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Productos />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Categorias />
      </TabPanel>
    </div>
  );
}
