import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
  Box,
} from "@mui/material";
import ImagenCertificado from "../../../images/ejemplo_cert.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UbicacionNumeroCertificado() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="text"
        size="medium"
        color="primary"
        onClick={handleClickOpen}
        sx={{ textTransform: "none" }}
      >
        ¿Dónde encuentro mi número de certificado?
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="d-description-certificate"
      >
        <DialogContent>
          <DialogContentText id="d-description-certificate">
            Tu número de certificado se encuentra en la parte inferior derecha,
            como se muestra en la siguiente imagen
          </DialogContentText>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <img
              alt="ubicacion numero certificado"
              src={ImagenCertificado}
              style={{ maxHeight: "100%", maxWidth: "100%" }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
