import React, { useState, useEffect } from "react";
import { Detector } from "react-detect-offline";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Close, Wifi, WifiOff } from "@mui/icons-material";
import Slide from "@mui/material/Slide";

export default function ConexionDetect() {
  const [firstLoad, setFirstLoad] = useState(true); // Control de la primera carga
  const [isOnline, setIsOnline] = useState(true); // Estado de conexión

  // Actualizamos `firstLoad` solo después del render inicial
  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
    }
  }, [firstLoad]);

  return (
    <Box>
      <Detector
        render={({ online }) => {
          if (firstLoad) {
            return null; // No mostrar nada durante la primera carga
          }

          // Mostrar la barra de estado dependiendo de la conexión
          if (online) {
            if (!isOnline) setIsOnline(true); // Actualiza el estado a "online"
            return <SnackOnline />;
          } else {
            if (isOnline) setIsOnline(false); // Actualiza el estado a "offline"
            return <SnackOffline />;
          }
        }}
      />
    </Box>
  );
}

const SnackOnline = () => {
  const [open, setOpen] = useState(true);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={open}
      autoHideDuration={6000}
      TransitionComponent={Slide}
      onClose={() => setOpen(false)}
      message={
        <Box display="flex">
          <Wifi htmlColor="#81c784" />
          <Box mx={1} />
          <Typography>Estás Conectado</Typography>
        </Box>
      }
      action={
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setOpen(false)}
          >
            <Close fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  );
};

const SnackOffline = () => (
  <Snackbar
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    open={true}
    TransitionComponent={Slide}
    message={
      <Box display="flex">
        <WifiOff htmlColor="#bdbdbd" />
        <Box mx={1} />
        <Typography>Sin Conexión</Typography>
      </Box>
    }
  />
);
