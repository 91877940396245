import { useContext, useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { Box, Button } from "@mui/material";
import MessageSnackbar from "../../../../components/Snackbar/snackbar";
import clienteAxios from "../../../../config/axios";
import { CtxPrincipal } from "../../../../context/ContextPrincipal";

export default function PagoPaypal({ order, compra, total }) {
  const token = localStorage.getItem("token");
  const { setPayment } = useContext(CtxPrincipal);
  const [snackbar, setSnackbar] = useState({
    open: false,
    mensaje: "",
    status: "",
  });

  const handleApprove = async (data, actions) => {
    try {
      const response = await actions.order.capture();
      await clienteAxios.post(
        `/pay/confirm/paypal`,
        {
          idPaypal: response.id,
          courses: compra.courses,
          username: compra.user.name,
          idUser: compra.user._id,
          total: total,
          typePay: "paypal",
        },
        {
          headers: {
            Authorization: `bearer ${token}`,
          },
        }
      );
      window.location.href = `/payment_success/${response.id}`;
    } catch (error) {
      if (error.response) {
        window.location.href = `/payment_failed/paypal/${error.response.data.message}`;
      } else {
        window.location.href = `/payment_failed/paypal/Al parecer no se ha podido conectar al servidor`;
      }
    }
  };

  const handleError = () => {
    setSnackbar({
      open: true,
      mensaje: "Error al procesar el pago.",
      status: "error",
    });
  };

  const handleCancel = () => {
    setSnackbar({
      open: true,
      mensaje: "Pago cancelado por el usuario.",
      status: "info",
    });
  };

  const cancelarCompra = () => {
    setPayment(null);
    window.location.href = "/";
  };

  return (
    <PayPalScriptProvider
      options={{
        "client-id": process.env.REACT_APP_PAYPAL_LIVE,
        currency: "MXN",
      }}
    >
      <Box>
        <PayPalButtons
          style={{ layout: "vertical", color: "blue", shape: "rect" }}
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: total,
                  },
                  description: "Compra en UNILINE",
                },
              ],
            });
          }}
          onApprove={handleApprove}
          onError={handleError}
          onCancel={handleCancel}
        />
        <MessageSnackbar
          open={snackbar.open}
          mensaje={snackbar.mensaje}
          status={snackbar.status}
          setSnackbar={setSnackbar}
        />
        <Button
          fullWidth
          color="primary"
          size="large"
          variant="outlined"
          onClick={cancelarCompra}
        >
          Cancelar compra
        </Button>
      </Box>
    </PayPalScriptProvider>
  );
}
