import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  IconButton,
  Box,
  useMediaQuery,
  useTheme,
  Slide,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PopupModalPrincipal({ ready }) {
  const [open, setOpen] = useState(false);
  const [dataModal, setDataModal] = useState(null);
  const [verMas, setVerMas] = useState(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    setOpen(false);
    localStorage.setItem(
      "modal_uniline",
      JSON.stringify([{ ...dataModal, open: true }])
    );
  };

  useEffect(() => {
    const modal = JSON.parse(localStorage.getItem("modal_uniline"));
    if (modal && !modal[0].open) {
      setDataModal(modal[0]);
      setTimeout(() => {
        setOpen(true);
      }, 1200);
    }
  }, [ready]);

  if (!dataModal) return null;

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth={isSmallScreen ? "xs" : "md"}
        scroll={isSmallScreen ? "paper" : "body"}
        sx={{
          zIndex: 999999,
        }}
      >
        {isSmallScreen ? (
          <DialogTitle
            sx={{
              p: 0,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        ) : null}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: isSmallScreen ? "100vh" : "50vh",
            cursor: "pointer",
          }}
          onClick={() => {
            if (dataModal.url) {
              window.open(dataModal.url, "_blank");
              handleClose();
            }
          }}
        >
          <img
            alt="modal uniline"
            src={isSmallScreen ? dataModal.image_devices : dataModal.image}
            style={{ maxHeight: "100%", maxWidth: "100%" }}
          />
        </Box>

        {dataModal.title && (
          <DialogTitle>
            <Typography>{dataModal.title}</Typography>
          </DialogTitle>
        )}

        <DialogContent>
          <Box
            sx={{
              height: 200,
              pb: 2,
              lineHeight: 1.2,
              fontSize: "20px",
              overflow: "hidden",
              transition: "height 0.3s",
            }}
          >
            <Typography>{verMas ? dataModal.description : null}</Typography>
          </Box>
          {dataModal.description && (
            <Box sx={{ my: 2, pb: 2, textAlign: "center" }}>
              <Typography
                onClick={() => setVerMas(!verMas)}
                sx={{
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                {verMas ? "Ver menos" : "Ver más"}
              </Typography>
            </Box>
          )}
        </DialogContent>

        {!isSmallScreen && (
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cerrar
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
