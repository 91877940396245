import React, { useState } from "react";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { Box, Typography, CircularProgress } from "@mui/material";
import MessageSnackbar from "../../../../../components/Snackbar/snackbar";
import clienteAxios from "../../../../../config/axios";
import { ConsultaContext } from "../../Context";
import { formatoMexico } from "../../../../../config/reuserFunction";

export default function PaypalPayment({ typePayment }) {
  return (
    <PayPalScriptProvider
      options={{
        "client-id": process.env.REACT_APP_PAYPAL_LIVE,
        currency: "MXN",
      }}
    >
      <ComponentPaypal />
    </PayPalScriptProvider>
  );
}

const ComponentPaypal = ({ typePayment }) => {
  const { appointment, handleNext, setPaymentStatus } = React.useContext(
    ConsultaContext
  );
  const [{ isPending }] = usePayPalScriptReducer();
  const { baseAmount, amount } = appointment;
  const [snackbar, setSnackbar] = useState({
    open: false,
    mensaje: "",
    status: "",
  });

  const handleApprove = async (data, actions) => {
    try {
      const response = await actions.order.capture();
      const paymentData = {
        idPaypal: response.id,
        username: appointment.name,
        total: appointment.amount,
        typePay: "paypal",
        isService: true,
        idService: appointment.idProduct,
        summary: appointment.summary,
      };

      await clienteAxios.post(`/appointment/payment/paypal/confirm/`, {
        paymentData,
        appointment,
      });

      setPaymentStatus({
        status: "success",
        data: {
          message:
            "Reunión Agendada: Verifica tu correo electrónico para ver los datos de la reunión",
        },
      });
      handleNext();
    } catch (error) {
      console.error(error);
      setSnackbar({
        open: true,
        mensaje: "Ocurrió un error al procesar el pago.",
        status: "error",
      });
    }
  };

  return (
    <Box sx={{ textAlign: "center" }}>
      <Typography>
        Subtotal: <b>${formatoMexico(baseAmount)}</b>
      </Typography>
      <Typography variant="h6" sx={{ marginBottom: 3 }}>
        Total: <b>${formatoMexico(amount)}</b>
      </Typography>
      {isPending && (
        <Box
          py={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={1}
        >
          <CircularProgress size={20} />
          <Typography>Cargando métodos de pago de paypal...</Typography>
        </Box>
      )}

      <PayPalButtons
        style={{
          layout: "vertical",
          color: "blue",
          shape: "rect",
        }}
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: appointment.amount,
                },
              },
            ],
          });
        }}
        onApprove={handleApprove}
        onError={() =>
          setSnackbar({
            open: true,
            mensaje: "Error al conectar con PayPal",
            status: "error",
          })
        }
        onCancel={() =>
          setSnackbar({
            open: true,
            mensaje: "Pago cancelado por el usuario.",
            status: "info",
          })
        }
      />

      <MessageSnackbar
        open={snackbar.open}
        mensaje={snackbar.mensaje}
        status={snackbar.status}
        setSnackbar={setSnackbar}
      />
    </Box>
  );
};
