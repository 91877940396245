import React, { Fragment } from "react";
import moment from "moment";
import ExcelExportComponent from "../../../../components/ExcelExportComponent";
import clienteAxios from "../../../../config/axios";

const formatTable = (users = []) => {
  console.log(users)
  return users.map((user) => ({
    nombre: user.name,
    correo: user.email,
    telefono: user.phone ?? "-",
    edad: user.age,
    profesion: user.profession,
    escolaridad: user.scholarship,
    registro: moment(user.createdAt).format("DD/MM/YYYY"),
  }));
};

function ExportarExcelEstudiantes({ usuarios }) {
  const excelData = formatTable(usuarios);
  const [loading, setLoading] = React.useState(false);
  const token = localStorage.getItem("token");

  const getUsersData = async () => {
    try {
      setLoading(true);
      const res = await clienteAxios.get(`/user?search`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      });
      setLoading(false);
      return formatTable(res.data);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <ExcelExportComponent
        data={excelData}
        name="Lista de estudiantes Uniline"
        getQueryData={getUsersData}
        loading={loading}
      />
    </Fragment>
  );
}

export default ExportarExcelEstudiantes;
