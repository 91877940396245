import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { createTheme } from "@mui/material/styles";
import { AppProvider } from "@toolpad/core/react-router-dom";
import { DashboardLayout } from "@toolpad/core/DashboardLayout";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Sesion from "../Verificacion_sesion/verificacion_sesion";
import { darkTheme, lightTheme } from "../../config/Theming";
import CustomAvatar from "../CustomAvatar";
import ToolbarActionsAccount from "./AccountSettings";
import { signOutUser } from "../../utils/logout";
import { getNavItems, HeaderTitle } from "./NavigationsItems";

const demoTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: "data-toolpad-color-scheme",
  },
  colorSchemes: { light: lightTheme, dark: darkTheme },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
});

const user_data = { _id: "", name: "", imagen: "" };

function LayoutMaestro() {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("student")) ?? user_data;
  let tema = JSON.parse(localStorage.getItem("tema"));
  const location = useLocation()?.pathname;
  const title = HeaderTitle[location?.split("/")[2]] ?? "";

  React.useEffect(() => {
    if (tema === null) {
      localStorage.setItem("tema", false);
      return;
    }
  }, [tema]);

  React.useEffect(() => {
    Sesion(navigate);
  }, [navigate]);

  const logout = async () => {
    console.log("LOGOUTTTT");
    setLoading(true);
    await signOutUser();
    setLoading(false);
  };

  return (
    <AppProvider
      branding={{
        logo: "",
        /* title: user.name
          ? `👋 Hola, ${user.name.split(" ")[0]}`
          : "Dashboard instructor", */
        title: (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={2}
          >
            <Typography noWrap>
              {user.name
                ? `👋 Hola, ${user.name.split(" ")[0]}`
                : "Dashboard instructor"}
            </Typography>
            <Typography noWrap>{" • "}</Typography>
            <Typography noWrap>
              <b>{title}</b>
            </Typography>
          </Box>
        ),
      }}
      session={{
        user: token && {
          name: user.name,
          email: user.email,
          image: user?.imagen ?? (
            <CustomAvatar size={30} fontSize={16} name={user.name} />
          ),
        },
      }}
      authentication={{
        signOut: () => logout(),
      }}
      navigation={getNavItems(user)}
      theme={demoTheme}
    >
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 10 })}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DashboardLayout
        slots={{
          toolbarAccount: () => null,
          toolbarActions: ToolbarActionsAccount,
        }}
      >
        <Box m={1} mx={2}>
          <Outlet />
        </Box>
      </DashboardLayout>
    </AppProvider>
  );
}

export default LayoutMaestro;
