import React from "react";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import ExportarPagos from "./ExportarExcelCursos";
import { Box, Button } from "@mui/material";

export default function FiltrosCursos({ setFiltro }) {
  const [busqueda, setBusqueda] = React.useState("");

  const hanldeChangeInput = (value) => {
    setBusqueda(value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (!busqueda) {
      setFiltro("");
    }
    setFiltro(busqueda);
  };

  return (
    <Grid container spacing={2} alignItems="center" style={{ width: "100%" }}>
      <Grid size={{ xs: 12, md: 6 }}>
        <form onSubmit={handleSearch}>
          <TextField
            fullWidth
            size="small"
            placeholder="Buscar por estudiante, curso, o un cupon de descuento..."
            value={busqueda}
            onChange={(e) => hanldeChangeInput(e.target.value)}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="center">
                  <IconButton type="submit">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </form>
      </Grid>
      <Grid
        size={{ xs: 12, md: 6 }}
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Button startIcon={<CloseIcon />} onClick={() => setFiltro("")}>
          Limpiar filtro
        </Button>
        <Box mx={1} />
        <ExportarPagos />
      </Grid>
    </Grid>
  );
}
