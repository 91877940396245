import React from "react";

import { Box, Button, Grid2 as Grid, Typography } from "@mui/material";

import imagen from "../../images/uniline3.png";

export default function AgradecimientoTaller(props) {
  const classes = {
    containerLogo: {
      maxHeight: 140,
      maxWidth: 260,
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
    },
    imagenDimension: {
      maxHeight: "100%",
      maxWidth: "100%",
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
    },
    buttonInscripcion: {
      backgroundColor: "#EFB321",
      fontSize: 20,
    },
    inputText: {
      maxWidth: "100%",
    },
    titulo: {
      fontSize: 30,
      fontWeight: "bold",
    },
    informacion: {
      fontSize: 25,
    },
  };

  return (
    <Grid container>
      <Grid item justify="center">
        <Box p={3} textAlign="center" sx={classes.containerLogo}>
          <img
            style={classes.imagenDimension}
            src={imagen}
            alt="Imagen de Ulinine"
          />
        </Box>
      </Grid>
      <Grid item>
        <Box textAlign="center" p={2}>
          <Typography sx={classes.titulo}>
            Gracias por inscribirte a nuestro Taller
          </Typography>
        </Box>
        <Box textAlign="center" p={2}>
          <Typography sx={classes.informacion}>
            Enseguida te llegará una notificación al correo electrónico que nos
            proporcionaste.
          </Typography>
          <Typography sx={classes.informacion}>
            En caso de no encontrarlo, revisar tu spam y agreganos a favoritos.
          </Typography>
          <Typography sx={classes.informacion}>
            Cualquier duda o aclaración escribenos al siguiente correo:
            soporte@cursosuniline.com
          </Typography>
        </Box>
        <Box textAlign="center" p={2}>
          <Button
            sx={classes.buttonInscripcion}
            onClick={() => props.history.push(`/`)}
            size="large"
            variant="contained"
          >
            Volver
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}
