import React from "react";
import { Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import moment from "moment";
import CustomAvatar from "../../../../components/CustomAvatar";

export default function InformacionModalEstudiante({ user }) {

  return (
    <Box>
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left" />
              <TableCell>Nombre</TableCell>
              <TableCell>Correo electrónico</TableCell>
              <TableCell>Telefono</TableCell>
              <TableCell>Se registro el</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">
                {user.urlImage ? (
                  <Avatar alt="imagen usuario" src={user.urlImage} />
                ) : (
                  <CustomAvatar name={user.name} />
                )}
              </TableCell>
              <TableCell>{user.name}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.phone ? user.phone : "-"}</TableCell>
              <TableCell>
                {moment(user.createdAt).format("DD/MM/YYYY")}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box my={2} display="flex" justifyContent="center">
        <Typography variant="h6">Cursos del estudiante</Typography>
      </Box>
      <TableContainer sx={{height: "40vh"}}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Curso</TableCell>
              <TableCell>Inscrito el</TableCell>
              <TableCell>Avance</TableCell>
              <TableCell>Concluido</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {user.inscriptions.map((res, index) => (
              <CursosEstudiante key={index} curso={res} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

const CursosEstudiante = ({ curso }) => {
  return (
    <TableRow>
      <TableCell>
        <Box display="flex" justifyContent="center" alignItems="center">
          <img
            alt="imagen curso"
            src={curso.course[0].urlPromotionalImage}
            height="40"
            width="70"
          />
        </Box>
      </TableCell>
      <TableCell>{curso.course[0].title}</TableCell>
      <TableCell>{moment(curso.createdAt).format("DD/MM/YYYY")}</TableCell>
      <TableCell>{curso.studentAdvance}%</TableCell>
      <TableCell>{curso.ending ? "SI" : "NO"}</TableCell>
    </TableRow>
  );
};
