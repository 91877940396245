import React from "react";
import { Box } from "@mui/material";
import Vimeo from "@u-wave/react-vimeo";

function VideoPresentacion({ cursos }) {
  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        paddingTop: {
          xs: "56.25%",
          md: "40%",
          lg: "35%",
        },
      }}
    >
      <Vimeo
        video={cursos.course.urlCourseVideo}
        autoplay={false}
        id="vimeo-player-description"
        responsive
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      />
    </Box>
  );
}

export default VideoPresentacion;
