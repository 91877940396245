import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Rating from "@mui/material/Rating";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import DeleteIcon from "@mui/icons-material/Delete";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import { Link } from "react-router-dom";
import {
  formatoFechaCurso,
  formatoMexico,
} from "../../../config/reuserFunction";
import clienteAxios from "../../../config/axios";
import ImagenIcon from "../../../images/imagen-none.png";
import {
  verificarBloquesCurso,
  verificarInformacionCurso,
  verificarLearningsCurso,
  verificarPrecioCurso,
} from "../Subir_curso/verificar_contenido";
import LinkMaterial from "@mui/material/Link";
import { CardHeader, Divider } from "@mui/material";

export default function CursosProfesor({
  datos,
  update,
  setLoading,
  setSnackbar,
}) {
  const theme = useTheme();
  const curso = datos.course;
  const token = localStorage.getItem("token");
  const [open, setOpen] = useState(false);
  const [blocks, setBlocks] = useState([]);

  const obtenerBloquesBD = useCallback(async () => {
    if (curso._id && token) {
      await clienteAxios
        .get(`/course/data/${curso._id}`, {
          headers: {
            Authorization: `bearer ${token}`,
          },
        })
        .then((res) => {
          setBlocks(res.data);
        })
        .catch((err) => {
          return;
        });
    }
  }, [curso._id, token]);

  const publicarCurso = async () => {
    if (
      verificarInformacionCurso(curso) &&
      verificarLearningsCurso(curso) &&
      verificarBloquesCurso(blocks) &&
      verificarPrecioCurso(curso)
    ) {
      await clienteAxios
        .put(
          `/course/public/${curso._id}`,
          {
            publication: !curso.publication,
          },
          {
            headers: {
              Authorization: `bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setSnackbar({
            open: true,
            mensaje: res.data.message,
            status: "success",
          });
          setLoading(false);
          update();
        })
        .catch((err) => {
          setLoading(false);
          if (err.response) {
            setSnackbar({
              open: true,
              mensaje: err.response.data.message,
              status: "error",
            });
          } else {
            setSnackbar({
              open: true,
              mensaje: "Al parecer no se a podido conectar al servidor.",
              status: "error",
            });
          }
        });
    } else {
      setSnackbar({
        open: true,
        mensaje: "Tu curso aun esta incompleto.",
        status: "error",
      });
    }
  };

  const eliminarCurso = async () => {
    await clienteAxios
      .delete(`/course/${curso._id}`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        setSnackbar({
          open: true,
          mensaje: res.data.message,
          status: "success",
        });
        setLoading(false);
        update();
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          setSnackbar({
            open: true,
            mensaje: err.response.data.message,
            status: "error",
          });
        } else {
          setSnackbar({
            open: true,
            mensaje: "Al parecer no se a podido conectar al servidor.",
            status: "error",
          });
        }
      });
  };

  useEffect(() => {
    obtenerBloquesBD();
  }, [obtenerBloquesBD]);

  const toggleModal = () => {
    setOpen(!open);
  };

  return (
    <Box my={2}>
      <Card>
        <CardActionArea>
          <LinkMaterial
            component={Link}
            to={`/instructor/contenido_curso/${curso._id}/general`}
            underline="none"
            color="inherit"
          >
            <CardHeader
              style={{
                padding: 0,
                textAlign: "center",
                backgroundColor: curso.publication ? "#dcedc8" : "#eeeeee",
              }}
              subheader={
                curso.publication ? (
                  <Box
                    justifyContent="center"
                    display="flex"
                    alignItems="center"
                    style={{ color: "#0000008A" }}
                  >
                    <CloudDoneIcon />
                    <Box mx={1} />
                    {` Publicado`}
                  </Box>
                ) : (
                  <Box
                    justifyContent="center"
                    display="flex"
                    alignItems="center"
                    style={{ color: "#0000008A" }}
                  >
                    <CloudOffIcon />
                    <Box mx={1} />
                    {` No publicado`}
                  </Box>
                )
              }
            />
            <CardMedia
              sx={{ height: 170, paddingTop: "56.25%" }}
              image={
                curso.urlPromotionalImage
                  ? curso.urlPromotionalImage
                  : ImagenIcon
              }
              title={`imagen curso ${curso._id}`}
            />
            <CardContent>
              <Box style={{ height: 160 }}>
                <Box>
                  {!curso.qualification ? (
                    <Typography variant="body2">Sin calificaciones</Typography>
                  ) : (
                    <Rating
                      size="small"
                      name="read-only"
                      value={curso.qualification}
                      readOnly
                      precision={0.5}
                    />
                  )}
                </Box>
                <Box className="truncate-multiline">
                  <Typography variant="h5" style={{ fontSize: "21px" }}>
                    {curso.title}
                  </Typography>
                </Box>

                <Typography variant="subtitle1" color="textSecondary">
                  {formatoFechaCurso(curso.createdAt)}
                </Typography>
                <Box display="flex" my={2}>
                  {curso.priceCourse && curso.priceCourse.promotionPrice ? (
                    <Fragment>
                      <Box display="flex">
                        <LocalOfferOutlinedIcon color="primary" />
                        <Typography color="primary">
                          <b>{`%${curso.priceCourse.persentagePromotion}`}</b>
                        </Typography>
                      </Box>
                      <Box mx={1} />
                    </Fragment>
                  ) : null}

                  <Typography>
                    {!curso.priceCourse ? (
                      "Sin precio"
                    ) : curso.priceCourse.free ? (
                      "Gratis"
                    ) : curso.priceCourse.promotionPrice ? (
                      <Fragment>
                        <b style={{ marginRight: theme.spacing(1) }}>
                          ${formatoMexico(curso.priceCourse.promotionPrice)}
                        </b>
                        <s>${formatoMexico(curso.priceCourse.price)}</s>
                      </Fragment>
                    ) : (
                      `$${formatoMexico(curso.priceCourse.price)}`
                    )}
                  </Typography>
                </Box>
              </Box>
              <Divider />
              <Box mt={1} display="flex" justifyContent="space-around">
                <Box textAlign="center">
                  <TrendingUpIcon
                    style={{
                      color: theme.palette.success.main,
                    }}
                  />
                  <Typography>${formatoMexico(datos.sales)}</Typography>
                </Box>
                <Box textAlign="center">
                  <PersonAddIcon />
                  <Typography>{datos.numInscription}</Typography>
                </Box>
                <Box textAlign="center">
                  <ThumbUpIcon color="primary" />
                  <Typography>{datos.numCalification}</Typography>
                </Box>
              </Box>
            </CardContent>
          </LinkMaterial>
        </CardActionArea>

        <CardActions disableSpacing>
          <Button
            aria-label="publicar-curso"
            fullWidth
            size="small"
            color={curso.publication ? "primary" : "default"}
            onClick={() => publicarCurso()}
            startIcon={curso.publication ? <CloudOffIcon /> : <CloudDoneIcon />}
          >
            {curso.publication ? "Ocultar" : "Publicar"}
          </Button>
          <Button
            fullWidth
            size="small"
            color="inherit"
            target="_blank"
            href={`/dashboard/${curso.slug}`}
            startIcon={<PlayCircleFilledIcon />}
          >
            Ver
          </Button>
          {!datos.blockCourse && datos.numInscription === 0 ? (
            <ModalDelete
              open={open}
              toggleModal={toggleModal}
              eliminarCurso={eliminarCurso}
            />
          ) : null}
        </CardActions>
      </Card>
    </Box>
  );
}

const ModalDelete = ({ open, toggleModal, eliminarCurso }) => {
  return (
    <Fragment>
      <Button
        size="small"
        fullWidth
        color="secondary"
        startIcon={<DeleteIcon />}
        onClick={toggleModal}
      >
        Borrar
      </Button>
      <Dialog open={open} onClose={toggleModal}>
        <DialogTitle>{"Estás seguro de eliminar este curso?"}</DialogTitle>
        <DialogActions>
          <Button onClick={toggleModal} color="primary">
            Cancelar
          </Button>
          <Button onClick={eliminarCurso} color="secondary" autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
