import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  TextField,
  Paper,
  IconButton,
  InputAdornment,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import { Search, School } from "@mui/icons-material";
import TablaCertificados from "./TablaCertificados";
import clienteAxios from "../../../config/axios";
import MessageSnackbar from "../../../components/Snackbar/snackbar";
import UbicacionNumeroCertificado from "./UbicacionNumeroCer";
import ULogo from "../../../images/u-normal.png";

export default function BuscarUnCertificado() {
  const [certificado, setCertificado] = useState();
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    mensaje: "",
    status: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const obtenerCertificado = async (e) => {
    e.preventDefault();
    if (!value) return;
    const numero_certificado = value.trim();
    setLoading(true);
    try {
      const res = await clienteAxios.get(
        `/certificate/verify/${numero_certificado}`
      );
      setCertificado(res.data);
    } catch (err) {
      console.log(err);
      setSnackbar({
        open: true,
        mensaje: err.response
          ? err.response.data.message
          : "Al parecer no se ha podido conectar al servidor.",
        status: "error",
      });
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  };

  return (
    <Container maxWidth="md">
      <MessageSnackbar
        open={snackbar.open}
        mensaje={snackbar.mensaje}
        status={snackbar.status}
        setSnackbar={setSnackbar}
      />
      <Box my={5}>
        <Box display="flex" alignItems="center" my={3}>
          <Box
            sx={{
              height: 64,
              width: 64,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              alt="logo-uniline"
              src={ULogo}
              style={{ maxHeight: "100%", maxWidth: "100%" }}
            />
          </Box>
          <Box mx={2}>
            <Typography variant="h5">Verificación de certificados</Typography>
            <Typography variant="h6">Cursos UNILINE</Typography>
          </Box>
        </Box>

        <Divider />

        <Box mb={2} mt={1}>
          <Typography>
            En el siguiente recuadro, deberás escribir el{" "}
            <b>Número de certificado</b> que deseas verificar
          </Typography>
          <UbicacionNumeroCertificado />
        </Box>

        <Box sx={{ maxWidth: "100%", overflowX: "hidden" }}>
          <Paper
            variant="outlined"
            sx={{
              maxWidth: "100%",
              width: { xs: "90%", sm: "400px" },
              border: 0,
            }}
          >
            <form onSubmit={obtenerCertificado}>
              <TextField
                size="small"
                variant="outlined"
                placeholder="Introduce un número de certificado"
                fullWidth
                onChange={(e) => setValue(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <School color="disabled" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton disabled={loading} type="submit">
                        {loading ? <CircularProgress size={28} /> : <Search />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{ maxWidth: "100%" }}
              />
            </form>
          </Paper>
        </Box>
      </Box>

      <Box my={2}>
        <TablaCertificados loaded={loaded} certificado={certificado} />
      </Box>
    </Container>
  );
}
