import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Typography } from "@mui/material";

export default function BackDropVideo({ backdrop, loading, progress }) {

  return (
    <div>
      <Backdrop
        sx={{ zIndex: theme => theme.zIndex.drawer + 1, color: "#fff" }}
        open={backdrop}
      >
        {loading ? (
          <CircularProgress variant="indeterminate" size={70} color="inherit" />
        ) : null}
        {progress ? (
          <Box>
            <CircularProgressWithLabel
              value={progress}
            />
          </Box>
        ) : null}
      </Backdrop>
    </div>
  );
}

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" color="inherit" size={70} {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography component="div" color="inherit" variant="h6">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
