import React from "react";
import PropTypes from "prop-types";
import FormLoginUsuario from "../Login/form_login";
import FormRegistroUsuario from "../Registro/form_registro";
import { AppBar, Box, Tab, Tabs, useTheme } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}> {children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function RegistroAlterno({ handleClose }) {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ flexGrow: 1, backgroundColor: theme.palette.background.paper }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          sx={{
            "& .MuiTab-root": {
              color: theme.palette.textWhite.secondary,
              minWidth: "160px",
            },
            "& .Mui-selected": { color: theme.palette.textWhite.primary },
            "& .MuiTabs-indicator": {
              backgroundColor: theme.palette.info.main,
            },
          }}
        >
          <Tab label="Inicia sesión" {...a11yProps(0)} />
          <Tab label="Registrate" {...a11yProps(1)} />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <FormLoginUsuario handleClose={handleClose} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <FormRegistroUsuario handleClose={handleClose} />
      </TabPanel>
    </Box>
  );
}
