import React, { Fragment } from "react";
import ExcelExportComponent from "../../../../components/ExcelExportComponent";
import clienteAxios from "../../../../config/axios";
import moment from "moment";

const formatTable = (users = []) => {
  return users.map(user => ({
    nombre: user.teacher.name,
    correo: user.teacher.email,
    telefono: user.teacher.phone ?? "-",
    cursos: user.courses,
    registro: moment(user.createdAt).format("DD/MM/YYYY"),
  }))
};

function ExportarExcelMaestros({ maestros }) {
  const excelData = formatTable(maestros);
  const [loading, setLoading] = React.useState(false);
  const token = localStorage.getItem("token");

  const getUsersData = async () => {
    try {
      setLoading(true);
      const res = await clienteAxios.get(`/user/action/teacher/`, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      });
      setLoading(false);
      return formatTable(res.data);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <ExcelExportComponent
        data={excelData}
        name="Lista de instructores Uniline"
        getQueryData={getUsersData}
        loading={loading}
      />
    </Fragment>
  );
}

export default ExportarExcelMaestros;
