import React from "react";
import "./footer.css";
import { Box, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Link } from "react-router-dom";
import ImagenUniline from "../../images/u-normal.png";
import moment from "moment";
import {
  EmojiObjectsOutlined,
  Facebook,
  Instagram,
  Policy,
  VerifiedUserOutlined,
  WhatsApp,
  YouTube,
} from "@mui/icons-material";
import LinkMaterial from "@mui/material/Link";

const LinkWithIcon = ({ Icon, href, text }) => (
  <Box sx={{ display: "flex", mb: 0.5, alignItems: "center" }}>
    <Icon color="action" sx={{ mr: 1 }} />
    <Typography>
      <LinkMaterial
        target="_blank"
        rel="noopener"
        href={href}
        color="inherit"
        underline="none"
      >
        {text}
      </LinkMaterial>
    </Typography>
  </Box>
);

const IconLink = ({ Icon, to, text }) => (
  <Box sx={{ display: "flex", mb: 0.5, alignItems: "center" }}>
    <Icon color="action" sx={{ mr: 1 }} />
    <Typography>
      <LinkMaterial component={Link} to={to} color="inherit" underline="none">
        {text}
      </LinkMaterial>
    </Typography>
  </Box>
);

export default function Footer() {
  const theme = useTheme();
  const year_footer = moment().format("YYYY");
  return (
    <Box
      sx={{
        position: "relative",
        zIndex: 2,
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        padding: theme.spacing(3),
        boxShadow: theme.shadows[3],
      }}
    >
      <Grid container spacing={2} justifyContent="space-evenly">
        <Grid
          size={{ xs: 12 }}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              height: 80,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              alt="Uniline"
              src={ImagenUniline}
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          </Box>
        </Grid>
        <Grid
          size={{ xs: 12, sm: 6, md: 4 }}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box>
            <LinkWithIcon
              Icon={Facebook}
              href="https://www.facebook.com/EscuelaUniline"
              text="Uniline"
            />
            <LinkWithIcon
              Icon={Instagram}
              href="https://www.instagram.com/uniline.oficial"
              text="Uniline Online"
            />
            <LinkWithIcon
              Icon={YouTube}
              href="https://www.youtube.com/@Unilineonline"
              text="Uniline YT Channel"
            />
          </Box>
        </Grid>
        <Grid
          size={{ xs: 12, sm: 6, md: 4 }}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box>
            <IconLink
              Icon={VerifiedUserOutlined}
              to="/buscar-certificado"
              text="Verificar Certificado"
            />
            <IconLink
              Icon={Policy}
              to="/politicas"
              text="Políticas de privacidad"
            />
            <IconLink
              Icon={EmojiObjectsOutlined}
              to="/imagen_corporativa"
              text="Imagen corporativa"
            />
          </Box>
        </Grid>

        <Grid
          size={{ xs: 12, md: 4 }}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box>
            <Box sx={{ display: "flex", mb: 0.5 }}>
              <WhatsApp color="action" sx={{ mr: 1 }} />
              <Typography>317 129 7626</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <LocationOnIcon color="action" sx={{ mr: 1 }} />
              <Box>
                <Typography>
                  Miguel Espinoza Monrroy #40, Col. Obrera.
                </Typography>
                <Typography>Autlán de Navarro, Jalisco, México.</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Typography>©AB Cloud</Typography>
        <Box sx={{ mx: 1 }} />
        <Typography>©Uniline {year_footer}</Typography>
      </Box>
    </Box>
  );
}
