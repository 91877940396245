import React, { Fragment, useState } from "react";
import {
  SwipeableDrawer,
  Box,
  List,
  Badge,
  Divider,
  ListItem,
  ListItemAvatar,
  Typography,
  Avatar,
  BottomNavigationAction,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/system";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ImageIcon from "@mui/icons-material/Image";

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.action.disabled,
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

export default function NotificationResponsive() {
  const [open, setOpen] = useState(false);
  const notifications = JSON.parse(
    localStorage.getItem("notifications_uniline") || "[]"
  );

  const count = notifications.filter((res) => !res.read);

  const toggleDrawer = () => setOpen(!open);

  return (
    <Fragment>
      <BottomNavigationAction
        icon={
          <Badge
            badgeContent={count.length}
            color="secondary"
            onClick={() => toggleDrawer()}
          >
            <NotificationsIcon htmlColor="#fff" sx={{ fontSize: 30 }} />
          </Badge>
        }
      />
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={() => toggleDrawer()}
        onOpen={() => toggleDrawer()}
      >
        <Puller />
        {!notifications.length ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              pt: 3,
            }}
          >
            <Typography variant="h6" color="textSecondary">
              No hay notificaciones
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              pt: 3,
            }}
          >
            <Typography color="textSecondary" variant="h6" align="center">
              Notificaciones
            </Typography>
          </Box>
        )}

        <List sx={{ height: "50vh", overflow: "auto" }}>
          {notifications.map((notification, index) => (
            <Box key={index}>
              <ItemNotification
                index={index}
                notification={notification}
                notificaciones={notifications}
                handleClose={toggleDrawer}
              />
              {index !== notifications.length - 1 && (
                <Divider sx={{ mx: 2, height: 0.5 }} />
              )}
            </Box>
          ))}
        </List>
      </SwipeableDrawer>
    </Fragment>
  );
}

const ItemNotification = ({
  notification,
  notificaciones,
  index,
  handleClose,
}) => {
  const redirection = () => {
    const updatedNotification = { ...notification, read: true };
    const updatedNotifications = [...notificaciones];
    updatedNotifications.splice(index, 1, updatedNotification);
    localStorage.setItem(
      "notifications_uniline",
      JSON.stringify(updatedNotifications)
    );
    if (notification.url) window.open(notification.url, "_blank");
    handleClose();
  };

  return (
    <ListItem
      sx={{
        display: "flex",
        alignItems: "flex-start",
        cursor: "pointer",
      }}
      onClick={redirection}
    >
      <Badge
        variant="dot"
        color="secondary"
        invisible={notification.read}
        sx={{ mt: 1.5, mr: 1 }}
      >
        <ListItemAvatar>
          <Avatar
            variant="rounded"
            src={notification.image || ""}
            sx={{ height: 52, width: 52 }}
          >
            <ImageIcon />
          </Avatar>
        </ListItemAvatar>
      </Badge>

      <ListItemText
        primary={`${
          notification.name_teacher ? `${notification.name_teacher}: ` : ""
        } ${notification.title || ""}`}
        secondary={notification.description || ""}
      />
    </ListItem>
  );
};
