import React, { useEffect } from "react";
import { Box, Container, useTheme } from "@mui/material";
import { createTheme, ThemeProvider, CssBaseline } from "@mui/material";
import Imagen from "../../../images/politicas.png";
import Scroll from "../../../components/ScrolltoTop/scroll";

const customTheme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        h5 {
          font-size: 20px;
        }
		p, a {
          font-size: 15px;
        }
      `,
    },
  },
});

export default function Politicas() {
  const theme = useTheme();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      <Container
        maxWidth="lg"
        sx={{
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Scroll showBelow={250} />
        <Box height="90vh">
          <img
            alt="imagen corporativa"
            width="100%"
            height="100%"
            src={Imagen}
          />
        </Box>
        <Box textAlign="justify" sx={{ fontSize: "15px" }}>
          <div>
            <p>
              {" "}
              Gracias por unirte a nosotros, en UNILINE escuela al revés
              queremos agradecerte por ser parte de este gran movimiento,
              queremos hacerte saber que tú eres muy importante para nosotros,
              es por eso que en cumplimiento con Ley Federal de Protección de
              Datos Personales en Posesión de Particulares publicada en el
              Diario Oficial de la Federación el 5 de julio de 2010, pone a su
              disposición el presente Aviso de Privacidad para informarle la
              forma en que recabamos, protegemos, transferimos y utilizamos su
              información confidencial. Te recordamos que puedes acceder al
              contenido de la Ley citada a través del portal de la Cámara de
              Diputados del H. Congreso de la Unión
              <a href="http://www.diputados.gob.mx.">
                http://www.diputados.gob.mx.
              </a>
            </p>
            <h5>I. DEFINICIONES</h5>
            <p>
              a) Derechos ARCO: derechos de Acceso, Rectificación, Cancelación y
              Oposición.
            </p>
            <p>
              b) Legislación Vigente Aplicable: Se refiere a la con Ley Federal
              de Protección de Datos Particulares en Posesión de Particulares,
              su reglamento, así como a cualquier ordenamiento jurídico que
              regule la recabación, almacenamiento y transmisión de datos
              personales.
            </p>
            <p>
              {" "}
              c) Responsable: Para los efectos del presente Aviso de Privacidad,
              el responsable del tratamiento, uso y protección de sus Datos
              Personales será UNILINE escuela al revés.
            </p>
            <p>
              {" "}
              d) Plataforma: Plataforma o sitio web de UNILINE escuela al revés,
              en el cual se genera de forma espontánea información para estudios
              de mercado de diversos productos o servicios.
            </p>
            <p>
              e) Titular: Persona física o moral a quien corresponden los Datos
              Personales (en adelante “Usted”).
            </p>
            <h5>II. DATOS PERSONALES QUE RECABAMOS</h5>
            <p>
              Los siguientes datos personales serán recabados por UNILINE
              escuela al revés de la manera descrita en el presente Aviso de
              Privacidad: -Datos de identificación: se podrá recabar su nombre y
              apellido; domicilio particular, laboral o fiscal; fecha de
              nacimiento; país y entidad de nacimiento, edad; nacionalidad,
              estado civil, ocupación, profesión, actividad o giro del negocio
              al que se dedique; números telefónicos fijos de casa, celulares y/
              o de trabajo, correos electrónicos, estudios realizados,
              dependientes económicos, datos financieros, Clave Única de
              Registro de Población (CURP); Clave del Registro Federal de
              Contribuyentes. -UNILINE escuela al revés no recaba ningún dato
              personal sensible a través de su sitio web ni de ningún medio.
            </p>
            <h5>
              III. FINALIDADES PRINCIPALES Y/O USOS DE SUS DATOS PERSONALES
            </h5>
            <p>
              Las finalidades principales por las que UNILINE escuela al revés
              recabará sus datos personales son las siguientes:
            </p>
            <p>
              1. Cumplir con las medidas establecidas por las autoridades en
              materia de prevención de lavado de dinero y prevención de
              financiamiento al terrorismo.
            </p>
            <p>2. Realizar análisis de tendencia de consumo.</p>
            <p>3. Fines mercadotécnicos.</p>
            <p>
              {" "}
              4. Poder prestar los servicios disponibles en las distintas
              plataformas. UNILINE escuela al revés hará uso de su Datos
              Personales siempre en atención a lo estipulado en el presente
              Aviso de Privacidad o sus actualizaciones, siempre estarán
              disponibles en la Plataforma y en atención a los principios de
              licitud, consentimiento, información, calidad, finalidad, lealtad,
              proporcionalidad y responsabilidad.
            </p>
          </div>
          <h5>IV. FINALIDADES ACCESORIAS DEL USO</h5>
          <p>
            {" "}
            Las finalidades por las que UNILINE escuela al revés recaba sus
            Datos Personales son para cumplir con obligaciones derivadas del uso
            de su Plataforma, para fines estadísticos, para informar sobre
            cambios o nuevos productos o servicios, evaluar la calidad del
            servicio, determinar la calidad de nuestros productos y servicios,
            intercambiar información necesaria para atender auditorías internas,
            externas y por autoridades, así como para fines mercadotécnicos,
            publicitarios y/o de prospección comercial y para otorgar beneficios
            y dar cumplimiento a obligaciones contraídas y para realizar
            estudios internos sobre hábitos de consumo.
          </p>
          <h5>V. TRANSFERENCIA DE DATOS</h5>
          <p>
            Usted acepta y reconoce que UNILINE escuela al revés podrá realizar
            las transferencias de sus datos personas como se describe a
            continuación:
          </p>
          <p>
            a) Otras personas que trabajan con y para UNILINE escuela al revés.
            UNILINE escuela al revés usa determinados terceros de confianza (por
            ejemplo, proveedores de soporte para clientes y servicios de
            informática) para prestar, mejorar, proteger y promocionar los
            Servicios. Estos terceros solamente tendrán acceso a tu información
            para llevar a cabo tareas en representación nuestra y de conformidad
            con esta Política de privacidad. La forma en que procesen tu
            información según nuestras instrucciones será nuestra
            responsabilidad.
          </p>
          <p>
            b) Fuerzas del orden público y el interés público. Podremos divulgar
            su información ante terceros si determinamos que ello es
            razonablemente necesario para: (a) cumplir las leyes aplicables, las
            regulaciones, los procesos legales o las solicitudes apropiadas del
            gobierno; (b) proteger a una persona contra la muerte o lesiones
            graves;
          </p>
          <p>
            c) prevenir fraudes o abuso contra UNILINE escuela al revés o contra
            nuestros usuarios;
          </p>
          <p>
            d) proteger los derechos, la propiedad, la seguridad o los intereses
            de UNILINE escuela al revés.
          </p>
          <h5>VI. LIMITACIÓN DEL USO O DIVULGACIÓN DE SUS DATOS PERSONALES</h5>
          <p>
            {" "}
            Usted podrá limitar el uso o divulgación de su información personal
            en nuestra posesión, para el ejercicio de dichos derechos Usted
            deberá enviar un correo electrónico a la siguiente dirección:
            <p style={{ fontSize: "15px", color: "blue" }}>
              datospersonales@escuerlaalreves.com
            </p>{" "}
            con el asunto “SOLICITUD DE LIMITACIÓN O DIVULGACIÓN DE DATOS
            PERSONALES”. Adicionalmente deberá contener la siguiente
            información.
          </p>
          <p>1. Nombre del Titular.</p>
          <p>2. Declaración de hechos (explicación breve y solicitud).</p>
          <p>
            {" "}
            3. Domicilio de Titular o dirección de correo electrónico para
            comunicar respuesta a solicitud.
          </p>
          <p>
            {" "}
            4. Documentos que acrediten identidad o personalidad para presentar
            la solicitud (INE, Pasaporte, Poder Notarial, etc.)
          </p>
          <h5>
            {" "}
            VII. MEDIOS PARA EJERCER LOS DERECHOS DE ACCESO, RECTIFICACIÓN,
            CANCELACIÓN U OPOSICIÓN (ARCO)
          </h5>
          <p>
            Usted tiene el derecho, en todo momento, a solicitar a UNILINE
            escuela al revés la cancelación (eliminación) de sus datos
            personales cuando considere que los mismos no están siendo tratados
            conforme a los principios, deberes y obligaciones previstas en la
            Legislación Vigente Aplicable. Esta cancelación implica la supresión
            total o parcial de la información personal de acuerdo con lo
            solicitado por el titular en los registros, archivos, bases de datos
            o tratamientos realizados por el responsable, previo bloqueo.
          </p>
          <p>
            Si usted quiere ejercer sus derechos ARCO respecto a sus datos
            personales que estén en posesión de UNILINE escuela al revés lo
            podrá realizar enviando la solicitud para ejercer sus derechos ARCO,
            a nuestro Departamento de Datos Personales en nuestro correo
            electrónico:
            <p style={{ fontSize: "15px", color: "blue" }}>
              datospersonales@escuerlaalreves.com
            </p>
          </p>
          <p>
            {" "}
            Usted podrá obtener el formato para ejercer sus derechos ARCO dando
            clic aquí o bien a través de un escrito enviándolo a nuestro
            domicilio de UNILINE escuela al revés señalado en el numeral XIII
            DATOS DE CONTACTO.
          </p>
          <p>
            {" "}
            Para responder a su solicitud UNILINE escuela al revés tendrá un
            plazo de veinte (20) días hábiles contados a partir de la fecha en
            que recibió la solicitud para responder. Si la solicitud es
            procedente, se hará efectiva dentro de los quince (15) días hábiles
            siguientes a la fecha en que se le haya comunicado la respuesta
            anterior. Estos plazos podrán ser ampliados por una sola vez y por
            un periodo igual, cuando las circunstancias del caso lo ameriten. La
            entrega de información procederá previa acreditación de su identidad
            o de su representante legal, según corresponda. Si usted deseara
            revocar el consentimiento otorgado para el tratamiento de datos
            personales por parte del UNILINE escuela al revés lo deberá informar
            a ésta por escrito de la misma manera.
          </p>
          <p>
            Al momento en que usted haga la revocación del uso de sus datos
            personales existe la posibilidad de que UNILINE escuela al revés no
            pueda seguir prestándole sus servicios o productos.
          </p>
          <p>
            Así también, le informamos que usted tiene derecho a iniciar un
            Procedimiento de Protección de Datos ante el Instituto Federal de
            Acceso a la Información y Protección de Datos (
            <a href=" http://www.ifai.gob.mx">www.ifai.gob.mx</a>) dentro de los
            15 días siguientes a la fecha en que reciba la respuesta de UNILINE
            escuela al revés o a partir de que concluya el plazo de 20 días
            contados a partir de la fecha de recepción de su solicitud de
            ejercicio de derechos.
          </p>
          <h5>VIII. USO DE COOKIES</h5>
          <p>
            Nuestro Sitio Web utiliza cookies, web beacons y otras tecnologías a
            través de las cuales es posible monitorear su comportamiento como
            usuario de Internet, brindarle un mejor servicio y experiencia de
            usuario al navegar en el Sitio Web, así como ofrecerle nuevos
            productos y servicios basados en sus preferencias.
          </p>
          <p>
            {" "}
            Usted podrá deshabilitar el uso de estos mecanismos siguiendo el
            procedimiento de desactivación, determinado para cada navegador.
          </p>
          <h5>IX. ACEPTACIÓN</h5>
          <p>
            {" "}
            Al ponerse a disposición el presente Aviso de Privacidad y la no
            manifestación de oposición alguna por el Titular, se entenderá que
            el Titular otorga a UNILINE escuela al revés su consentimiento para
            llevar a cabo el tratamiento de los Datos Personales que hubieran
            sido proporcionados y/o los que con motivo de alguna de las
            finalidades establecidas en el presente proporcione en lo futuro.
          </p>
          <h5>X. MODIFICACIONES AL AVISO DE PRIVACIDAD Y MEDIOS DE DIFUSIÓN</h5>
          <p>
            {" "}
            Nos reservamos el derecho de efectuar en cualquier momento
            modificaciones o actualizaciones al presente aviso de privacidad,
            para la atención de novedades legislativas, políticas internas o
            nuevos requerimientos para la prestación u ofrecimiento de nuestros
            servicios o productos. Estas modificaciones estarán disponibles al
            público a través de los siguientes medios: En nuestro Sitio Web y
            mediante su correo electrónico.
          </p>
          <h5> XI. GARANTÍA DE SEGURIDAD Y ALMACENAMIENTO DE DATOS</h5>
          <p>
            {" "}
            No obstante que UNILINE escuela al revés utiliza la tecnología más
            avanzada para salvaguardar los datos que proporciona el Titular,
            existe la probabilidad de que pueda haber un defecto en la
            programación de las aplicaciones que utilizamos, que permita el
            acceso forzado a la información. Aún y cuando esta situación se
            llegará a dar, tus datos están protegidos mediante procesos de
            cifrado avanzado que hacen imposible la recuperación útil de la
            información por un tercero ajeno a UNILINE escuela al revés. Nuestra
            garantía es hacer todo lo que razonablemente esté a nuestro alcance
            para resguardar tus datos.
          </p>
          <p>
            Para prestar los Servicios, podremos almacenar, procesar y
            transmitir sus Datos en otras ubicaciones de todo el mundo (también
            fuera de tu país). La información también podrá almacenarse de forma
            local en los dispositivos que usas para acceder a los Servicios con
            la garantía de seguridad señalada en el párrafo anterior.
          </p>
          <p>
            {" "}
            Una vez cumplida la o las finalidades del tratamiento, y cuando no
            exista disposición legal o reglamentaria que establezca lo
            contrario, UNILINE escuela al revés procederá a la cancelación de
            los datos personales, en su posesión previo bloqueo de los mismos,
            para su posterior supresión.
          </p>
          <h5>XII. INFORMACIÓN DE OTROS SITIOS</h5>
          <p>
            {" "}
            Debes tomar en cuenta que la información que alimentas en sitios
            ajenos a UNILINE escuela al revés puede coincidir con aquella que
            has alimentado o alimentarás en los formularios de UNILINE escuela
            al revés.
          </p>
          <p>
            En consecuencia, debes estar consciente y ser responsable de tener
            el mismo cuidado que tienes con tu información en los sitios de
            UNILINE escuela al revés, en los demás sitios en los que navegues o
            alimentes información de cualquier tipo.
          </p>
          <h5>XIV. Datos de pago</h5>
          <p>
            {" "}
            En la mayoría de nuestros cursos se deberá de realizar un pago para
            poder obtener acceso a ellos, si el usuario realiza compras,
            recopilamos algunos datos sobre las compras (como el nombre y el
            código postal del usuario), según sea necesario para procesar el
            pedido. El usuario debe proporcionar algunos datos de pago y
            facturación directamente a nuestros socios de procesamiento de pago,
            como el nombre, la información de la tarjeta de crédito y el código
            postal del usuario. Por motivos de seguridad, UNILINE escuela al
            revés no recopila ni almacena datos confidenciales del titular de la
            tarjeta, como todos los números de la tarjeta de crédito o los datos
            de autenticación de la tarjeta.
          </p>
          <h5>XV. TARIFAS</h5>
          <p>
            {" "}
            UNILINE escuela al revés se reserva el derecho de cambiar sus
            tarifas o cargos por los servicios ofrecidos en el Sitio Web (o de
            comenzar a cobrar por cualquier servicio gratuito), tomando en
            cuenta que las tarifas pagadas antes de dichos cambios no resultarán
            afectadas, sin embargo, podrán tener cambios, como no poder seguir
            tomando un curso de manera gratuita hasta no realizar el pago
            correspondiente.
          </p>
          <h5> XVI. USO CORRECTO DEL SITIO WEB</h5>
          <p>
            {" "}
            El Usuario se compromete a utilizar el Sitio Web de conformidad con
            lo establecido en el ordenamiento jurídico, el orden público y las
            buenas costumbres.&nbsp; En ese sentido, a título meramente
            indicativo y no exhaustivo, el Usuario se abstendrá de:
          </p>
          <p>
            a. Abusar, acosar, amenazar, suplantar o intimidar a cualquier
            persona.
          </p>
          <p>
            b. Publicar o transmitir cualquier tipo de contenido difamatorio,
            obsceno, pornográfico, abusivo, ofensivo, profano o que infrinja
            derecho de autor o derechos en general de cualquier persona.
          </p>
          <p>
            c. Intentar acceder o realizar búsquedas en motores de búsquedas o
            softwares (spiders, robots, crawlers, herramientas de minería de
            datos o similares) distintos de aquellos provistos por UNILINE
            Escuela al reves o aquellos motores de búsquedas genéricos provistos
            por terceros y de acceso público la Internet. Generar o enviar
            correos no deseados a cualquier persona o URL.
          </p>
          <p>
            d. Publicar contenidos de los cuales no posea los derechos de autor.
          </p>
          <p>g. Vender o transferir su perfil.</p>
          <p>
            h. Utilizar el Sitio Web para fines comerciales o para el beneficio
            de terceros de manera que infrinja lo establecido en el presente
            documento.
          </p>
          <p>
            i. Manipular cualquier paquete TCP/IP, encabezado (header) o
            cualquier parte de la información de encabezado en cualquier correo
            electrónico, newsgroup, newsletter o utilizar de cualquier manera el
            Sitio Web para enviar información falsa, alterada o que no permita
            identificar la fuente original desde donde se envió.
          </p>
          <p>
            {" "}
            j. Intentar descifrar, descompilar o aplicar ingeniería en reversa a
            cualquier programa de software proveedor del Sitio Web.
          </p>
          <p>
            Al compartir cualquier tipo de contenido, el Usuario declara y
            garantiza que:
          </p>
          <p>
            a. El contenido compartido no violará los derechos de propiedad.
          </p>
          <p>
            b. Si su empleador posee los derechos de cualquier propiedad
            intelectual que usted genere, ha recibido permiso de su empleador
            para publicar el contenido o ha conseguido una extensión de derechos
            de parte de su empleador.
          </p>
          <p>
            {" "}
            c. El contenido compartido no acarrea ni contagiará de virus o
            cualquier software dañino (malware) o destructivo.
          </p>
          <p>
            {" "}
            UNILINE escuela al revés se reserva el aviso y en cualquier momento
            la prestación de los Servicios y el acceso a Contenidos en el Sitio
            Web a aquellos Usuarios que infrinjan los presentes Términos y
            Condiciones de Uso.
          </p>
          <h5>XVII. CONDICIONES PARA ALUMNOS</h5>
          <p>
            {" "}
            Los Alumnos aceptan todas las Condiciones como también las Políticas
            de Privacidad y cualquier política de cancelación publicada en el
            Sitio Web. Todas las clases en UNILINE escuela al revés se
            mantendrán disponibles para acceso ilimitado, a menos que se dé de
            bajo el contenido por motivos de derechos de autor.
          </p>
          <p>
            {" "}
            Los alumnos aceptan tomar las clases solo por motivos educacionales
            y nunca con la intención de copiar materiales o técnicas para su
            beneficio o llevar a otras clases, o bien para interrumpir cualquier
            clase o relación del Profesor con sus alumnos.
          </p>
          <h5> XVIII. DATOS DE CONTACTO</h5>
          <p>
            {" "}
            Tus datos son tratados y resguardados por UNILINE escuela al revés;
            si como Titular tienes alguna duda o preocupación en relación a la
            presente política de privacidad o la forma en que manejamos los
            datos que nos proporcionas o recolectamos, por favor envíanos un
            mensaje a nuestro Departamento de Datos Personales encargado de
            atender y gestionar cualquier solicitud de Derechos ARCO y
            relacionada a los datos personales del Titular.
          </p>
          <Box display="flex" justifyContent="center" textAlign="center">
            <div>
              <p>
                {" "}
                Dirección: Autlán de Navarro, Jalisco. México Teléfono:
                (+523171035768)
              </p>
              <p style={{ fontSize: "15px", color: "blue" }}>
                Correo: datospersonales@escuerlaalreves.com
              </p>
              <br />
              <small> Copyright © UNILINE escuela al revés 2020</small>
            </div>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
