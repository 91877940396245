import React from "react";
import { Slide, Grid2 as Grid, Box } from "@mui/material";
import { Button, AppBar, Toolbar, Typography, Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import TablaUsuarios from "./tabla_usuarios";
import FormRegistroInstructor from "./registro_form";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RegistrarInstructor({ usuarios, reload, setReload }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpenRegistro = () => setOpen(!open);

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        size="large"
        startIcon={<AddCircleOutlinedIcon />}
        onClick={handleClickOpenRegistro}
      >
        Nuevo
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClickOpenRegistro}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography variant="h6" sx={{ marginLeft: 2, flex: 1 }}>
              Crear un nuevo instructor
            </Typography>
            <Button
              size="large"
              color="inherit"
              onClick={handleClickOpenRegistro}
              startIcon={<CloseIcon />}
            >
              Cerrar
            </Button>
          </Toolbar>
        </AppBar>
        <Grid container>
          <Grid size={{xs: 12, md: 4, lg: 5}}>
            <Box p={5}>
              {<FormRegistroInstructor
                reload={reload}
                setReload={setReload}
                handleClickOpenRegistro={handleClickOpenRegistro}
              />}
            </Box>
          </Grid>
		  <Grid size={{xs: 12, md: 8, lg: 7}}>
            <Box p={5}>
              <TablaUsuarios
                usuarios={usuarios}
                reload={reload}
                setReload={setReload}
                handleClickOpenRegistro={handleClickOpenRegistro}
              />
            </Box>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
