import React from "react";
import InfoConsulta from "./InfoConsulta";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import PaypalPayment from "./PaypalPayment";
import StripePayments from "./StripePayment";

export default function Payment() {
  const [typePayment, setTypePayment] = React.useState("");

  return (
    <Grid container spacing={2} sx={{ marginTop: 5 }}>
      <Grid
        size={{ md: 1 }}
        sx={{
          display: { xs: "none", sm: "block" },
        }}
      ></Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <InfoConsulta />
        <Box display="flex" my={2}></Box>
      </Grid>
      <Grid
        size={{ md: 1 }}
        sx={{
          display: { xs: "none", sm: "block" },
        }}
      ></Grid>
      <Grid size={{ xs: 12, md: 3 }}>
        <PaypalPayment
          typePayment={typePayment}
          setTypePayment={setTypePayment}
        />
        <StripePayments
          typePayment={typePayment}
          setTypePayment={setTypePayment}
        />
      </Grid>
      <Grid
        size={{ md: 1 }}
        sx={{
          display: { xs: "none", sm: "block" },
        }}
      ></Grid>
    </Grid>
  );
}
