import React from "react";
import { Box, Container, Typography } from "@mui/material";
import ImagenError500 from "../images/Error500.png";

export default function Error500({ error }) {
  return (
    <Container maxWidth="md">
      <Box sx={{ height: "80vh", mt: 5 }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ height: "60vh" }}>
            <img
              alt="error 404"
              src={ImagenError500}
              style={{
                maxHeight: "100%",
                maxWidth: "100%",
              }}
            />
          </Box>
        </Box>
        <Typography variant="h4" align="center">
          <b>Ups.. Algo salió mal</b>
        </Typography>
        <Typography align="center">
          <b>{error}</b>
        </Typography>
      </Box>
    </Container>
  );
}
