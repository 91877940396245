import React /* , { useState } */ from "react";
import Vimeo from "@u-wave/react-vimeo";
import { Box, Button } from "@mui/material";
import { useParams } from "react-router-dom";
/* import { Helmet } from 'react-helmet'; */

export default function VimeoReactNative(props) {
  const classes = {
    video: {
      backgroundColor: "#1A1A1A",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      /* [theme.breakpoints.down('sm')]: {
			height: '40vh'
		} */
    },
    vimeoPlayer: {
      width: "82%",
    },
  };
  /* 	const [ update, setUpdate ] = useState(""); */
  const { url, info_url } = useParams();
  const info_local = process.env.REACT_APP_VIMEO_RN;

  if (info_url !== info_local) return null;

  const jalaPorfa = () => {
    document.getElementById("jalaPorfa").click();
  };

  return (
    <>
      {/* <Helmet>
				<title>{update}</title>
			</Helmet> */}
      <div style={classes.video}>
        <Vimeo
          video={url}
          autoplay={true}
          onEnd={jalaPorfa}
          id="vimeo-player-mobile"
          style={classes.vimeoPlayer}
          responsive={true}
          speed={true}
          height={100}
          loop={false}
        />
        <Box my={2} display="none">
          <Button
            id="jalaPorfa"
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => props.history.push("/vimeo_mobil_change/true")}
          >
            Jala porfa
          </Button>
        </Box>
      </div>
    </>
  );
}
